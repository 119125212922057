<template>
  <div class="family-tree-expand-all-button" v-tooltip="tooltip" @click="expandAllTreeBranches">
    <circle-expand-icon class="item" :size="24" title=""></circle-expand-icon>
  </div>
</template>

<script>
import CircleExpandIcon from 'vue-material-design-icons/CircleExpand';

export default {
  props: {
    treeId: Number,
    startPersonId: Number,
  },
  computed: {
    tooltip() {
      return {content: 'Expand all branches', placement: 'left'};
    },
  },
  methods: {
    expandAllTreeBranches() {
      this.$store
        .dispatch('fetchFamilyTreeAction', {
          id: this.treeId,
          start_person_id: this.startPersonId,
          control_data: [{action: 'reset'}, {action: 'expand', controller_state: '1'}],
        })
        .then(() => {
          this.$store.commit('setFamilyTreeRefreshRequestState', true);
        });
    },
  },
  components: {CircleExpandIcon},
  name: 'familyTreeExpandAllButton',
};
</script>

<style scoped lang="scss"></style>

<template>
  <div class="person-details flex-column tree-modal-content">
    <div class="tree-modal-header">
      <div class="person-picture">
        <img
          :src="person.profile_picture.attachment"
          @click="profilePictureClick"
          class="profile-picture"
          v-if="person.profile_picture.attachment"
        />
        <div class="loading-profile-picture" v-else-if="showProfilePictureLoading"></div>
        <person-icon-colored v-else :gender="person.gender" @click.native="personIconClick"></person-icon-colored>
      </div>
      <div class="name-titles">
        <h4 v-if="personPrimaryFullnameEn">{{ personPrimaryFullnameEn }}</h4>
        <h4 v-if="personPrimaryFullnameCn">{{ personPrimaryFullnameCn }}</h4>
        <h4 v-if="!personPrimaryFullnameEn && !personPrimaryFullnameCn">{{ unknownName }}</h4>
        <div class="strapline" v-if="livingStatus">{{ livingStatus }}</div>
      </div>
      <div class="generation-number" v-if="person.generation_number">Generation #{{ person.generation_number }}</div>
    </div>
    <div class="person-details-wrapper">
      <!--<div class="flex-row">
        <div class="label">Gender</div>
        <div class="value">{{ person.gender_display }}</div>
      </div>-->

      <div class="person-details-content">
        <h5>Details</h5>
        <template v-for="(names, typeDisplay) in firstNamesByType">
          <div class="flex-row">
            <div class="label">{{ typeDisplay }}</div>
            <div class="value flex-column name">
              <div v-for="(name, index) in names" :key="index">{{ name }}</div>
            </div>
          </div>
        </template>

        <template v-for="(names, typeDisplay) in surnamesByType">
          <div class="flex-row">
            <div class="label">{{ typeDisplay }}</div>
            <div class="value flex-column name">
              <div v-for="(name, index) in names" :key="index">
                <router-link v-if="isChineseText(name)" :to="getTreeClanDetailRoute(name)">{{ name }}</router-link>
                <span v-else>{{ name }}</span>
              </div>
            </div>
          </div>
        </template>

        <div class="flex-row">
          <div class="label">Birth</div>
          <date-location-display
            class="value value-place"
            v-if="person.birth_date || person.birth_location"
            :date="person.birth_date"
            :location="person.birth_location"
          ></date-location-display>
          <div class="value" v-else>Unknown</div>
        </div>

        <div class="flex-row" v-if="person.death_date || person.death_location">
          <div class="label">Death</div>
          <date-location-display
            class="value value-place"
            v-if="person.death_date || person.death_location"
            :date="person.death_date"
            :location="person.death_location"
          ></date-location-display>
          <div class="value" v-else>Unknown</div>
        </div>

        <div class="flex-row" v-if="person.residence_location && person.residence_location.length">
          <div class="label">Residence</div>
          <div class="value">
            <div v-for="residence in person.residence_location" class="value-place">
              <router-link
                class="place-wrapper"
                v-if="residence.place_id"
                :to="getPlaceDetailRoute(residence)"
                target="_blank"
              >
                <map-marker-icon></map-marker-icon>
                <span>{{ getFormattedResidenceText(residence) }}</span>
              </router-link>
              <span v-else class="place-wrapper">
                <map-marker-icon></map-marker-icon>
                <span>{{ getFormattedResidenceText(residence) }}</span>
              </span>
            </div>
          </div>
        </div>

        <div class="flex-row" v-if="relatives && relatives.parents.length">
          <div class="label">Parents</div>
          <div class="value">
            <person-details-relatives :relatives="relatives.parents" @click="relativeClick"></person-details-relatives>
          </div>
        </div>

        <div class="flex-row" v-if="relatives && separateSiblings.fullSiblings.length">
          <div class="label">Full Siblings</div>
          <div class="value">
            <person-details-relatives
              :relatives="separateSiblings.fullSiblings"
              @click="relativeClick"
            ></person-details-relatives>
          </div>
        </div>
        <div class="flex-row" v-if="relatives && separateSiblings.halfSiblings.length">
          <div class="label">Half Siblings</div>
          <div class="value">
            <person-details-relatives
              :relatives="separateSiblings.halfSiblings"
              @click="relativeClick"
            ></person-details-relatives>
          </div>
        </div>

        <div class="flex-row" v-if="relatives && relatives.spouses.length">
          <div class="label">Spouses</div>
          <div class="value">
            <person-details-relatives :relatives="relatives.spouses" @click="relativeClick"></person-details-relatives>
          </div>
        </div>

        <div class="flex-row" v-if="relatives && relatives.children.length">
          <div class="label">Children</div>
          <div class="value">
            <person-details-relatives :relatives="relatives.children" @click="relativeClick"></person-details-relatives>
          </div>
        </div>
      </div>
    </div>

    <div v-if="person.notes_display">
      <h5>Biography</h5>
      <div v-html="person.notes_display"></div>
    </div>

    <person-photos-container
      :ref="photosContainer"
      :personId="person.object_id"
      :person-name-en="personPrimaryFullnameEn"
      :person-name-cn="personPrimaryFullnameCn"
      :add-allowed="editable"
      @files-selected="filesSelected"
      @file-uploaded="fileUploaded"
    ></person-photos-container>

    <person-saved-mentions
      :editable="editable"
      :person-id="person.id"
      :person-name="personPrimaryFullnameEn || personPrimaryFullnameCn || unknownName"
    ></person-saved-mentions>
  </div>
</template>

<script>
import {getLifetimeYears, isChineseText} from '@common/utils/utils';
import {getPlaceDetailRoute, getTreeClanDetailRoute} from '@common/utils/utils.routes';
import MapMarkerIcon from 'vue-material-design-icons/MapMarker';
import {mapGetters} from 'vuex';

import PersonIconColored from '@/base/icons/person/PersonIconColored';

import DateLocationDisplay from '@/components/common/tree/dateLocationDisplay';

import {UNKNOWN_NAME} from '@/components/modules/familyTree/constants';
import personProfilePictureCropModalContentContainer from '@/components/modules/familyTree/tree/gallery/personProfilePictureCropModalContentContainer';
import PersonPhotosContainer from '@/components/modules/familyTree/tree/modals/personPhotosContainer';

import personSavedMentions from './details/personSavedMentions';
import personDetailsRelatives from './personDetailsRelatives';

export default {
  components: {
    DateLocationDisplay,
    PersonPhotosContainer,
    personDetailsRelatives,
    PersonIconColored,
    MapMarkerIcon,
    personSavedMentions,
  },
  props: {
    person: Object,
    personPrimaryFullnameEn: String,
    personPrimaryFullnameCn: String,
    relatives: Object,
  },
  data() {
    return {
      photosContainer: 'photos-container',
      cropperInitialImage: {},
      profilePictureProcessing: false,
      addPhotosInitiator: 'personDetails',
    };
  },
  computed: {
    ...mapGetters(['familyTreePersonProfilePictureLoadingState']),
    surnames() {
      return this.person.surnames.map(name => name.value);
    },
    firstNamesByType() {
      let byType = {};
      for (let firstName of this.person.first_names) {
        const typeDisplay = firstName.type_display || 'First Name';
        byType[typeDisplay] = byType[typeDisplay] || [];
        byType[typeDisplay].push(firstName.value);
      }
      return byType;
    },
    surnamesByType() {
      let byType = {};
      for (let surname of this.person.surnames) {
        const typeDisplay = surname.type_display || 'Surname';
        byType[typeDisplay] = byType[typeDisplay] || [];
        byType[typeDisplay].push(surname.value);
      }
      return byType;
    },
    unknownName() {
      return this.person.is_current_user ? 'You' : UNKNOWN_NAME;
    },
    livingStatus() {
      return getLifetimeYears(this.person.birth_date, this.person.death_date, this.person.is_deceased);
    },
    editable() {
      return this.person.is_write_allowed;
    },
    showProfilePictureLoading() {
      return (
        !this.person.profile_picture.attachment &&
        (this.familyTreePersonProfilePictureLoadingState || this.profilePictureProcessing)
      );
    },
    separateSiblings() {
      const separatedSiblings = {
        fullSiblings: [],
        halfSiblings: [],
      };
      for (let sibling of this.relatives.siblings) {
        let isFullSibling = true;
        this.relatives.parents.map(p => {
          if (!sibling.parents_ids.includes(p.object_id)) {
            isFullSibling = false;
          }
        });
        if (isFullSibling) {
          separatedSiblings.fullSiblings.push(sibling);
        } else {
          separatedSiblings.halfSiblings.push(sibling);
        }
      }
      return separatedSiblings;
    },
  },
  methods: {
    getTreeClanDetailRoute,
    isChineseText,
    relativeClick(id) {
      this.$emit('click-relative', id);
    },
    profilePictureClick() {
      this.$store.dispatch('displayGalleryAction', this.person.profile_picture.asset_id);
    },
    personIconClick() {
      this.$refs[this.photosContainer].addPhotos(this.addPhotosInitiator);
    },
    filesSelected({initiator}) {
      if (
        initiator === this.addPhotosInitiator ||
        (!this.$refs[this.photosContainer].photos.length && !this.person.profile_picture.attachment)
      ) {
        this.profilePictureProcessing = true;
      }
    },
    fileUploaded(asset) {
      if (!this.cropperInitialImage.attachment && this.profilePictureProcessing) {
        this.cropperInitialImage = asset;
        const props = {
          src: this.cropperInitialImage.attachment,
          personId: this.person.object_id,
          assetId: this.cropperInitialImage.object_id,
        };
        const modalParams = {
          classes: 'clear_modal white_modal',
          height: 'auto',
          scrollable: true,
          name: personProfilePictureCropModalContentContainer.modalName,
        };
        this.$modal.show(personProfilePictureCropModalContentContainer, props, modalParams);
        this.profilePictureProcessing = false;
      }
    },
    getPlaceDetailRoute(placeId) {
      return getPlaceDetailRoute(placeId);
    },
    getFormattedResidenceText(residence) {
      const years =
        residence.from_year || residence.to_year ? ` (${residence.from_year || '?'}-${residence.to_year || '?'})` : '';
      return `${residence.display_text}${years}`;
    },
  },
};
</script>

<style lang="scss" scoped>
@import './tree-modal.scss';

.person-details-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding-top: 20px;

  .value-place {
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    &::v-deep .place-wrapper {
      display: flex;
      align-items: flex-start;
    }
  }

  @media only screen and (max-width: $breakpoint-phablet) {
    padding-top: 0;
  }
  .person-details-content {
    @media only screen and (min-width: $breakpoint-phablet) {
      h5 {
        display: none;
      }
    }

    .flex-row {
      margin-bottom: 20px;
      align-items: flex-start;
      .label {
        width: 90px;
      }
      &:last-child {
        margin-bottom: 0;
      }
    }

    .name {
      word-break: break-all;
    }

    @media only screen and (max-width: $breakpoint-phablet) {
      width: 100%;
      .flex-row {
        justify-content: space-between;
        border-bottom: 1px solid $divider-line-light;
        padding-bottom: 14px;
        margin-bottom: 14px;
        .value {
          text-align: right;
        }
        .value-place {
          justify-content: flex-end;
        }
      }
    }
  }
}
</style>

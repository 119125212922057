<template>
  <div class="existing-person-select">
    <div class="section">
      <div class="input-label">Person name</div>
      <family-tree-people-multiselect-container
        ref="people-select"
        class="bordered"
        :value="selectedPerson"
        :family-tree-id="treeId"
        :multiple="false"
        :allow-clear="false"
        @select="selectPerson"
      ></family-tree-people-multiselect-container>
    </div>
    <mcr-loading-indicator :loading="selectPersonLoading"></mcr-loading-indicator>
    <stand-out-block class="warning text-md" v-if="warningText">
      <div class="info-icon-wrapper">
        <info-icon :size="20"></info-icon>
      </div>
      <div class="text">
        <div><b>Adding relationship with this person will cause changes to your family tree.</b></div>
        <div>
          {{ warningText }}
        </div>
      </div>
    </stand-out-block>
  </div>
</template>

<script>
import StandOutBlock from '@common/elements/layouts/StandOutBlock.vue';
import InfoIcon from 'vue-material-design-icons/Information.vue';
import {mapGetters} from 'vuex';

import FamilyTreePeopleMultiselectContainer from '@/components/common/inputs/FamilyTreePeopleMultiselectContainer.vue';

import {
  CHILD_RELATION_TYPE,
  PARENT_RELATION_TYPE,
  SIBLING_RELATION_TYPE,
  SPOUSE_RELATION_TYPE,
} from '@/components/modules/familyTree/constants';
import {getPossibleChildrenList, getPossibleParentsList} from '@/components/modules/familyTree/tree/services';

export default {
  props: {
    relationType: String,
    treeId: Number,
    fromPersonData: Object,
  },
  data() {
    return {
      selectedPerson: null,
      selectPersonLoading: false,
    };
  },
  computed: {
    ...mapGetters(['familyRelativesByPersonIdState']),
    fullName() {
      return this.fromPersonData.full_name;
    },
    warningText() {
      if (!this.selectedPerson) {
        return '';
      }
      if (this.relationType === CHILD_RELATION_TYPE) {
        const relatives = this.familyRelativesByPersonIdState[this.selectedPerson.object_id];
        const parents = relatives ? relatives.parents : [];
        const parentsNames = parents && parents.length ? parents.map(p => p.full_name).join(' and ') : '';
        const parentsLabel = parents && parents.length === 1 ? 'parent' : 'parents';
        return parentsNames
          ? `Selecting ${this.selectedPerson.full_name} as a child of ${this.fullName} will replace ${this.selectedPerson.full_name}’s current ${parentsLabel} ${parentsNames}.`
          : '';
      }
      if (this.relationType === SIBLING_RELATION_TYPE) {
        const relatives = this.familyRelativesByPersonIdState[this.selectedPerson.object_id];
        const parents = relatives ? relatives.parents : [];
        const parentsNames = parents && parents.length ? parents.map(p => p.full_name).join(' and ') : '';
        const parentsLabel = parents && parents.length === 1 ? 'parent' : 'parents';

        const newRelatives = this.$store.getters.getFamilyTreePersonRelativesState(this.fromPersonData.object_id);
        const newParents = newRelatives ? newRelatives.parents : [];
        const newParentNames =
          newParents && newParents.length ? newParents.map(p => p.full_name).join(' and ') : 'Unknown Parent';
        return parentsNames
          ? `Selecting ${this.selectedPerson.full_name} as a sibling of ${this.fullName} will replace ${this.selectedPerson.full_name}’s current ${parentsLabel} ${parentsNames} with ${newParentNames}.`
          : '';
      }
    },
  },
  methods: {
    cleanup() {
      this.selectedPerson = null;
    },
    selectPerson(person) {
      this.selectPersonLoading = true;
      this.selectedPerson = person;
      this.$store
        .dispatch('fetchFamilyTreePersonRelativesInBackgroundAction', person.object_id)
        .then(() => {
          const parentsList = this.getPossibleParentsList(person);
          const childrenList = this.getPossibleChildrenList(person.object_id);
          this.$emit('select', {childrenList, parentsList});
        })
        .finally(() => {
          this.selectPersonLoading = false;
        });
    },
    getPossibleChildrenList(newPersonId) {
      if (this.relationType === SPOUSE_RELATION_TYPE) {
        return [
          ...getPossibleChildrenList(this.relationType, this.fromPersonData, this),
          ...getPossibleChildrenList(this.relationType, {object_id: newPersonId}, this),
        ];
      }
      if (this.relationType === PARENT_RELATION_TYPE) {
        return [
          ...getPossibleChildrenList(this.relationType, this.fromPersonData, this),
          ...getPossibleChildrenList(SPOUSE_RELATION_TYPE, {object_id: newPersonId}, this),
        ];
      }
      return [];
    },
    getPossibleParentsList(newPerson) {
      if (this.relationType === PARENT_RELATION_TYPE) {
        return [...getPossibleParentsList(CHILD_RELATION_TYPE, newPerson, this)];
      }
      if (this.relationType === CHILD_RELATION_TYPE) {
        return [...getPossibleParentsList(CHILD_RELATION_TYPE, this.fromPersonData, this)];
      }
      return [];
    },
  },
  name: 'ExistingPersonSelectForm',
  components: {StandOutBlock, FamilyTreePeopleMultiselectContainer, InfoIcon},
};
</script>

<style scoped lang="scss">
.existing-person-select {
  padding: 16px 24px 24px;
  .warning {
    margin-top: 24px;
    box-shadow: $slight-shadow-bottom;
    background: white;
    color: $neutral-500;
    padding: 12px 16px;
    display: flex;
    column-gap: 12px;

    .info-icon-wrapper {
      flex-shrink: 0;
      border-radius: 50%;
      background: $neutral-100;
      color: $neutral-600;
      width: 32px;
      height: 32px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .text {
      div + div {
        margin-top: 8px;
      }
    }
  }
}
</style>

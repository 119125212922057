<template>
  <div v-if="viewPortBoundaries" class="mini-map-scaler" :class="mainClasses">
    <div
      v-if="familyTreeMapImageState"
      @mouseenter="switchShowCloseButton"
      @mouseleave="switchShowCloseButton"
      @mouseup.left="stopMapDragging"
      @mousedown.left="navigateToClick"
      @mouseup.middle="middleClickAction"
      @mousemove="mousemove"
      class="mini-map"
      :style="miniMapSizeStyle"
    >
      <button class="expand-control" @mousedown.left="preventEvent" @click="closeMap">✕</button>
      <div class="mini-map-container" :style="miniMapStyle">
        <div
          class="mini-map-area"
          :style="miniMapAreaStyle"
          @mousedown.left="mousedown"
          @mouseup.left="stopMapDragging"
          @mousemove="mousemove"
          @touchstart="touchstart"
          @touchend="touchend"
          @touchmove.prevent="touchmove"
          @click="preventEvent"
        ></div>
      </div>
    </div>
    <div v-else>
      <mcr-loading-indicator :loading="true"></mcr-loading-indicator>
    </div>
  </div>
</template>
<script>
import throttle from 'lodash/throttle';
import {mapGetters} from 'vuex';

export default {
  props: {
    mapScale: {
      type: Number,
      default: 1,
    },
    scale: {
      type: Number,
      default: 1,
    },
    treeWidth: {
      type: Number,
      default: 0,
    },
    treeHeight: {
      type: Number,
      default: 0,
    },
    viewPortBoundaries: {
      type: Object,
    },
    xMargin: {
      type: Number,
      default: 0,
    },
  },
  mounted() {
    this.setMiniMapStyle(this.viewPortBoundaries);
  },
  data() {
    return {
      miniMapSize: {width: 200, height: 200},
      defaultMiniMapSizeLimit: {width: 260, height: 260},
      minWidth: 80,
      minHeight: 80,
      mapTransform: null,
      translateX: 0,
      translateY: 0,
      areaDimensions: {xInt: 0, yInt: 0, x: 0, y: 0, width: 0, height: 0, widthInt: 0, heightInt: 0},
      isGrabbing: false,
      draggingStart: {x: 0, y: 0},
      draggingEnd: {x: 0, y: 0},
      isPanning: false,
      transition: false,
      expandMap: false,
      extraScale: 1.0,
      middleClick: 0,
      showCloseButton: false,
    };
  },
  computed: {
    ...mapGetters([
      'familyTreeDrawnWidthState',
      'familyTreeDrawnHeightState',
      'familyTreeMapImageState',
      'familyTreeDrawnMarginXState',
    ]),
    backgroundMapSize() {
      return {
        width: this.familyTreeDrawnWidthState * this.mapScale,
        height: this.familyTreeDrawnHeightState * this.mapScale,
      };
    },
    miniMapSizeStyle() {
      let paddingVert = 5;
      let paddingHor = 5;
      if (this.miniMapSize.width < this.minWidth) {
        paddingHor = (this.minWidth - this.miniMapSize.width) / 2;
      }

      if (this.miniMapSize.height < this.minHeight) {
        paddingVert = (this.minHeight - this.miniMapSize.height) / 2;
      }

      return {
        width: this.miniMapSize.width + 'px',
        height: this.miniMapSize.height + 'px',
        transform: `scale(${this.extraScale})`,
        padding: `${paddingVert}px ${paddingHor}px`,
      };
    },
    miniMapStyle() {
      return {
        transform: this.mapTransform,
        backgroundImage: `url(${this.familyTreeMapImageState})`,
        'background-repeat': 'no-repeat',
        width: Math.round(this.backgroundMapSize.width) + 'px',
        height: Math.round(this.backgroundMapSize.height) + 'px',
        transition: this.transition ? 'all 0.1s ease' : 'none',
      };
    },
    miniMapAreaStyle() {
      return {
        left: `${this.areaDimensions.xInt - this.xMargin * this.mapScale}px`,
        top: `${this.areaDimensions.yInt}px`,
        width: `${this.areaDimensions.widthInt}px`,
        height: `${this.areaDimensions.heightInt}px`,
        cursor: this.isGrabbing ? 'grabbing' : 'grab',
        transition: this.transition ? 'all 0.1s ease' : 'none',
      };
    },
    miniMapImageWidth() {
      return this.familyTreeDrawnWidthState * this.mapScale;
    },
    miniMapImageHeight() {
      return this.familyTreeDrawnHeightState * this.mapScale;
    },
    miniMapSizeLimit() {
      if (this.expandMap) {
        return {width: this.defaultMiniMapSizeLimit.width * 2, height: this.defaultMiniMapSizeLimit.height * 2};
      } else {
        return {...this.defaultMiniMapSizeLimit};
      }
    },
    mapHasMore() {
      return this.miniMapImageWidth > this.miniMapSize.width || this.miniMapImageHeight > this.miniMapSize.height;
    },
    mainClasses() {
      if (this.$store.getters.needShowBreadCrumbsState) {
        return 'is-high';
      }
    },
  },
  methods: {
    closeMap(event) {
      this.$emit('close');
      this.preventEvent(event);
    },
    switchSize(event) {
      this.expandMap = !this.expandMap;

      this.$nextTick(function () {
        this.setMiniMapStyle();
      });

      this.preventEvent(event);
    },
    isLeftLimit(x) {
      return x > 0;
    },
    isRightLimit(x) {
      return x - this.miniMapSize.width + this.miniMapImageWidth <= 0;
    },
    isTopLimit(y) {
      return y > 0;
    },
    isBottomLimit(y) {
      return y - this.miniMapSize.height + this.miniMapImageHeight <= 0;
    },
    getX(centerMarginX) {
      return -Math.round(
        (this.viewPortBoundaries.left_x - this.familyTreeDrawnMarginXState - centerMarginX / this.mapScale) *
          this.mapScale
      );
    },
    getY(centerMarginY) {
      return -Math.round((this.viewPortBoundaries.top_y - centerMarginY / this.mapScale) * this.mapScale);
    },
    middleClickAction(event) {
      this.middleClick = this.middleClick + 1;
      setTimeout(() => {
        this.middleClick = this.middleClick - 1;
      }, 300);
      if (this.middleClick === 2) {
        this.switchSize(event);
      }
    },
    setMiniMapStyle: throttle(
      function () {
        if (this.miniMapImageHeight <= this.miniMapSizeLimit.height) {
          this.miniMapSize.height = this.miniMapImageHeight;
        } else {
          this.miniMapSize.height = this.miniMapSizeLimit.height;
        }

        if (this.miniMapImageWidth <= this.miniMapSizeLimit.width) {
          this.miniMapSize.width = this.miniMapImageWidth;
        } else {
          this.miniMapSize.width = this.miniMapSizeLimit.width;
        }

        let shift = 150;
        let areaX = this.areaDimensions.x;
        let areaY = this.areaDimensions.y;
        let areaWidth = this.areaDimensions.width;
        let areaHeight = this.areaDimensions.height;

        let newAreaX = this.viewPortBoundaries.left_x * this.mapScale;
        let newAreaY = this.viewPortBoundaries.top_y * this.mapScale;
        let newAreaWidth = this.viewPortBoundaries.width * this.mapScale;
        let newAreaHeight = (this.viewPortBoundaries.height + shift / 2) * this.mapScale;

        if (!this.isGrabbing) {
          areaX = newAreaX;
          areaY = newAreaY;
          areaWidth = newAreaWidth;
          areaHeight = newAreaHeight;
        }

        let isFullWidth = this.miniMapImageWidth <= this.miniMapSize.width;
        let isFullHeight = this.miniMapImageHeight <= this.miniMapSize.height;
        if (!isFullWidth || !isFullHeight) {
          if (this.isGrabbing) {
            let visibleAreaX = this.translateX + areaX - this.xMargin * this.mapScale;
            let visibleAreaY = this.translateY + areaY;
            let moveX = 0;
            let moveY = 0;

            if (!this.isRightLimit(this.translateX) && visibleAreaX + areaWidth >= this.miniMapSize.width) {
              moveX = visibleAreaX + areaWidth - this.miniMapSize.width;
            }
            if (!this.isLeftLimit(this.translateX) && visibleAreaX < 0) {
              moveX = visibleAreaX;
            }

            if (!this.isBottomLimit(this.translateY) && visibleAreaY + areaHeight > this.miniMapSize.height) {
              moveY = visibleAreaY + areaHeight - this.miniMapSize.height;
            }

            if (!this.isTopLimit(this.translateY) && visibleAreaY < 0) {
              moveY = visibleAreaY;
            }

            if (isFullWidth) {
              moveX = 0;
            }

            if (isFullHeight) {
              moveY = 0;
            }

            if (moveX || moveY) {
              this.isPanning = true;
              let scaledMoveX = (moveX * this.mapScale) / this.scale;
              let scaledMoveY = (moveY * this.mapScale) / this.scale;
              this.translateX -= scaledMoveX;
              this.translateY -= scaledMoveY;
              areaX += scaledMoveX;
              areaY += scaledMoveY;

              this.$emit('move', {x: -moveX, y: -moveY});
            }
          } else {
            if (!isFullWidth) {
              let centerMarginX = (this.miniMapSize.width - areaWidth) / 2;
              let x = this.getX(centerMarginX);
              if (this.isLeftLimit(x)) {
                x = 0;
              }

              if (this.isRightLimit(x)) {
                x = this.miniMapSize.width - this.miniMapImageWidth;
              }
              this.translateX = x;
            }

            if (!isFullHeight) {
              let centerMarginY = (this.miniMapSize.height - areaHeight) / 2;
              let y = this.getY(centerMarginY);

              if (this.isTopLimit(y)) {
                y = 0;
              }

              if (this.isBottomLimit(y)) {
                y = this.miniMapSize.height - this.miniMapImageHeight;
              }

              this.translateY = y;
            }
          }
        }

        this.mapTransform = `translate3d(${this.translateX}px, ${this.translateY}px, 0)`;
        this.areaDimensions = {
          x: areaX,
          xInt: Math.round(areaX),
          y: areaY,
          yInt: Math.round(areaY),
          width: areaWidth,
          height: areaHeight,
          widthInt: Math.round(areaWidth),
          heightInt: Math.round(areaHeight),
        };
      },
      33,
      {leading: true, trailing: true}
    ),
    preventEvent(event) {
      if (event) {
        event.stopPropagation();
        event.preventDefault();
      }
    },
    mousedown(event) {
      this.isGrabbing = true;
      this.preventEvent(event);
    },
    switchShowCloseButton(event) {
      this.showCloseButton = !this.showCloseButton;
      this.stopMapDragging(event);
    },
    stopMapDragging(event) {
      if (!this.isGrabbing) {
        return;
      }
      this.isGrabbing = false;
      this.transition = true;
      this.setMiniMapStyle();
      setTimeout(() => {
        this.transition = false;
      }, 150);
      this.preventEvent(event);
    },
    translateMove(movementX, movementY) {
      if (this.isRightLimit(this.translateX) && this.isLeftLimit(this.translateX)) {
        movementX = 0;
      }
      if (this.isBottomLimit(this.translateY) && this.isTopLimit(this.translateY)) {
        movementY = 0;
      }

      this.areaDimensions.x += movementX / this.extraScale;
      let scaleMoveX = ((movementX / this.mapScale) * this.scale) / this.extraScale;

      this.areaDimensions.y += movementY / this.extraScale;
      let scaleMoveY = ((movementY / this.mapScale) * this.scale) / this.extraScale;

      this.$emit('move', {x: scaleMoveX * -1, y: scaleMoveY * -1});
    },
    mousemove(event) {
      if (this.isGrabbing) {
        let {movementX, movementY} = event;
        this.translateMove(movementX, movementY);
      }
      this.preventEvent(event);
    },
    touchstart(event) {
      if (event.touches.length === 1) {
        this.isGrabbing = true;
        this.draggingStart = {x: event.changedTouches[0].pageX, y: event.changedTouches[0].pageY};
      }

      this.preventEvent(event);
    },
    touchend(event) {
      this.isGrabbing = false;
      this.transition = true;
      setTimeout(() => {
        this.transition = false;
      }, 150);
      this.preventEvent(event);
    },
    touchmove(event) {
      if (event.touches.length === 1 && this.isGrabbing) {
        const movementX = event.touches[0].pageX - this.draggingStart.x;
        const movementY = event.touches[0].pageY - this.draggingStart.y;
        this.translateMove(movementX, movementY);
        this.draggingStart = {x: event.changedTouches[0].pageX, y: event.changedTouches[0].pageY};
      }
    },
    navigateToClick(event) {
      this.isGrabbing = true;
      const miniMargin =  (this.xMargin * this.mapScale);
      const x = event.offsetX + miniMargin;
      const y = event.offsetY;


      const halfWidth = this.areaDimensions.width / 2;
      const halfHeight = this.areaDimensions.height / 2;
      const currentX = this.areaDimensions.x;
      const currentY = this.areaDimensions.y;

      const newX = x - halfWidth;
      const newY = y - halfHeight;

      this.areaDimensions.x = newX;
      this.areaDimensions.y = newY;

      this.setMiniMapStyle();
      const diffX = newX - currentX;
      const diffY = newY - currentY;

      const scaleMoveX = (diffX / this.mapScale) * this.scale;
      const scaleMoveY = (diffY / this.mapScale) * this.scale;

      this.$emit('move', {x: -scaleMoveX, y: -scaleMoveY});
      this.preventEvent(event);
    },
  },
};
</script>

<style scoped lang="scss">
.mini-map-scaler {
  position: fixed;
  right: 70px;
  bottom: 20px;
  pointer-events: none;
  backface-visibility: hidden;
  z-index: 20;
  transform-origin: bottom right;

  @media only screen and (max-width: $main-menu-breakpoint) {
    bottom: 12px;
  }

  @media only screen and (max-width: $breakpoint-mobile) {
    left: 12px;
    bottom: 12px;

    &.is-high {
      bottom: 60px;
    }
  }
}

.mini-map {
  backface-visibility: hidden;
  pointer-events: auto;
  z-index: 20;
  overflow: hidden;
  transform-origin: bottom right;
  box-shadow: $box-shadow-extra-light;
  outline: 1px solid $neutral-300;
  border-radius: 4px;

  background-color: white;
  cursor: pointer;

  .expand-control {
    z-index: 22;
    font-size: 21px;
    padding: 8px;
    line-height: 21px;
    position: absolute;
    border: none;
    border-radius: 0;
    display: flex;
    background: none;
    text-shadow: none;

    color: $text-alternate-color;
    left: 0;
    top: 0;
  }

  .mini-map-container {
  }

  .mini-map-area {
    position: absolute;
    border: 1px solid $primary-400;
  }
}
</style>

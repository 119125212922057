<template>
  <images-gallery-overlay-container
    class="person-photos-gallery-overlay"
    ref="container"
    :allow-touch="!isModalShown"
    :is-mode-area-selection="isOcrSelectionMode"
    @area-selected="onAreaSelected"
    @click-close="cropCancel"
    @hide-image="hideDetails"
    @display-item-changed="displayItemChanged"
    @image-loaded="onImageLoad"
  >
    <template v-slot:absolute-actions="props">
      <div class="mobile-title">
        <div class="heading-6 text-md-mobile main-title">Photos of {{ currentPersonFullName }}</div>
        <div class="photo-count text-md-mobile">{{ props.currentIndex + 1 }} of {{ props.itemsCount }} photos</div>
      </div>
    </template>
    <template v-slot:image-port-actions>
      <extract-text-prompt
        :shown="isOcrSelectionMode"
        :disabled="isOcrConfirmDisabled"
        @dismiss="toggleExtractText"
        @confirm="onExtractTextConfirm"
      ></extract-text-prompt>
    </template>
    <div class="info-bar-content" slot="info-bar" slot-scope="props">
      <div class="heading-6 main-title">Photos of {{ currentPersonFullName }}</div>
      <p class="photo-count">{{ props.currentIndex + 1 }} of {{ props.itemsCount }} photos</p>
      <div class="info-bar-actions" v-if="areDetailsShown(props.item.object_id)">
        <div class="icon-action" v-if="isFileImage(props.item)">
          <div class="icon-container" @click="toggleExtractText(props.item)" :class="{'is-active': isOcrSelectionMode}">
            <ocr-icon :size="24"></ocr-icon>
          </div>
          <div class="label text-sm">Extract Text</div>
        </div>
        <div v-if="canMakeProfilePicture(props.item)" class="make-profile-picture icon-action">
          <div class="icon-container" @click="makeProfilePictureClick(props.item)">
            <account-box-multiple-outline-icon></account-box-multiple-outline-icon>
          </div>
          <div class="label text-sm">Set Profile</div>
        </div>
        <asset-download-button
          v-if="isValidImage(props.item) && isDesktop"
          :href="props.item.attachment"
          :filename="familyTreeAssetDetailsByIdState[props.item.object_id].filename"
        ></asset-download-button>
        <div v-if="canRemovePicture(props.item) && isDesktop" class="icon-action remove-picture">
          <div class="icon-container" @click="removePictureClick(props.item)">
            <delete-icon :size="24"></delete-icon>
          </div>
          <div class="label text-sm">Delete</div>
        </div>
        <div v-if="!isDesktop" class="icon-action">
          <div class="icon-container" @click="openMobileDetails(props.item)">
            <pencil-icon :size="24"></pencil-icon>
          </div>
          <div class="label text-sm">{{ toggleDetailsText }}</div>
        </div>
        <div v-if="!isDesktop" class="icon-action">
          <div class="icon-container" @click="openMoreOptionsModal(props.item)">
            <dots-vertical-icon :size="24"></dots-vertical-icon>
          </div>
          <div class="label text-sm">More</div>
        </div>
      </div>
      <div class="additional-actions text-sm"></div>

      <asset-details-block
        v-if="isDetailsBlockShown"
        :is-edit-allowed="isEditAllowed"
        :asset-details="familyTreeAssetDetailsByIdState[props.item.object_id]"
      ></asset-details-block>
    </div>
    <template slot="modals" slot-scope="props">
      <modal
        :name="profilePictureDeleteModalName"
        classes="clear_modal white_modal"
        class="mobile_bottom"
        @opened="setModalOpened"
        @closed="setModalClosed"
      >
        <delete-confirm-modal-content
          :modal-name="profilePictureDeleteModalName"
          :loading="familyTreePersonDeleteAssetLoadingState"
          @confirm="deleteAsset(props.item)"
        >
          <h5>Are you sure you want to delete this photo?</h5>
        </delete-confirm-modal-content>
      </modal>
    </template>
  </images-gallery-overlay-container>
</template>

<script>
import imagesGalleryOverlayContainer from '@common/elements/gallery/imagesGalleryOverlayContainer';
import {isImageFile} from '@common/elements/layouts/file-preview/filePreviewHelper';
import AnalyticsMainHandler from '@common/utils/analytics/analytics.main';
import {getRoutePageName} from '@common/utils/analytics/utils.analytics';
import {preload} from '@common/utils/utils.sources';
import AccountBoxMultipleOutlineIcon from 'vue-material-design-icons/AccountBoxMultipleOutline';
import DeleteIcon from 'vue-material-design-icons/Delete';
import DotsVerticalIcon from 'vue-material-design-icons/DotsVertical';
import OcrIcon from 'vue-material-design-icons/Ocr';
import PencilIcon from 'vue-material-design-icons/Pencil';
import {mapGetters} from 'vuex';

import DeleteConfirmModalContent from '@/base/elements/modals/deleteConfirmModalContent';

import AssetDetailsBlock from '@/components/common/tree/AssetDetailsBlock.vue';
import AssetDetailsBlockModal from '@/components/common/tree/AssetDetailsBlockModal';
import AssetMoreOptionsModal from '@/components/common/tree/AssetMoreOptionsModal';
import ExtractTextModalContent from '@/components/common/tree/ExtractTextModalContent.vue';
import ExtractTextPrompt from '@/components/common/tree/ExtractTextPrompt';

import {UNKNOWN_NAME} from '@/components/modules/familyTree/constants';
import personProfilePictureCropModalContentContainer from '@/components/modules/familyTree/tree/gallery/personProfilePictureCropModalContentContainer';

import AssetDownloadButton from '../../../familyTreeLibrary/common/AssetDownloadButton';

export default {
  computed: {
    ...mapGetters([
      'galleryDisplayItemState',
      'familyTreePersonProfilePictureLoadingState',
      'familyTreePersonDeleteAssetLoadingState',
      'familyTreeAssetDetailsLoadingState',
      'familyTreeAssetDetailsByIdState',
      'windowWidthState',
    ]),
    currentPersonFullName() {
      return this.galleryDisplayItemState.personNameEn || this.galleryDisplayItemState.personNameCn
        ? `${this.galleryDisplayItemState.personNameEn || ''} ${this.galleryDisplayItemState.personNameCn || ''}`
        : UNKNOWN_NAME;
    },
    profilePictureCropModalName() {
      return 'profile-picture-crop-modal';
    },
    isEditAllowed() {
      return this.galleryDisplayItemState.editable;
    },
    personId() {
      return this.galleryDisplayItemState.personId;
    },
    toggleDetailsText() {
      return this.isEditAllowed ? 'Edit Details' : 'View Details';
    },
    isDesktop() {
      return this.windowWidthState >= this.$breakpoints.desktop;
    },
    isDetailsBlockShown() {
      return (
        !this.areDetailsHidden &&
        this.familyTreeAssetDetailsByIdState[this.galleryDisplayItemState.object_id] &&
        this.isDesktop &&
        !this.isOcrSelectionMode
      );
    },
  },
  data() {
    return {
      cropModalRef: 'crop-modal',
      profilePictureDeleteModalName: 'profile-picture-delete-modal',
      areDetailsHidden: false,
      isOcrSelectionMode: false,
      isOcrConfirmDisabled: true,
      isModalShown: false,
    };
  },
  methods: {
    canMakeProfilePicture(item) {
      return this.isValidImage(item) && isImageFile(item.filename) && this.isEditAllowed;
    },
    isFileImage(item) {
      return this.isValidImage(item) && isImageFile(item.filename) && this.isEditAllowed;
    },
    canRemovePicture(item) {
      return this.isValidImage(item) && this.isEditAllowed;
    },
    isValidImage(item) {
      return !item.error && !item.uploading;
    },
    makeProfilePictureClick(item) {
      const props = {
        personId: this.personId,
        src: item.src,
        assetId: item.object_id,
      };
      const modalParams = {
        name: this.profilePictureCropModalName,
        class: 'profile-picture-crop-modal over-gallery-overlay',
        classes: 'clear_modal white_modal',
        height: 'auto',
        scrollable: true,
      };
      const events = {
        opened: this.setModalOpened,
        closed: this.onCropClosed,
      };
      this.$modal.show(personProfilePictureCropModalContentContainer, props, modalParams, events);
    },
    removePictureClick(item) {
      this.$modal.show(this.profilePictureDeleteModalName);
    },
    onCropClosed() {
      this.$store.commit('mutateProfileCropImageUrl', {url: null});
      this.setModalClosed();
    },
    cropCancel() {
      this.$modal.hide(this.profilePictureCropModalName);
    },
    setModalOpened() {
      this.isModalShown = true;
    },
    setModalClosed() {
      this.isModalShown = false;
    },
    deleteAsset(item) {
      if (item.object_id) {
        this.$store
          .dispatch('deletePersonAssetAction', {
            assetId: item.object_id,
            personId: this.personId,
          })
          .then(() => {
            this.$modal.hide(this.profilePictureDeleteModalName);
          });
      }
    },
    getAssetDetails(assetId) {
      this.$store.dispatch('getAssetDetailsAction', {assetId});
    },
    openMobileDetails(item) {
      const props = {
        isEditAllowed: this.isEditAllowed,
        assetId: item.object_id,
      };
      const modalParams = {
        classes: 'clear_modal dark_modal',
        class: 'mobile_bottom over-gallery-overlay',
        name: 'asset-edit',
        height: 'auto',
        scrollable: true,
      };
      this.$modal.show(AssetDetailsBlockModal, props, modalParams);
    },
    openMoreOptionsModal(item) {
      const props = {
        href: item.attachment,
        filename: this.familyTreeAssetDetailsByIdState[item.object_id].filename,
        deleteHandler: () => this.removePictureClick(item),
        canDelete: this.canRemovePicture(item),
      };
      const modalParams = {
        classes: 'clear_modal dark_modal',
        class: 'mobile_bottom over-gallery-overlay',
        name: 'more-options',
      };
      this.$modal.show(AssetMoreOptionsModal, props, modalParams);
    },
    areDetailsShown(objectId) {
      return !this.areDetailsHidden && objectId && this.familyTreeAssetDetailsByIdState[objectId];
    },
    hideDetails() {
      this.areDetailsHidden = true;
    },
    displayItemChanged(newItem) {
      this.areDetailsHidden = false;
      this.isOcrSelectionMode = false;
      this.cropCancel();
      if (newItem.object_id) {
        this.getAssetDetails(newItem.object_id);
      }
    },
    onImageLoad() {
      if (this.$store.getters.galleryHasNextItemState) {
        const src = this.$store.getters.galleryItemsState[this.$store.getters.galleryCurrentIndexState + 1].src;
        preload([src]);
      }
    },
    onAreaSelected(value) {
      this.isOcrConfirmDisabled = false;
    },
    toggleExtractText(item) {
      if (!this.isOcrSelectionMode) {
        AnalyticsMainHandler.trackClickSelectToOcrEvent(getRoutePageName(this.$route));
      }
      if (!this.isDesktop && !this.isOcrSelectionMode) {
        const props = {src: item.src};
        this.$modal.show(ExtractTextModalContent, props, {
          height: 'auto',
          scrollable: true,
          classes: 'clear_modal white_modal',
          class: 'over-gallery-overlay',
          name: 'ocr',
        });
        return;
      }
      this.isOcrSelectionMode = !this.isOcrSelectionMode;
      this.isOcrConfirmDisabled = true;
    },
    onExtractTextConfirm(value) {
      if (this.$refs.container && this.$refs.container.$refs.gallery) {
        this.$refs.container.$refs.gallery.confirmOcrSelection(value);
      }
    },
  },
  components: {
    AssetDetailsBlock,
    ExtractTextPrompt,
    AssetDownloadButton,
    DeleteConfirmModalContent,
    imagesGalleryOverlayContainer,
    AccountBoxMultipleOutlineIcon,
    DeleteIcon,
    PencilIcon,
    OcrIcon,
    DotsVerticalIcon,
  },
};
</script>

<style lang="scss" scoped>
@import '@common/style/gallery.info';

.person-photos-gallery-overlay::v-deep {
  .image-port .image-container .image {
    overflow: hidden;
  }
}
</style>

<template>
  <div class="extract-text-modal-content with-sticky-buttons">
    <close-button @click="closeModal"></close-button>
    <div class="content">
      <div class="heading-5">Select the area to extract text from</div>
      <div class="loading-container" v-if="imgLoading">
        <mcr-loading-indicator :loading="true"></mcr-loading-indicator>
      </div>
      <div class="cropper-wrapper" :class="{loaded: !imgLoading}">
        <cropper
          class="cropper"
          ref="cropper"
          :src="src"
          :min-width="120"
          :canvas="false"
          @change="onChange"
          @ready="onImageLoaded"
        />
      </div>
    </div>
    <div class="action-buttons">
      <mcr-button @click="confirm" class="full-width">Extract Selected Area</mcr-button>
    </div>
  </div>
</template>
<script>
import CloseButton from '@common/elements/buttons/closeButton.vue';
import McrButton from '@common/elements/buttons/mcrButton.vue';
import OcrAreaConfirmModal from '@common/elements/layouts/area-selection/OcrAreaConfirmModal.vue';
import {Cropper} from 'vue-advanced-cropper';
import 'vue-advanced-cropper/dist/style.css';

export default {
  props: {
    src: String,
  },
  data() {
    return {
      croppa: {},
      imgLoading: true,
      coordinates: null,

      imageWidth: 0,
      imageHeight: 0,
      imageScale: 1,
    };
  },
  methods: {
    onChange({coordinates, image, imageTransforms}) {
      this.coordinates = coordinates;
      this.imageWidth = imageTransforms && imageTransforms.rotate === 90 ? image.height : image.width;
      this.imageHeight = imageTransforms && imageTransforms.rotate === 90 ? image.width : image.height;
      this.imageScale = 1;
    },
    onImageLoaded() {
      this.imgLoading = false;
    },
    closeModal() {
      this.$emit('close');
    },
    confirm() {
      const x1 = this.coordinates.left;
      const y1 = this.coordinates.top;
      const x2 = this.coordinates.left + this.coordinates.width;
      const y2 = this.coordinates.top + this.coordinates.height;
      const width = this.imageWidth;
      const height = this.imageHeight;
      this.showConfirmModal(x1, x2, y1, y2, width, height);
    },
    showConfirmModal(x1, x2, y1, y2, width, height) {
      this.$modal.show(
        OcrAreaConfirmModal,
        {
          imageWidth: width,
          imageHeight: height,
          url: this.src,
          x1: x1,
          x2: x2,
          y1: y1,
          y2: y2,
          renderScale: this.imageScale,
        },
        {
          height: 'auto',
          scrollable: true,
          classes: 'clear_modal white_modal',
          class: 'over-gallery-overlay',
          name: 'ocr',
        },
        {
          'before-open': () => {
            this.closeModal();
          },
        }
      );
    },
  },
  name: 'ExtractTextModalContent',
  components: {McrButton, Cropper, CloseButton},
};
</script>

<style scoped lang="scss">
.extract-text-modal-content {
  position: relative;
  box-sizing: border-box;
  max-width: 800px;

  .cropper-wrapper,
  .loading-container {
    height: 400px;
    overflow: hidden;
    margin: 20px 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .cropper-wrapper.loaded {
    background: black;
  }

  .loading-container {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    left: 0;
    right: 0;

    .mcr-loading-indicator {
      margin: 0;
    }
  }

  @media only screen and (min-width: $breakpoint-phablet) {
    .cropper-wrapper {
      width: 500px;
    }
  }
}
</style>

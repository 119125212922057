<template>
  <div class="person-full-edit-form text-md text-lg-mobile sections">
    <slot name="extra-fields"></slot>

    <div class="section names-section">
      <div class="content-item" :id="getCommonSectionId('first_names')">
        <div class="label">English First Name</div>
        <div class="values">
          <div class="sub-forms">
            <div
              class="sub-form"
              :class="{removable: index !== 0}"
              v-for="(name, index) in personFormData.firstNamesEn"
            >
              <div class="inline column-mobile">
                <input
                  class="input"
                  type="text"
                  :value="name.value"
                  @input="onNameValueChange(name, personFormData.firstNamesEn, $event, 'first_names')"
                />
                <name-type-select
                  :options="firstNameTypeOptions"
                  :value="name.type"
                  @select="onNameTypeChange(name, personFormData.firstNamesEn, $event, 'first_names')"
                ></name-type-select>
              </div>
              <div class="delete" @click="deleteName(name, personFormData.firstNamesEn, 'first_names')">
                <delete-icon :size="20" />
                <div class="text-sm text-md-mobile">Delete</div>
              </div>
            </div>
          </div>

          <a class="link add-name" @click="addNewName(personFormData.firstNamesEn, 'first_names')"
            ><plus-icon :size="14"></plus-icon><span>Add English First Name</span></a
          >
        </div>
      </div>

      <div class="content-item" :id="getCommonSectionId('surnames')">
        <div class="label">English Last Name</div>
        <div class="values">
          <div class="sub-forms">
            <div class="sub-form" :class="{removable: index !== 0}" v-for="(name, index) in personFormData.surnamesEn">
              <div class="inline column-mobile">
                <input
                  class="input"
                  type="text"
                  :value="name.value"
                  @input="onNameValueChange(name, personFormData.surnamesEn, $event, 'surnames')"
                />
                <name-type-select
                  :options="surnameTypeOptions"
                  :value="name.type"
                  @select="onNameTypeChange(name, personFormData.surnamesEn, $event, 'surnames')"
                ></name-type-select>
              </div>
              <div class="delete" @click="deleteName(name, personFormData.surnamesEn, 'surnames')">
                <delete-icon :size="20" />
                <div class="text-sm text-md-mobile">Delete</div>
              </div>
            </div>
          </div>

          <a class="link add-name" @click="addNewName(personFormData.surnamesEn, 'surnames')"
            ><plus-icon :size="14"></plus-icon><span>Add English Last Name</span></a
          >
        </div>
      </div>
    </div>

    <div class="section names-section">
      <div class="content-item">
        <div class="label">Chinese First Name</div>
        <div class="values">
          <div class="sub-forms">
            <div
              class="sub-form"
              :class="{removable: index !== 0}"
              v-for="(name, index) in personFormData.firstNamesCn"
            >
              <div class="inline column-mobile">
                <input
                  class="input"
                  type="text"
                  :value="name.value"
                  @input="onNameValueChange(name, personFormData.firstNamesCn, $event, 'first_names')"
                />
                <name-type-select
                  :options="firstNameTypeOptions"
                  :value="name.type"
                  @select="onNameTypeChange(name, personFormData.firstNamesCn, $event, 'first_names')"
                ></name-type-select>
              </div>
              <div class="delete" @click="deleteName(name, personFormData.firstNamesCn, 'first_names')">
                <delete-icon :size="20" />
                <div class="text-sm text-md-mobile">Delete</div>
              </div>
            </div>
          </div>
          <a class="link add-name" @click="addNewName(personFormData.firstNamesCn, 'first_names')"
            ><plus-icon :size="14"></plus-icon><span>Add Chinese First Name</span></a
          >
        </div>
      </div>
      <div class="content-item">
        <div class="label">Chinese Last Name</div>
        <div class="values">
          <div class="sub-forms">
            <div class="sub-form" :class="{removable: index !== 0}" v-for="(name, index) in personFormData.surnamesCn">
              <div class="inline column-mobile">
                <surname-autocomplete-select
                  :value="name.value"
                  :init-search-value="getChineseSurnameInitSearchValue(name.type)"
                  class="chinese-surname-select"
                  @change="onNameValueChange(name, personFormData.surnamesCn, $event, 'surnames')"
                ></surname-autocomplete-select>
                <name-type-select
                  :options="surnameTypeOptions"
                  :value="name.type"
                  @select="onNameTypeChange(name, personFormData.surnamesCn, $event, 'surnames')"
                ></name-type-select>
              </div>
              <div class="delete" @click="deleteName(name, personFormData.surnamesCn, 'surnames')">
                <delete-icon :size="20" />
                <div class="text-sm text-md-mobile">Delete</div>
              </div>
            </div>
          </div>
          <a class="link add-name" @click="addNewSurname(personFormData.surnamesCn)"
            ><plus-icon :size="14"></plus-icon><span>Add Chinese Last Name</span></a
          >
        </div>
      </div>
    </div>

    <div class="section" :id="getCommonSectionId('gender')">
      <div class="content-item">
        <div class="label">Gender</div>
        <div class="values">
          <div class="field radio">
            <template v-for="(name, value) in familyTreePersonOptionsState.genders">
              <input
                :id="value"
                type="radio"
                name="gender-radio"
                :value="value"
                v-model="personFormData.gender"
                @input="addToUnsavedFields('gender')"
              />
              <label :for="value">{{ name }}</label>
            </template>
          </div>
        </div>
      </div>
    </div>

    <div class="section" :id="getCommonSectionId('birth')">
      <div class="content-item">
        <div class="label">Birth</div>
        <div class="values">
          <div class="sub-forms">
            <div class="sub-form">
              <approximate-date-field
                :day="personFormData.birth_date.day"
                :month="personFormData.birth_date.month"
                :year="personFormData.birth_date.year"
                :approximation-years="personFormData.birth_date.approximation_years"
                @change-day="changeApproximateDate('birth_date', {day: $event})"
                @change-month="changeApproximateDate('birth_date', {month: $event})"
                @change-year="changeApproximateDate('birth_date', {year: $event})"
                @change-approximation-years="changeApproximateDate('birth_date', {approximation_years: $event})"
              ></approximate-date-field>
              <div>
                <div class="input-label">Birth Place</div>
                <slot name="birth-place-widget" :form-data="personFormData">
                  <family-tree-location-multiselect-container
                    ref="birthplace"
                    placeholder=""
                    multiselect-classes="bordered"
                    :always-show-remove="true"
                    :location="personFormData.birth_location"
                    :family-tree-id="personFormData.family_tree_id"
                    :show-id-warning="true"
                    @clear="clearBirthLocation"
                    @select="changeBirthLocation"
                  ></family-tree-location-multiselect-container>
                </slot>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="section" :id="getCommonSectionId('living_status')">
      <div class="content-item">
        <div class="label">Status</div>
        <div class="values">
          <div class="field radio">
            <input
              id="living-status"
              type="radio"
              name="status-radio"
              :value="false"
              v-model="personFormData.is_deceased"
              @input="addToUnsavedFields('is_deceased')"
            />
            <label for="living-status">Living</label>
            <input
              id="deceased-status"
              type="radio"
              name="status-radio"
              :value="true"
              v-model="personFormData.is_deceased"
              @input="addToUnsavedFields('is_deceased')"
            />
            <label for="deceased-status">Deceased</label>
            <input
              id="unknown-status"
              type="radio"
              name="status-radio"
              :value="null"
              v-model="personFormData.is_deceased"
              @input="addToUnsavedFields('is_deceased')"
            />
            <label for="unknown-status">Unknown</label>
          </div>
        </div>
      </div>
    </div>

    <div class="section" v-show="personFormData.is_deceased" :id="getCommonSectionId('death')">
      <div class="content-item">
        <div class="label">Death</div>
        <div class="values">
          <div class="sub-forms">
            <div class="sub-form">
              <approximate-date-field
                :day="personFormData.death_date.day"
                :month="personFormData.death_date.month"
                :year="personFormData.death_date.year"
                :approximation-years="personFormData.death_date.approximation_years"
                @change-day="changeApproximateDate('death_date', {day: $event})"
                @change-month="changeApproximateDate('death_date', {month: $event})"
                @change-year="changeApproximateDate('death_date', {year: $event})"
                @change-approximation-years="changeApproximateDate('death_date', {approximation_years: $event})"
              ></approximate-date-field>
              <div>
                <div class="input-label">Death Place</div>
                <slot name="death-place-widget" :form-data="personFormData">
                  <family-tree-location-multiselect-container
                    placeholder=""
                    :location="personFormData.death_location"
                    :family-tree-id="personFormData.family_tree_id"
                    multiselect-classes="bordered"
                    :always-show-remove="true"
                    :show-id-warning="true"
                    @clear="clearDeathLocation"
                    @select="changeDeathLocation"
                  ></family-tree-location-multiselect-container>
                </slot>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="section" :id="getCommonSectionId('residence_location')">
      <div class="content-item">
        <div class="label">Residence</div>
        <div class="values">
          <div class="sub-forms" v-if="personFormData.residence_location.length">
            <div class="sub-form removable" v-for="residence in personFormData.residence_location">
              <div>
                <div class="input-label">Residence Place</div>
                <slot name="residence-place-widget" :form-data="residence">
                  <family-tree-location-multiselect-container
                    placeholder=""
                    multiselect-classes="bordered"
                    :always-show-remove="true"
                    :location="residence"
                    :family-tree-id="personFormData.family_tree_id"
                    :show-id-warning="true"
                    @clear="clearResidenceLocationDisplayText(residence)"
                    @select="changeResidenceLocation(residence, $event)"
                  ></family-tree-location-multiselect-container>
                </slot>
              </div>
              <div class="inline">
                <div>
                  <div class="input-label">Start Year</div>
                  <input
                    :value="residence.from_year"
                    placeholder="YYYY"
                    maxlength="4"
                    size="4"
                    class="input"
                    @change="changeResidenceLocation(residence, {from_year: $event.target.value})"
                  />
                </div>
                <div>
                  <div class="input-label">End Year</div>
                  <input
                    :value="residence.to_year"
                    placeholder="YYYY"
                    maxlength="4"
                    size="4"
                    class="input"
                    @change="changeResidenceLocation(residence, {to_year: $event.target.value})"
                  />
                </div>
              </div>
              <div class="delete" @click="deleteResidence(residence)">
                <delete-icon :size="20" />
                <div class="text-sm text-md-mobile">Delete</div>
              </div>
            </div>
          </div>
          <a class="link add-name" @click="addResidence"
            ><plus-icon :size="14"></plus-icon><span>Add Residence</span></a
          >
        </div>
      </div>
    </div>

    <div class="section">
      <div class="content-item" :id="getCommonSectionId('notes')">
        <div class="label">Biography</div>
        <div class="values">
          <textarea
            v-model="personFormData.notes"
            class="input"
            @input="addToUnsavedFields('notes')"
            @keyup.enter.stop
          ></textarea>
        </div>
      </div>

      <div class="content-item" :id="getCommonSectionId('generation_number')">
        <div class="label">
          <mcr-wiki-glossary-link naturalId="generation-number" :is-help-text="true"
            >Generation Number</mcr-wiki-glossary-link
          >
        </div>
        <div class="values">
          <input
            v-model="personFormData.generation_number"
            @input="addToUnsavedFields('generation_number')"
            type="number"
            class="input"
          />
        </div>
      </div>
    </div>

    <div
      class="section"
      v-for="factSection in groupedFacts"
      :key="factSection.key"
      :id="getFactSectionId(factSection.factType.id)"
    >
      <div class="content-item">
        <div v-if="factSection.sectionCategory" class="section-category text-sm text-md-mobile">
          {{ factSection.sectionCategory }}
        </div>
        <div class="label">{{ factSection.label }}</div>
        <div class="values">
          <div class="sub-forms">
            <div class="sub-form removable" v-for="fact in factSection.items" :key="fact.id || fact._id">
              <person-fact-form
                :fact="fact"
                :options="familyTreePersonOptionsState.fact_types"
                :family-tree-id="personFormData.family_tree_id"
                @change-value="changeFact(fact, 'value', $event)"
                @change-place="changeFactPlace(fact, $event)"
                @change-start-date="changeFact(fact, 'start_date', $event)"
                @change-end-date="changeFact(fact, 'end_date', $event)"
                @change-cemetery="changeFact(fact, 'cemetery', $event)"
                @change-info="changeFact(fact, 'info', $event)"
              >
              </person-fact-form>
              <div class="delete" @click="deleteFact(fact)">
                <delete-icon :size="20" />
                <div class="text-sm text-md-mobile">Delete</div>
              </div>
            </div>
          </div>

          <a class="link add-name" @click="addEmptyFact(factSection.factType)"
            ><plus-icon :size="14"></plus-icon><span>Add {{ factSection.label }}</span></a
          >
        </div>
      </div>
    </div>

    <div class="section" v-if="$slots['extra-fields-end']">
      <slot name="extra-fields-end"></slot>
    </div>
  </div>
</template>

<script>
import mcrWikiGlossaryLink from '@common/elements/glossary/mcrWikiGlossaryLink';
import SurnameAutocompleteSelect from '@common/elements/inputs/SurnameAutocompleteSelect';
import consts from '@common/utils/consts';
import {getRandomString, isChineseText} from '@common/utils/utils';
import sortBy from 'lodash/sortBy';
import DeleteIcon from 'vue-material-design-icons/DeleteOutline';
import PlusIcon from 'vue-material-design-icons/Plus';
import {mapGetters} from 'vuex';

import FamilyTreeLocationMultiselectContainer from '@/components/common/FamilyTreeLocationMultiselectContainer';

import {getEditSectionScrollId} from '@/components/modules/familyTreeProfile/utils';

import NameTypeSelect from './NameTypeSelect';
import PersonFactForm from './PersonFactForm';
import approximateDateField from './approximateDateField';

export default {
  props: {
    formInitialData: Object,
    allowOtherNames: {type: Boolean, default: false},
  },
  mounted() {
    // computed are not available in data;
    this.personFormDataFacts = this.initFactsFormData();
  },
  data() {
    const initData = this.initPersonFormData();
    return {
      personFormData: {...initData},
      personFormDataFacts: [],
      unsavedFields: [],
      unsavedFactsIds: [],
    };
  },
  computed: {
    ...mapGetters(['familyTreePersonOptionsState']),
    firstNameTypeOptions() {
      let options = [{key: '', value: 'First Name'}];
      for (let key in this.familyTreePersonOptionsState.first_name_types) {
        if (!this.allowOtherNames && key === 'other_name') {
          continue;
        }
        options.push({key, value: this.familyTreePersonOptionsState.first_name_types[key]});
      }
      return sortBy(options, 'key');
    },
    surnameTypeOptions() {
      let options = [{key: '', value: 'Surname'}];
      for (let key in this.familyTreePersonOptionsState.surname_types) {
        options.push({key, value: this.familyTreePersonOptionsState.surname_types[key]});
      }
      let order = ['', 'paper', 'maiden', 'married'];
      return sortBy(options, obj => order.indexOf(obj.key));
    },
    groupedFacts() {
      const factsGrouped = {};
      this.personFormDataFacts.forEach(fact => {
        if (fact['DELETE']) {
          return;
        }
        const key = fact.fact_type.id;
        factsGrouped[key] = factsGrouped[key] || [];
        factsGrouped[key].push(fact);
      });
      return sortBy(Object.keys(factsGrouped), key => factsGrouped[key][0].fact_type.priority).map(key => {
        const items = factsGrouped[key];
        const factType = items[0].fact_type;
        const label = factType.label;
        const sectionCategory = factType.category;
        return {key: `fact_${key}`, label: label, sectionCategory: sectionCategory, factType: factType, items: items};
      });
    },
  },
  methods: {
    initPersonFormData() {
      const formInitialData = {...this.formInitialData};
      let data = {
        object_id: formInitialData.object_id,
        family_tree_id: formInitialData.family_tree_id,
        birth_date: formInitialData.birth_date ? {...formInitialData.birth_date} : {},
        birth_location: formInitialData.birth_location ? {...formInitialData.birth_location} : null,
        death_date: formInitialData.death_date ? {...formInitialData.death_date} : {},
        death_location: formInitialData.death_location ? {...formInitialData.death_location} : null,
        first_names: formInitialData.first_names ? [...formInitialData.first_names] : [],
        gender: formInitialData.gender,
        hierarchy_level: formInitialData.hierarchy_level,
        is_deceased: formInitialData.is_deceased,
        surnames: formInitialData.surnames ? [...formInitialData.surnames] : [],
        notes: formInitialData.notes || '',
        generation_number: formInitialData.generation_number,
        residence_location: formInitialData.residence_location ? [...formInitialData.residence_location] : [],
      };

      data.is_deceased = [true, false, null].includes(formInitialData.is_deceased) ? formInitialData.is_deceased : null;
      data.generation_number = formInitialData.generation_number === 0 ? 0 : data.generation_number;
      data.hierarchy_level = formInitialData.hierarchy_level === 0 ? 0 : data.hierarchy_level;
      data.residence_location = data.residence_location.length
        ? data.residence_location
        : [{display_text: '', place_id: null, from_year: null, to_year: null}];
      return {
        ...data,
        id: data.object_id,
        ...this.splitNamesByLanguage(data.first_names, data.surnames),
      };
    },
    splitNamesByLanguage(firstNames, surnames) {
      let firstNamesEn = [];
      let firstNamesCn = [];
      let surnamesEn = [];
      let surnamesCn = [];

      for (let name of firstNames) {
        if (!this.allowOtherNames && name.type === 'other_name') {
          name.type = '';
        }
        isChineseText(name.value) ? firstNamesCn.push(name) : firstNamesEn.push(name);
      }
      for (let name of surnames) {
        isChineseText(name.value) ? surnamesCn.push(name) : surnamesEn.push(name);
      }
      this.addEmptyNameIfMissing(firstNamesEn);
      this.addEmptyNameIfMissing(firstNamesCn);
      this.addEmptyNameIfMissing(surnamesEn);
      this.addEmptyNameIfMissing(surnamesCn);
      return {firstNamesEn, firstNamesCn, surnamesEn, surnamesCn};
    },
    addEmptyNameIfMissing(names) {
      if (!names.length) {
        names.push({value: '', type: ''});
      }
    },
    initFactsFormData() {
      const facts = this.formInitialData.facts ? [...this.formInitialData.facts] : [];
      this.familyTreePersonOptionsState.fact_types
        .filter(type => {
          return type.is_profile_highlighted && !facts.some(fact => fact.fact_type.id === type.id);
        })
        .forEach(type => {
          facts.splice(facts.length, 1, this.getEmptyFact(type));
        });
      return sortBy(facts, fact => {
        return fact.start_date && fact.start_date.year ? fact.start_date.year : null;
      });
    },
    onNameValueChange(name, names, event, key) {
      this.addToUnsavedFields(key);
      const value = event && event.target ? event.target.value : event;
      names.splice(
        names.findIndex(n => this.compareObjects(n, name)),
        1,
        {...name, value: value}
      );
    },
    onNameTypeChange(name, names, type, key) {
      this.addToUnsavedFields(key);
      names.splice(
        names.findIndex(n => this.compareObjects(n, name)),
        1,
        {...name, type: type}
      );
    },
    addNewName(toList, fieldName) {
      toList.push({value: '', type: ''});
      this.addToUnsavedFields(fieldName);
    },
    addNewSurname(toList) {
      const shouldAddMarriedNameNext =
        toList.find(name => name.type === 'maiden') && !this.names.find(name => name.type === 'married');
      let type = shouldAddMarriedNameNext ? 'married' : '';
      toList.push({value: '', type: type});
      this.addToUnsavedFields('surnames');
    },
    deleteName(name, fromList, fieldName) {
      let index = fromList.indexOf(name);
      fromList.splice(index, 1);
      this.addToUnsavedFields(fieldName);
    },
    getChineseSurnameInitSearchValue(type) {
      const nameForSuggestion = this.personFormData.surnamesEn
        ? this.personFormData.surnamesEn.find(name => name.type === type)
        : null;
      const cnName = this.personFormData.surnamesCn.find(name => name.type === type);
      const nameExist = cnName ? cnName.value : false;
      return nameForSuggestion && !nameExist ? nameForSuggestion.value : null;
    },
    changeApproximateDate(field_name, data) {
      this.addToUnsavedFields(field_name);
      this.personFormData[field_name] = {...this.personFormData[field_name], ...data};
    },
    clearBirthLocation() {
      this.changeBirthLocation(null);
    },
    changeBirthLocation(location) {
      this.addToUnsavedFields('birth_location');
      this.personFormData.birth_location = location;
    },
    clearDeathLocation() {
      this.changeDeathLocation(null);
    },
    changeDeathLocation(location) {
      this.addToUnsavedFields('death_location');
      this.personFormData.death_location = location;
    },
    changeResidenceLocation(oldResidence, newData) {
      const index = this.personFormData.residence_location.findIndex(r => this.compareObjects(r, oldResidence));
      const newResidence = {...this.personFormData.residence_location[index], ...newData};
      this.personFormData.residence_location.splice(index, 1, newResidence);
      this.addToUnsavedFields('residence_location');
    },
    clearResidenceLocationDisplayText(residence) {
      this.changeResidenceLocation(residence, {display_text: '', place_id: '', place_historical_id: ''});
    },
    addResidence() {
      this.personFormData.residence_location.push({display_text: '', place_id: null, from_year: null, to_year: null});
    },
    deleteResidence(residence) {
      this.addToUnsavedFields('residence_location');
      let index = this.personFormData.residence_location.indexOf(residence);
      this.personFormData.residence_location.splice(index, 1);
    },
    changeFact(fact, fieldName, newValue) {
      let index = this.personFormDataFacts.indexOf(fact);
      const newFact = {...this.personFormDataFacts[index], [fieldName]: newValue};
      this.updateFactByIndex(index, newFact);
    },
    changeFactPlace(fact, place) {
      let index = this.personFormDataFacts.indexOf(fact);
      const newFact = {
        ...this.personFormDataFacts[index],
        place_id: place.place_id,
        place_display_text: place.display_text,
      };
      this.updateFactByIndex(index, newFact);
    },
    updateFactByIndex(index, newFact) {
      this.personFormDataFacts.splice(index, 1, newFact);
      if (!this.unsavedFactsIds.includes(newFact.id || newFact._id)) {
        this.unsavedFactsIds.push(newFact.id || newFact._id);
      }
      this.addToUnsavedFields(newFact.fact_type.id);
    },
    deleteFact(fact) {
      let index = this.personFormDataFacts.indexOf(fact);
      this.updateFactByIndex(index, {...fact, DELETE: true});
    },
    addFact(fact) {
      this.updateFactByIndex(this.personFormDataFacts.length, fact);
      this.$toasted.success(`${fact.fact_type.label} added!`);
    },
    addEmptyFact(type) {
      this.updateFactByIndex(this.personFormDataFacts.length, this.getEmptyFact(type));
    },
    getEmptyFact(type) {
      return {fact_type: type, _id: getRandomString(15), info: []};
    },
    compareObjects(obj1, obj2) {
      return Object.is(obj1, obj2);
    },
    addToUnsavedFields(key) {
      if (!this.unsavedFields.includes(key)) {
        this.unsavedFields.push(key);
      }
    },
    getCommonSectionId(id) {
      return getEditSectionScrollId(id, false);
    },
    getFactSectionId(factTypeId) {
      return getEditSectionScrollId(factTypeId, true);
    },
    scrollToSection(scrollId) {
      this.$scrollTo('#' + scrollId, {offset: this.scrollOffset});
    },
    scrollOffset() {
      const headerHeight = 61;
      const mainMenuHeight =
        this.$store.getters.windowWidthState >= this.$breakpoints.mainMenu
          ? consts.MAIN_MENU_HEIGHT
          : consts.MAIN_MENU_HEIGHT_MOBILE;
      return -(headerHeight + mainMenuHeight);
    },

    getModifiedPersonFactsForSave() {
      return this.personFormDataFacts
        .map(fact => {
          const {id, _id, fact_type, value, start_date, end_date, place_id, place_display_text, cemetery, info} = fact;
          if (!this.unsavedFactsIds.includes(id || _id)) {
            return;
          }
          if (fact['DELETE'] && !id) {
            return;
          }
          this.approximateDateValuesToInt(start_date);
          this.approximateDateValuesToInt(end_date);
          const infoDict = {};
          info.forEach(item => {
            infoDict[item.key] = item.value;
          });
          const res = {
            id,
            fact_type_id: fact_type.id,
            value,
            start_date,
            end_date,
            place_id,
            place_display_text,
            cemetery_id: cemetery ? cemetery.object_id : null,
            info: infoDict,
          };
          if (fact['DELETE']) {
            res['DELETE'] = true;
          }
          return res;
        })
        .filter(item => !!item);
    },
    getModifiedPersonForSave() {
      let {firstNamesEn, firstNamesCn, surnamesEn, surnamesCn, ...person} = this.personFormData;
      person.first_names = [...firstNamesEn.filter(name => name.value), ...firstNamesCn.filter(name => name.value)];
      person.surnames = [...surnamesEn.filter(name => name.value), ...surnamesCn.filter(name => name.value)];
      person.residence_location = [...person.residence_location.filter(item => item.display_text)];
      if (!person.is_deceased) {
        person.death_location = null;
        person.death_date = null;
      }
      person.gender = person.gender === 'null' ? null : person.gender;
      const genNum = parseInt(person.generation_number);
      person.generation_number = genNum || genNum === 0 ? genNum : null;
      this.approximateDateValuesToInt(person.birth_date);
      this.approximateDateValuesToInt(person.death_date);
      person.residence_location = person.residence_location.map(item => {
        return {...item, from_year: parseInt(item.from_year) || null, to_year: parseInt(item.to_year) || null};
      });
      return person;
    },
    approximateDateValuesToInt(date) {
      if (date) {
        ['day', 'month', 'year', 'approximation_years'].forEach(key => {
          date[key] = parseInt(date[key]) || null;
        });
      }
    },
  },
  components: {
    mcrWikiGlossaryLink,
    NameTypeSelect,
    SurnameAutocompleteSelect,
    FamilyTreeLocationMultiselectContainer,
    approximateDateField,
    PersonFactForm,
    DeleteIcon,
    PlusIcon,
  },
  name: 'PersonFullEditForm',
};
</script>

<style lang="scss" scoped>
.person-full-edit-form {
  &::v-deep .section {
    display: flex;
    flex-direction: column;
    row-gap: 12px;
    padding: 16px 24px;

    .content-item {
      display: flex;
      position: relative;

      .label {
        font-weight: 800;
        color: $neutral-500;
        width: 200px;
        flex-shrink: 0;
      }

      .values {
        display: flex;
        flex-direction: column;
        row-gap: 8px;
        flex-grow: 1;
        min-width: 0;
      }
    }
    .chip {
      align-self: flex-start;
    }

    .sub-forms {
      display: flex;
      flex-direction: column;
      row-gap: 24px;
    }

    .sub-form {
      border-radius: 4px;
      border: 1px solid $neutral-200;
      padding: 20px;
      display: flex;
      flex-direction: column;
      row-gap: 20px;

      .inline {
        display: flex;
        column-gap: 16px;

        > input,
        .chinese-surname-select {
          width: 50%;
        }

        .name-select {
          display: flex;
          flex-grow: 1;
        }
      }

      .delete {
        display: flex;
        align-items: center;
        color: $neutral-500;
        font-weight: 800;
        column-gap: 4px;
        cursor: pointer;
        align-self: flex-start;
        .material-design-icon {
          margin-top: -1px;
        }
      }

      &.removable {
        padding: 20px 20px 16px;
      }
      &:not(.removable) {
        .delete {
          display: none;
        }
      }
    }

    .add-name {
      display: flex;
      align-items: center;
      column-gap: 4px;
      align-self: flex-start;
    }
    .sub-forms + .add-name {
      margin-top: 8px;
    }
    .add-name + .chip {
      margin-top: 16px;
    }

    textarea {
      resize: none;
      height: 8em;
    }

    .section-category {
      position: absolute;
      top: -36px;
      left: -5px;
      background: $background-light;
      padding: 5px;
      color: $neutral-400;

      @media only screen and (max-width: $breakpoint-phablet) {
        top: -33px;
      }
    }
  }
  .section:not(:first-of-type) {
    padding-top: 20px;
    border-top: 1px solid $neutral-200;
  }
  .section + .section.fact-section {
    margin-top: 12px;
  }

  .section.fact-section .content-item {
    .values {
      flex-grow: 1;
      row-gap: 16px;
      .value:not(:last-of-type) {
        padding-bottom: 16px;
        border-bottom: 1px solid $neutral-200;
      }
    }
  }

  @media only screen and (max-width: $breakpoint-phablet) {
    .section:not(:first-of-type) {
      padding-top: 16px;
    }
  }
  @media only screen and (max-width: $main-menu-breakpoint) {
    &::v-deep .section {
      .content-item {
        flex-direction: column;
        row-gap: 12px;
      }
    }
    .sub-forms {
      row-gap: 8px;
    }
    .sub-form {
      padding: 16px;
      .inline.column-mobile {
        flex-direction: column;
        row-gap: 16px;
        input,
        .chinese-surname-select {
          width: 100%;
        }
      }
    }
  }
}
</style>

<template>
  <div class="family-tree-shortcuts-button" @click="openShortcutsModal" v-tooltip="tooltipData">
    <lightning-bolt-icon title="" :size="24"></lightning-bolt-icon>
    <span class="text-sm" v-if="showLabel">Shortcuts</span>
  </div>
</template>

<script>
import AnalyticsMainHandler from '@common/utils/analytics/analytics.main';
import LightningBoltIcon from 'vue-material-design-icons/LightningBolt';

import {TREE_SHORTCUTS_MODAL_KEY} from '@/components/modules/familyTree/constants';
import ShortcutsModalContent from '@/components/modules/familyTree/tree/modals/ShortcutsModalContent.vue';

export default {
  data() {
    return {
      showTooltip: false,
    };
  },
  computed: {
    tooltipData() {
      if (this.showTooltip) {
        return {
          content: 'Find shortcuts here anytime',
          popperClass: 'text-center in-background',
          placement: 'right',
          triggers: [],
          shown: this.showTooltip,
        };
      }
      return this.showLabel ? {} : {content: 'Shortcuts', placement: 'bottom'};
    },
    showLabel() {
      return !this.$store.getters.reviewedItemsState.includes(TREE_SHORTCUTS_MODAL_KEY);
    },
  },
  methods: {
    openShortcutsModal() {
      const modalParams = {
        classes: 'clear_modal white_modal',
        name: 'shortcutsmodal',
        height: 'auto',
        scrollable: true,
      };
      const modalEvents = {
        closed: () => {
          if (!this.$store.getters.reviewedItemsState.includes(TREE_SHORTCUTS_MODAL_KEY)) {
            this.showTooltip = true;
            this.$store.commit('addReviewedItemState', TREE_SHORTCUTS_MODAL_KEY);
            setTimeout(() => {
              this.showTooltip = false;
            }, 3000);
          }
        },
      };
      this.$modal.show(ShortcutsModalContent, {}, modalParams, modalEvents);
      AnalyticsMainHandler.trackTreeOpenShortcutsModalEvent();
    },
  },
  components: {LightningBoltIcon},
};
</script>

<style scoped lang="scss">
.family-tree-shortcuts-button {
  .text-sm {
    padding: 0 4px;
  }
}
</style>

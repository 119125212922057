<template>
  <div class="delete-confirm-modal-content alert-modal-content">
    <slot></slot>
    <div class="buttons">
      <mcr-button class="white" :disabled="loading" @click="cancelClick">Cancel</mcr-button>
      <mcr-button class="proceed" :loading="loading" @click="okClick">Proceed</mcr-button>
    </div>
  </div>
</template>

<script>
import McrButton from '@common/elements/buttons/mcrButton';
import {keyEnter} from '@common/utils/consts.keycodes';

export default {
  components: {McrButton},
  props: {
    loading: Boolean,
    modalName: {type: String, required: false},
  },
  created() {
    document.addEventListener('keydown', this.onKeyDown);
  },
  destroyed() {
    document.removeEventListener('keydown', this.onKeyDown);
  },
  methods: {
    cancelClick() {
      if (this.modalName) {
        this.$modal.hide(this.modalName);
      }
      this.$emit('close');
    },
    okClick() {
      this.$emit('confirm');
    },
    onKeyDown(e) {
      if (e.which === keyEnter) {
        return this.okClick();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.delete-confirm-modal-content {
  h5 span {
    color: $active-color;
  }
}
</style>

<template>
  <div class="">
    <date-location-display
      v-if="hasDataToDisplay"
      :class="{'padding-bottom': note}"
      :date="date"
      :date2="date2"
      :location="location"
    >
    </date-location-display>
    <div v-if="note" class="note" v-html="formattedNote"></div>
  </div>
</template>

<script>
import DateLocationDisplay from '@/components/common/tree/dateLocationDisplay.vue';

export default {
  components: {DateLocationDisplay},
  props: {
    date: Object,
    date2: Object,
    location: Object,
    note: String,
  },
  computed: {
    formattedNote() {
      return this.note ? this.note.replace(/\n/g, '<br/>') : null;
    },
    hasDataToDisplay() {
      return this.date || this.date2 || this.location.display_text || this.location.place_id;
    },
  },
};
</script>

<style lang="scss" scoped>
.padding-bottom {
  padding-bottom: 8px;
}
</style>

<template>
  <div class="edit-spouse-meta-modal-content with-sticky-buttons">
    <div class="heading">
      <div class="text-lg bold">Edit Relationship with {{ name }}</div>
      <close-icon class="close-icon" @click="closeModal"></close-icon>
    </div>
    <div class="content">
      <div class="children-select">
        <div class="label">Include the following individuals as children:</div>
        <bounce-loading class="dark" v-if="childrenLoading"></bounce-loading>
        <multiple-choice-select
          v-else
          class="text-sm"
          :options="possibleChildrenList"
          :checked="selectedPossibleChildrenIds"
          @change="childrenIdsChange"
        ></multiple-choice-select>
      </div>
      <marriage-details-block
        :initial-data="initialData"
        :family-tree-id="familyTreeId"
        ref="form"
      ></marriage-details-block>
    </div>
    <div class="action-buttons right-align">
      <mcr-button class="white" :disabled="loading" @click="closeModal">Cancel</mcr-button>
      <mcr-button class="save" :loading="loading" @click="onSave">Save Changes</mcr-button>
    </div>
  </div>
</template>

<script>
import McrButton from '@common/elements/buttons/mcrButton';
import BounceLoading from '@common/elements/loading-indicators/bounceLoading.vue';
import uniqBy from 'lodash/uniqBy';
import CloseIcon from 'vue-material-design-icons/Close';
import {mapGetters} from 'vuex';

import MultipleChoiceSelect from '@/base/elements/filters/multipleChoiceSelect';

import {UNKNOWN_NAME} from '@/components/modules/familyTree/constants';
import MarriageDetailsBlock from '@/components/modules/familyTree/tree/modals/fields/MarriageDetailsBlock';

export default {
  props: {
    name: String,
    initialData: Object,
    familyTreeId: [String, Number],
    coupleId: Number,
    personId: Number,
    spouseId: Number,
  },
  data() {
    return {
      childrenLoading: false,
      possibleChildrenList: [],
      selectedPossibleChildrenIds: [],
      childrenIds: [],
    };
  },
  created() {
    this.childrenLoading = true;
    const r1 = this.$store.dispatch('fetchFamilyTreePersonRelativesInBackgroundAction', this.personId);
    const r2 = this.$store.dispatch('fetchFamilyTreePersonRelativesInBackgroundAction', this.spouseId);
    Promise.all([r1, r2]).then(responses => {
      const r1 = this.familyRelativesByPersonIdState[this.couplePersonIds[0]] || {children: []};
      const r2 = this.familyRelativesByPersonIdState[this.couplePersonIds[1]] || {children: []};
      const childData = [...r1.children, ...r2.children].map(child => ({
        id: child.object_id,
        text: child.full_name || UNKNOWN_NAME,
        ...child,
      }));
      this.possibleChildrenList = uniqBy(childData, 'id');
      this.selectedPossibleChildrenIds = this.possibleChildrenList
        .filter(child => this.couplePersonIds.every(id => child.parents_ids.includes(id)))
        .map(child => child.id);
      this.childrenIds = [...this.selectedPossibleChildrenIds];
      this.childrenLoading = false;
    });
  },
  computed: {
    ...mapGetters(['familyRelativesByPersonIdState']),
    loading() {
      return this.$store.getters.coupleUpdateLoadingState;
    },
    couplePersonIds() {
      return [this.personId, this.spouseId];
    },
  },
  methods: {
    closeModal() {
      this.$emit('close');
    },
    childrenIdsChange(data) {
      this.childrenIds = data;
    },
    onSave() {
      const added = this.childrenIds.filter(id => !this.selectedPossibleChildrenIds.includes(id));
      const removed = this.selectedPossibleChildrenIds.filter(id => !this.childrenIds.includes(id));
      let addedPromise = added.length
        ? this.$store.dispatch('createParenthoodAction', {parent_ids: this.couplePersonIds, child_ids: added})
        : Promise.resolve();
      let removedPromise = removed.length
        ? this.network.familyTree.deleteParentRelationship({id: this.familyTreeId, children_ids: removed})
        : Promise.resolve();

      const data = this.$refs.form.getDataForSave();
      let updatePromise = this.$store.dispatch('updateCoupleAction', {id: this.coupleId, ...data});
      Promise.all([addedPromise, removedPromise, updatePromise]).then(() => {
        this.$toasted.success('Relationship updated.');
        this.$store.commit('mutateMetaOfPersonRelativesState', {couple_id: this.coupleId, ...data});
        this.$store.commit('mutateFamilyTreeLinesDrawnState', {
          relatedPeople: this.couplePersonIds,
          newData: {is_dashed: data.is_divorced},
        });
        this.$store.commit('clearFamilyTreePersonRelativesState', [...added, ...removed, this.spouseId]);
        this.$store.dispatch('fetchFamilyTreePersonRelativesAction', this.personId);
        if (added.length || removed.length) {
          this.$store.commit('setFamilyTreePersonsDrawnState', []);
          this.$store.commit('setFamilyTreeLinesDrawnState', []);
        }
        this.closeModal();
      });
    },
  },
  components: {BounceLoading, MultipleChoiceSelect, CloseIcon, MarriageDetailsBlock, McrButton},
  name: 'editSpouseMetaModalContent',
};
</script>

<style lang="scss" scoped>
.edit-spouse-meta-modal-content {
  .children-select {
    margin-bottom: 20px;
    padding-bottom: 20px;
    border-bottom: 1px solid $neutral-200;
    .label {
      font-weight: 800;
      color: $neutral-500;
      margin-bottom: 5px;
    }
  }
  .save {
    min-width: 128px;
  }
  @media only screen and (min-width: $breakpoint-mobile) {
    width: 720px;
  }
}
</style>

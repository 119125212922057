<template>
  <div class="change-parents-modal-content with-sticky-buttons">
    <div class="heading">
      <div class="text-lg bold">Change parent of {{ personName }}</div>
      <close-icon class="close-icon" @click="closeModal"></close-icon>
    </div>
    <div class="content">
      <family-tree-people-multiselect-container
        ref="people-select"
        class="bordered"
        :value="selectedPerson"
        :family-tree-id="treeId"
        :multiple="false"
        :allow-clear="false"
        @select="selectPerson"
      ></family-tree-people-multiselect-container>
      <mcr-loading-indicator :loading="selectPersonLoading"></mcr-loading-indicator>

      <div v-if="possibleParentsList.length && !selectPersonLoading" class="parents-block">
        <radio-select
          v-if="possibleParentsList.length"
          :options="possibleParentsList"
          :selected="selectedParentsIds"
          name="parents-radio"
          class="text-sm"
          @change="parentsIdsChange"
        ></radio-select>
      </div>
    </div>

    <div class="action-buttons right-align">
      <mcr-button class="white bold" :disabled="formLoading" @click="closeModal">Cancel</mcr-button>
      <mcr-button class="save bold" :loading="formLoading" @click="saveClick">Save</mcr-button>
    </div>
  </div>
</template>

<script>
import mcrButton from '@common/elements/buttons/mcrButton.vue';
import RadioSelect from '@common/elements/filters/radioSelect.vue';
import CloseIcon from 'vue-material-design-icons/Close';

import FamilyTreePeopleMultiselectContainer from '@/components/common/inputs/FamilyTreePeopleMultiselectContainer.vue';

import {CHILD_RELATION_TYPE, PARENT_RELATION_TYPE, UNKNOWN_NAME} from '@/components/modules/familyTree/constants';
import {createRelativeHandler, getPossibleParentsList} from '@/components/modules/familyTree/tree/services';

export default {
  props: {
    person: Object,
    parent: Object,
    treeId: Number,
  },
  data() {
    return {
      selectedPerson: null,
      selectPersonLoading: false,
      formLoading: false,
      possibleParentsList: [],
      selectedParentsIds: [],
    };
  },
  created() {
    this.selectPerson(this.parent);
  },
  computed: {
    personName() {
      return this.person.full_name || UNKNOWN_NAME;
    },
  },
  methods: {
    selectPerson(person) {
      this.selectPersonLoading = true;
      this.selectedPerson = person;
      this.$store
        .dispatch('fetchFamilyTreePersonRelativesInBackgroundAction', person.object_id)
        .then(() => {
          this.possibleParentsList = getPossibleParentsList(CHILD_RELATION_TYPE, person, this);
          if (!this.possibleParentsList.length) {
            this.possibleParentsList = [
              {id: [person.object_id], text: `${person.full_name || UNKNOWN_NAME} and Other Parent`, selected: true},
            ];
          }
          let parentsIds = this.possibleParentsList.find(option => option.selected);
          this.selectedParentsIds = parentsIds ? parentsIds.id : [];
        })
        .finally(() => {
          this.selectPersonLoading = false;
        });
    },
    closeModal() {
      this.$emit('close');
    },
    parentsIdsChange(ids) {
      this.selectedParentsIds = ids;
    },
    async saveClick() {
      this.formLoading = true;
      await createRelativeHandler(
        this.selectedPerson,
        PARENT_RELATION_TYPE,
        this.person,
        null,
        [],
        this.selectedParentsIds,
        false,
        false,
        this
      );
      this.$toasted.success('Parents updated');
      this.$store.dispatch('fetchFamilyTreePersonRelativesAction', this.person.object_id);
      this.closeModal();
    },
  },
  components: {RadioSelect, mcrButton, FamilyTreePeopleMultiselectContainer, CloseIcon},
  name: 'ChangeParentsModalContent',
};
</script>

<style scoped lang="scss">
.change-parents-modal-content {
  .parents-block {
    margin-top: 16px;
  }
  @media only screen and (min-width: $breakpoint-phablet) {
    min-width: 720px;
    width: 720px;
  }
}
</style>

<template>
  <div class="family-tree-collapse-button" v-tooltip="tooltip" @click="collapseAllTreeBranches">
    <collapse-all-icon class="item" :size="24" title=""></collapse-all-icon>
  </div>
</template>

<script>
import CollapseAllIcon from 'vue-material-design-icons/CollapseAll';

export default {
  props: {
    treeId: Number,
    startPersonId: Number,
  },
  computed: {
    tooltip() {
      return {content: 'Reset to default view', placement: 'left'};
    },
  },
  methods: {
    collapseAllTreeBranches() {
      this.$store
        .dispatch('fetchFamilyTreeAction', {
          id: this.treeId,
          start_person_id: this.startPersonId,
          control_data: [{action: 'reset'}],
        })
        .then(() => {
          this.$store.commit('setFamilyTreeRefreshRequestState', true);
        });
    },
  },
  components: {CollapseAllIcon},
  name: 'familyTreeCollapseButton',
};
</script>

<style scoped lang="scss"></style>

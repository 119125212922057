<template>
  <v-dropdown :shown.sync="isPopoverOpen" :popper-class="popoverClass" :autoHide="showTriggerElement" @hide="onClose">
    <mcr-button class="white small" v-if="showTriggerElement">
      <dots-vertical-icon class="my-icon" :size="20" title="Context menu"></dots-vertical-icon>
    </mcr-button>
    <div slot="popper" class="popover-content">
      <div class="overlay" @click="hidePopover" @contextmenu.prevent.stop></div>
      <slot name="header"></slot>
      <popover-action-button v-if="showOpenFullProfile" label="Full Profile" @click.native="fullProfileClick">
        <account-icon class="my-icon" :size="iconSize" title="Edit"></account-icon>
      </popover-action-button>
      <popover-action-button v-if="editable" label="Quick Edit" :help-text="editHelpText" @click.native="editClick">
        <pencil-icon class="my-icon" :size="iconSize" title="Quick Edit"></pencil-icon>
      </popover-action-button>
      <popover-action-button v-if="editableFull" :label="fullEditLabel" @click.native="fullProfileEditClick">
        <account-edit-icon class="my-icon" :size="iconSize" :title="fullEditLabel"></account-edit-icon>
      </popover-action-button>
      <popover-action-button
        v-if="showAddRelative"
        label="Add Relative"
        :class="{active: showAddRelativeSubmenu}"
        @click.native="addRelativeClick"
      >
        <plus-icon class="my-icon" :size="iconSize" title="Add Relative"></plus-icon>
      </popover-action-button>
      <div class="person-details-add-relative-popover" v-if="showAddRelativeSubmenu">
        <div v-if="canAddParent" class="option" @click="addParentClick">Parent</div>
        <div class="option" @click="addSpouseClick">Spouse</div>
        <div class="option" @click="addSiblingClick">Sibling</div>
        <div class="option" @click="addChildClick">Child</div>
      </div>
      <popover-action-button v-if="showViewTree" label="View Tree" @click.native="viewTreeClick">
        <eye-icon class="my-icon" :size="iconSize" title="View Tree"></eye-icon>
      </popover-action-button>
      <popover-action-button v-if="showViewLineage" label="View Lineage" @click.native="viewLineageClick">
        <lineage-icon class="my-icon" :size="iconSize" title="View Lineage"></lineage-icon>
      </popover-action-button>
      <popover-action-button v-if="removable" label="Delete" @click.native="deleteClick">
        <delete-icon class="my-icon" :size="iconSize" title="Delete"></delete-icon>
      </popover-action-button>
      <popover-action-button v-if="isMobile" class="big" label="Cancel" @click.native="hidePopover">
        <close-icon class="my-icon" :size="iconSize" title="Cancel"></close-icon>
      </popover-action-button>
    </div>
  </v-dropdown>
</template>

<script>
import McrButton from '@common/elements/buttons/mcrButton';
import AccountIcon from 'vue-material-design-icons/Account';
import AccountEditIcon from 'vue-material-design-icons/AccountEdit';
import CloseIcon from 'vue-material-design-icons/Close';
import DeleteIcon from 'vue-material-design-icons/Delete';
import DotsVerticalIcon from 'vue-material-design-icons/DotsVertical';
import LineageIcon from 'vue-material-design-icons/FormatListBulletedSquare';
import PencilIcon from 'vue-material-design-icons/Pencil';
import PlusIcon from 'vue-material-design-icons/Plus';
import EyeIcon from 'vue-material-design-icons/Sitemap';

import PopoverActionButton from '@/components/common/modals/popoverActionButton';

import {CONTEXT_MENU_SECTIONS} from '@/components/modules/familyTree/constants';

const SECTIONS = Object.values(CONTEXT_MENU_SECTIONS);

export default {
  props: {
    sections: {
      type: Array,
      validator: value => value.every(item => SECTIONS.includes(item)),
      default: () => SECTIONS,
    },
    canAddParent: Boolean,
    showTriggerElement: {type: Boolean, default: true},
    fullEditLabel: {type: String, default: 'Full Edit'},
  },
  data() {
    return {
      iconSize: 28,
      showAddRelativeSubmenu: false,
      isPopoverOpen: false,
    };
  },
  computed: {
    isMobile() {
      return this.$store.getters.windowWidthState <= this.$breakpoints.mobile;
    },
    showOpenFullProfile() {
      return this.sections.includes(CONTEXT_MENU_SECTIONS.FULL_PROFILE);
    },
    removable() {
      return this.sections.includes(CONTEXT_MENU_SECTIONS.DELETE);
    },
    editable() {
      return this.sections.includes(CONTEXT_MENU_SECTIONS.EDIT);
    },
    editableFull() {
      return this.sections.includes(CONTEXT_MENU_SECTIONS.FULL_PROFILE_EDIT);
    },
    showAddRelative() {
      return this.sections.includes(CONTEXT_MENU_SECTIONS.ADD_RELATIVE);
    },
    showViewTree() {
      return this.sections.includes(CONTEXT_MENU_SECTIONS.VIEW_TREE);
    },
    showViewLineage() {
      return this.sections.includes(CONTEXT_MENU_SECTIONS.VIEW_LINEAGE);
    },
    popoverClass() {
      return this.isMobile ? 'mobile-context' : '';
    },
    editHelpText() {
      return '';
    },
  },
  methods: {
    fullProfileClick() {
      this.hidePopover();
      this.$emit('click-full-profile');
    },
    fullProfileEditClick() {
      this.hidePopover();
      this.$emit('click-full-profile-edit');
    },
    editClick() {
      this.hidePopover();
      this.$emit('click-edit');
    },
    viewTreeClick() {
      this.$emit('click-view-tree');
    },
    viewLineageClick() {
      this.$emit('click-view-lineage');
    },
    deleteClick() {
      this.hidePopover();
      this.$emit('click-delete');
    },
    addRelativeClick() {
      this.showAddRelativeSubmenu = !this.showAddRelativeSubmenu;
    },
    addParentClick() {
      this.hidePopover();
      this.$emit('click-add-parent');
    },
    addSpouseClick() {
      this.hidePopover();
      this.$emit('click-add-spouse');
    },
    addSiblingClick() {
      this.hidePopover();
      this.$emit('click-add-sibling');
    },
    addChildClick() {
      this.hidePopover();
      this.$emit('click-add-child');
    },
    hidePopover() {
      this.isPopoverOpen = false;
    },
    onClose() {
      this.showAddRelativeSubmenu = false;
    },
  },
  components: {
    PopoverActionButton,
    DotsVerticalIcon,
    AccountIcon,
    AccountEditIcon,
    PencilIcon,
    EyeIcon,
    LineageIcon,
    DeleteIcon,
    PlusIcon,
    CloseIcon,
    McrButton,
  },
  name: 'ContextMenuPopover',
};
</script>

<style lang="scss" scoped>
.context-menu-popover {
  .mcr-button {
    min-width: 0;
  }
}
.mobile-context {
  .person-details-add-relative-popover {
    border-top: 1px solid $divider-line-color;
    background: $off-white;
  }

  // fix mobile longpress on card hovers context menu and highlights it on initial open
  .popover-action-button:not(.active):hover {
    background: $off-white;
    color: $text-color;
  }
}
</style>

<template>
  <div class="person-details-add-relative-popover">
    <div v-if="canAddParents" class="option" @click="addParentClick">Parent</div>
    <div class="option" @click="addSpouseClick">Spouse</div>
    <div class="option" @click="addSiblingClick">Sibling</div>
    <div class="option" @click="addChildClick">Child</div>
  </div>
</template>

<script>
export default {
  props: {
    canAddParents: Boolean,
  },
  methods: {
    addParentClick() {
      this.$emit('click-add-parent');
    },
    addSpouseClick() {
      this.$emit('click-add-spouse');
    },
    addSiblingClick() {
      this.$emit('click-add-sibling');
    },
    addChildClick() {
      this.$emit('click-add-child');
    },
  },
  name: 'personDetailsAddRelativePopover',
};
</script>

<style lang="scss" scoped>
.person-details-add-relative-popover {
  align-items: left;
  display: flex;
  flex-direction: column;
}
</style>

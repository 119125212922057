<template>
  <div class="shortcuts-modal-content">
    <close-button @click="$emit('close')"></close-button>
    <div class="content">
      <div class="tree-controls">
        <h6>Tree Controls</h6>
        <div class="controls-list">
          <div class="control-item">
            <div class="action">Pan Tree</div>
            <div class="shortcut">Click & Drag</div>
          </div>
          <div class="control-item">
            <div class="action">Zoom Tree</div>
            <div class="shortcut">Mouse Wheel</div>
          </div>
          <div class="control-item">
            <div class="action">Quick View</div>
            <div class="shortcut">Click Card</div>
          </div>
          <div class="control-item">
            <div class="action">Quick Edit</div>
            <div class="shortcut">Double Click Card</div>
          </div>
          <div class="control-item">
            <div class="action">Quick Actions</div>
            <div class="shortcut">Right Click Card</div>
          </div>
          <div class="control-item">
            <div class="action">Open Full Profile</div>
            <div class="shortcut">
              <key-cup :auto-hide="false" class="dark" :size="14">Alt / ⌥</key-cup> + Click Card
            </div>
          </div>
          <div class="control-item">
            <div class="action">Full Profile in New Tab</div>
            <div class="shortcut">
              <key-cup :auto-hide="false" class="dark" :size="14">Ctrl / ⌘</key-cup> + Click Card
            </div>
          </div>
          <div class="control-item">
            <div class="action">Search Tree</div>
            <div class="shortcut">
              <key-cup :auto-hide="false" class="dark" :size="14">Ctrl / ⌘</key-cup> +
              <key-cup :auto-hide="false" class="dark" :size="14">F</key-cup>
            </div>
          </div>
          <div class="control-item">
            <div class="action">Highlight Family Group</div>
            <div class="shortcut">Click Relationship Line</div>
          </div>
          <div class="control-item">
            <div class="action">Close Side Panel</div>
            <div class="shortcut"><key-cup :auto-hide="false" class="dark" :size="14">ESC</key-cup></div>
          </div>
        </div>
      </div>
      <div class="selection-controls">
        <h6>Selection Controls</h6>
        <div class="controls-list">
          <div class="control-item">
            <div class="action">Select Person</div>
            <div class="shortcut">Click Card</div>
          </div>
          <div class="control-item">
            <div class="action">View Full Profile</div>
            <div class="shortcut"><key-cup :auto-hide="false" class="dark" :size="14">P</key-cup></div>
          </div>
          <div class="control-item">
            <div class="action">Quick Edit</div>
            <div class="shortcut"><key-cup :auto-hide="false" class="dark" :size="14">E</key-cup></div>
          </div>
          <div class="control-item">
            <div class="action">Full Edit</div>
            <div class="shortcut"><key-cup :auto-hide="false" class="dark" :size="14">F</key-cup></div>
          </div>
          <div class="control-item">
            <div class="action">Add Relative</div>
            <div class="shortcut"><key-cup :auto-hide="false" class="dark" :size="14">R</key-cup></div>
          </div>
          <div class="control-item indented">
            <div class="action">Add Parent</div>
            <div class="shortcut"><key-cup :auto-hide="false" class="dark" :size="14">1</key-cup></div>
          </div>
          <div class="control-item indented">
            <div class="action">Add Spouse</div>
            <div class="shortcut"><key-cup :auto-hide="false" class="dark" :size="14">2</key-cup></div>
          </div>
          <div class="control-item indented">
            <div class="action">Add Sibling</div>
            <div class="shortcut"><key-cup :auto-hide="false" class="dark" :size="14">3</key-cup></div>
          </div>
          <div class="control-item indented">
            <div class="action">Add Child</div>
            <div class="shortcut"><key-cup :auto-hide="false" class="dark" :size="14">4</key-cup></div>
          </div>
          <div class="control-item">
            <div class="action">View Tree</div>
            <div class="shortcut"><key-cup :auto-hide="false" class="dark" :size="14">T</key-cup></div>
          </div>
          <div class="control-item">
            <div class="action">View Lineage</div>
            <div class="shortcut"><key-cup :auto-hide="false" class="dark" :size="14">L</key-cup></div>
          </div>
          <div class="control-item">
            <div class="action">Delete</div>
            <div class="shortcut"><key-cup :auto-hide="false" class="dark" :size="14">DEL</key-cup></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CloseButton from '@common/elements/buttons/closeButton.vue';
import KeyCup from '@common/elements/icons/KeyCup.vue';

export default {
  name: 'ShortcutsModalContent',
  components: {KeyCup, CloseButton},
};
</script>

<style scoped lang="scss">
.shortcuts-modal-content {
  width: 800px;
  .content {
    display: flex;
    column-gap: 50px;
    justify-content: space-between;

    > * {
      flex: 1;
    }
  }

  .controls-list {
    display: flex;
    flex-direction: column;
    row-gap: 20px;
    margin-top: 20px;

    .control-item {
      min-height: 25px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      column-gap: 48px;

      .action {
        color: $neutral-500;
      }

      .shortcut {
        text-align: right;
      }

      &.indented {
        padding-left: 24px;
      }
    }
  }
  .controls-list + h6 {
    margin-top: 24px;
  }

  @media only screen and (max-width: 800px) {
    width: auto;
  }
}
</style>

<template>
  <div class="radio-select">
    <div v-for="option in options" :key="option.id.toString()" class="radio-option">
      <input :id="getId(option)" type="radio" :name="name" :value="option.id" v-model="model" />
      <label :for="getId(option)">{{ option.text }}</label>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    selected: null, // any type
    name: {type: String, default: 'multiple-choice-select'},
    options: Array,
  },
  watch: {
    model(newValue) {
      this.$emit('change', newValue);
    },
    selected(newValue) {
      if (this.model !== newValue) {
        this.model = newValue;
      }
    },
  },
  data() {
    return {
      model: this.selected,
    };
  },
  methods: {
    getId(option) {
      return `${this.name}-${option.id}`;
    },
  },
};
</script>

<style lang="scss" scoped>
.radio-select {
  display: flex;
  flex-direction: column;
  row-gap: 12px;
  .radio-option {
    display: flex;
    align-items: center;
    column-gap: 8px;
  }
  .radio-option label,
  .radio-option input {
    cursor: pointer;
  }
  input[type='radio'] {
    margin: 0;
  }
}
</style>

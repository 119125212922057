<template>
  <div class="fact-types-modal-content">
    <div class="flex-column">
      <input v-model="search" class="input" ref="input" />
    </div>
    <div class="flex-column fact-types">
      <div class="fact-type" v-for="type in filteredTypes" @click="$emit('select', type)">
        <div>{{ type.label }}</div>
        <div class="category text-sm">{{ type.category }}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    types: Array,
  },
  data() {
    return {
      search: '',
    };
  },
  mounted() {
    this.$refs.input.focus();
  },
  computed: {
    searchLowerCase() {
      return this.search.toLowerCase();
    },
    filteredTypes() {
      return this.types.filter(type => type.label.toLowerCase().includes(this.searchLowerCase));
    },
  },
  name: 'FactTypesModalContent',
};
</script>

<style lang="scss" scoped>
.fact-types-modal-content {
  .flex-row {
    display: flex;
    flex-direction: column;

    input {
      flex-grow: 1;
    }
  }
  .flex-column + .flex-column {
    margin-top: 20px;
  }

  .fact-types {
    overflow: auto;
  }

  .fact-type {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 13px 16px;
    cursor: pointer;
    border-radius: 4px;
    .category {
      color: $neutral-400;
    }

    &:hover {
      background: $primary-400;
      color: white;
      .category {
        color: white;
      }
    }
  }

  @media only screen and (min-width: $main-menu-breakpoint) {
    width: 560px;
    .fact-types {
      max-height: 400px;
    }
  }
}
</style>

<template>
  <div class="person-delete-confirm alert-modal-content">
    <h5>{{ error }}</h5>
    <div class="buttons">
      <mcr-button @click="okClick">OK</mcr-button>
    </div>
  </div>
</template>

<script>
import McrButton from '@common/elements/buttons/mcrButton';
import {keyEnter} from '@common/utils/consts.keycodes';

export default {
  components: {McrButton},
  props: {
    error: String,
  },
  created() {
    document.addEventListener('keydown', this.onKeyDown);
  },
  destroyed() {
    document.removeEventListener('keydown', this.onKeyDown);
  },
  methods: {
    okClick() {
      this.$emit('close');
    },
    onKeyDown(e) {
      if (e.which === keyEnter) {
        return this.okClick();
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>

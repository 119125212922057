<template>
  <div class="fact-type-add-modal-content with-sticky-buttons">
    <div class="heading">
      <div class="text-lg bold">Add {{ factType.label }}</div>
      <div class="close-btn" @click="$modal.hide(modalName)"></div>
    </div>
    <div class="content">
      <person-fact-form
        :fact="fact"
        :options="options"
        :family-tree-id="treeId"
        @change-value="changeFact('value', $event)"
        @change-place="changeFactPlace($event)"
        @change-start-date="changeFact('start_date', $event)"
        @change-end-date="changeFact('end_date', $event)"
        @change-cemetery="changeFact('cemetery', $event)"
        @change-info="changeFact('info', $event)"
      ></person-fact-form>
    </div>
    <div class="action-buttons">
      <mcr-button class="white" @click="onGoBack">Go Back</mcr-button>
      <mcr-button class="save" @click="onSave">Save Details</mcr-button>
    </div>
  </div>
</template>

<script>
import McrButton from '@common/elements/buttons/mcrButton';
import {getRandomString} from '@common/utils/utils';

import PersonFactForm from '@/components/common/tree/PersonFactForm';

export default {
  props: {
    factType: Object,
    options: Array,
    treeId: [Number, String],
    modalName: String,
  },
  data() {
    return {
      fact: {fact_type: this.factType, _id: getRandomString(15), info: []},
    };
  },
  methods: {
    onGoBack() {
      this.$modal.hide(this.modalName);
      this.$emit('go-back');
    },
    changeFact(key, value) {
      this.$set(this.fact, key, value);
    },
    changeFactPlace(place) {
      this.changeFact('place_id', place.place_id);
      this.changeFact('place_display_text', place.display_text);
    },
    onSave() {
      this.$modal.hide(this.modalName);
      this.$emit('save', this.fact);
    },
  },
  components: {McrButton, PersonFactForm},
  name: 'FactTypeAddModalContent',
};
</script>

<style lang="scss" scoped>
.fact-type-add-modal-content {
  .action-buttons {
    display: flex;
    justify-content: space-between;

    .save {
      min-width: 113px;
    }
  }
  @media only screen and (min-width: $main-menu-breakpoint) {
    width: 560px;
    padding-bottom: 0;
  }
}
</style>

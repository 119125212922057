<template>
  <div class="family-tree-mobile-controls-button" @click="openModal">
    <dots-icon :size="22" title="" />
  </div>
</template>

<script>
import DotsIcon from 'vue-material-design-icons/DotsHorizontalCircleOutline';

import MobileActionsModal from '@/components/modules/familyTree/tree/modals/MobileActionsModal';

export default {
  methods: {
    openModal() {
      this.$modal.show(
        MobileActionsModal,
        {},
        {classes: 'clear_modal white_modal', class: 'mobile_bottom', height: 'auto', name: 'mobile-actions-modal'}
      );
    },
  },
  components: {DotsIcon},
  name: 'familyTreeMobileControlsButton',
};
</script>

<style lang="scss" scoped></style>

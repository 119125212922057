<template>
  <router-link :to="surnameRoute"
    >{{ clan.pinyin }} {{ clan.name_ch }}<span v-if="nameHant"> ({{ nameHant }})</span
    ><span v-if="isForVariant()"> ({{ getVariantName() }})</span>
  </router-link>
</template>

<script>
export default {
  name: 'linkSurnamePage',
  props: {
    clan: Object,
    forSpellingVariant: String,
  },
  data() {
    return {
      variantName: this.forSpellingVariant,
    };
  },
  computed: {
    nameHant() {
      return this.clan.name_hant !== this.clan.name_ch ? this.clan.name_hant : null;
    },
    surnameRoute() {
      return {name: 'search-tool-surname-detail', query: {word: this.clan.name_hant || this.clan.name_ch}};
    },
  },
  methods: {
    isForVariant() {
      return (
        this.variantName &&
        this.variantName !== this.clan.pinyin &&
        this.variantName !== this.nameHant &&
        this.variantName !== this.clan.name_ch
      );
    },
    getVariantName() {
      return this.variantName;
    },
  },
};
</script>

<style scoped></style>

<template>
  <router-link v-if="placeId" :to="placeRoute">{{ placeName }}</router-link>
  <span v-else-if="placeName">{{ placeName }}</span>
  <span v-else>Unknown Location</span>
</template>

<script>
import {getPlaceDetailRoute} from '@common/utils/utils.routes';

export default {
  props: {
    place: Object,
  },
  computed: {
    placeId() {
      return this.place ? this.place.object_id || this.place.id : null;
    },
    placeRoute() {
      return getPlaceDetailRoute(this.placeId, this.place.pinyin);
    },
    placeName() {
      if (this.place.pinyin === this.place.name) {
        return this.place.pinyin;
      }
      return `${this.place.pinyin || ''} ${this.place.name || ''}`.trim();
    },
  },
  name: 'linkPlacePage',
};
</script>

<style scoped></style>

<template>
  <div class="remove">
    <delete-icon :size="20" title="Remove"></delete-icon>
  </div>
</template>

<script>
import DeleteIcon from 'vue-material-design-icons/Delete';

export default {
  components: {
    DeleteIcon,
  },
  name: 'removeFieldIcon',
};
</script>

<style lang="scss" scoped>
.remove {
  margin-left: 8px;
  opacity: 0.4;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: left;

  &:hover {
    opacity: 1;
    color: $link-color;
    &::v-deep .material-design-icon__svg {
      color: $link-color;
    }
  }

  @media only screen and (max-width: 525px) {
    margin-top: 8px;
    margin-left: 0;
  }
}
</style>

<template>
  <div class="person-details modal-content family-tree-details-modal">
    <transition-expand>
      <person-details
        v-if="familyTreePersonState && !loading"
        :person="familyTreePersonState"
        :personPrimaryFullnameEn="familyTreePersonPrimaryFullnameEnState"
        :personPrimaryFullnameCn="familyTreePersonPrimaryFullnameCnState"
        :relatives="relatives"
        @click-relative="relativeClickHandler"
        @click-edit="editClickHandler"
        @click-delete="deleteClickHandler"
        @click-view-tree="
          viewTreeClickHandler($event);
          $emit('close');
        "
        @click-view-lineage="
          viewLineageClickHandler($event);
          $emit('close');
        "
        @click-add-parent="addParentHandler"
        @click-add-spouse="addSpouseHandler"
        @click-add-sibling="addSiblingHandler"
        @click-add-child="addChildHandler"
      ></person-details>
    </transition-expand>
    <mcr-loading-indicator :loading="loading"></mcr-loading-indicator>

    <person-details-context-menu-popover
      v-if="familyTreePersonState && !loading"
      :sections="contextMenuSections"
      :can-add-parent="!personHasBothParents"
      :is-mobile="isMobile"
      @click-edit="editClickHandler(familyTreePersonState)"
      @click-view-tree="
        viewTreeClickHandler(familyTreePersonState);
        $emit('close');
      "
      @click-view-lineage="
        viewLineageClickHandler(familyTreePersonState);
        $emit('close');
      "
      @click-delete="deleteClickHandler(familyTreePersonState)"
      @click-add-parent="addParentHandler(familyTreePersonState)"
      @click-add-spouse="addSpouseHandler(familyTreePersonState)"
      @click-add-sibling="addSiblingHandler(familyTreePersonState)"
      @click-add-child="addChildHandler(familyTreePersonState)"
    >
      <!--      <div class="helper-header" slot="header">-->
      <!--        <div>Press and hold a card </div>-->
      <!--        <div>to open this menu from tree </div>-->
      <!--      </div>-->
    </person-details-context-menu-popover>

    <close-button @click="$emit('close')"></close-button>
  </div>
</template>

<script>
import CloseButton from '@common/elements/buttons/closeButton';
import {mapGetters} from 'vuex';

import TransitionExpand from '@/base/transitions/TransitionExpand';

import PersonDetails from '@/components/modules/familyTree/tree/modals/personDetails';

import personDetailsContextMenuPopover from './details/personDetailsContextMenuPopover';

export default {
  props: {
    relativeClickHandler: Function,
    editClickHandler: Function,
    deleteClickHandler: Function,
    viewTreeClickHandler: Function,
    viewLineageClickHandler: Function,
    addParentHandler: Function,
    addSpouseHandler: Function,
    addSiblingHandler: Function,
    addChildHandler: Function,
    contextMenuSections: Array,
  },
  computed: {
    ...mapGetters([
      'familyTreePersonLoadingState',
      'familyTreePersonRelativesLoadingState',
      'familyTreePersonPhotosLoadingState',
      'familyTreePersonState',
      'getFamilyTreePersonRelativesState',
      'familyTreePersonPrimaryFullnameEnState',
      'familyTreePersonPrimaryFullnameCnState',
    ]),
    relatives() {
      return this.getFamilyTreePersonRelativesState(this.familyTreePersonState.object_id);
    },
    loading() {
      return (
        this.familyTreePersonRelativesLoadingState ||
        this.familyTreePersonLoadingState ||
        this.familyTreePersonPhotosLoadingState
      );
    },
    personHasBothParents() {
      return this.relatives && this.relatives.parents.length === 2;
    },
    isMobile() {
      return this.$store.getters.windowWidthState <= this.$breakpoints.mobile;
    },
  },
  components: {PersonDetails, CloseButton, TransitionExpand, personDetailsContextMenuPopover},
};
</script>

<style lang="scss" scoped>
@import './tree-modal.scss';
.person-details {
}
</style>

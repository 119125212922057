<template>
  <v-dropdown v-bind="$attrs" theme="interactive-tooltip" :autoHide="false" :offset="10">
    <slot></slot>
    <div slot="popper">
      <div class="interactive-tooltip--content-wrapper">
        <div class="interactive-tooltip--content">
          <div class="text-md bold">{{ heading }}</div>
          <div class="text-sm description">{{ text }}</div>
          <div class="actions" v-if="buttonLabel">
            <mcr-button class="white small" @click="$emit('button-click')">{{ buttonLabel }}</mcr-button>
          </div>
        </div>
        <div @click="$emit('close')"><close-icon :size="20"></close-icon></div>
      </div>
    </div>
  </v-dropdown>
</template>

<script>
import McrButton from '@common/elements/buttons/mcrButton.vue';
import CloseIcon from 'vue-material-design-icons/Close';

export default {
  inheritAttrs: false,
  props: {
    heading: String,
    text: String,
    buttonLabel: String,
  },
  components: {McrButton, CloseIcon},
  name: 'TooltipInteractive',
};
</script>

<style scoped lang="scss"></style>

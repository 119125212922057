<template>
  <div class="onboarding-me">
    <div class="main-heading heading-4 heading-5-mobile">Where were you born?</div>
    <div class="field date-field">
      <div class="input-label">Birth Date</div>
      <approximate-date-field
        :day="birthDate.day"
        :month="birthDate.month"
        :year="birthDate.year"
        :show-approximation="false"
        :show-labels="false"
        @change-day="changeBirthDate({day: $event})"
        @change-month="changeBirthDate({month: $event})"
        @change-year="changeBirthDate({year: $event})"
      ></approximate-date-field>
    </div>
    <div class="field place-field">
      <div class="input-label">Birth Place</div>
      <family-tree-location-multiselect-container
        placeholder=""
        multiselect-classes="bordered"
        :always-show-remove="false"
        :location="birthLocation || {}"
        :family-tree-id="null"
        @clear="clearBirthLocation"
        @select="changeBirthLocation"
      ></family-tree-location-multiselect-container>
      <div class="helper-text text-sm">Adding a city will improve records matching.</div>
    </div>
    <div class="place-suggestions">
      <div class="chip" v-for="item in suggestions" :key="item.place_id" @click="birthSuggestionClick(item)">
        <div>{{ item.display_text }}</div>
        <plus-icon class="plus-icon" :size="16"></plus-icon>
      </div>
    </div>
  </div>
</template>

<script>
import AnalyticsMainHandler from '@common/utils/analytics/analytics.main';
import PlusIcon from 'vue-material-design-icons/Plus';

import FamilyTreeLocationMultiselectContainer from '@/components/common/FamilyTreeLocationMultiselectContainer';
import approximateDateField from '@/components/common/tree/approximateDateField';

export default {
  mounted() {
    this.$emit('form-change', this.hasData);
  },
  data() {
    const data = this.$store.getters.treeOnboardingDataState.me || {};
    return {
      birthDate: data.birth_date || {day: null, month: null, year: null},
      birthLocation: data.birth_location,
    };
  },
  computed: {
    suggestions() {
      const userCountry = this.$store.getters.treeOnboardingDataState.user_country;
      return userCountry ? [userCountry] : [];
    },
    hasData() {
      return this.birthDate.day || this.birthDate.month || this.birthDate.year || this.birthLocation;
    },
  },
  methods: {
    birthSuggestionClick(location) {
      AnalyticsMainHandler.trackOnboardingClickPlaceShortcutEvent(
        location.level,
        location.display_text,
        location.place_id
      );
      this.changeBirthLocation(location);
    },
    changeBirthDate(data) {
      this.birthDate = {...this.birthDate, ...data};
      this.$emit('form-change', this.hasData);
    },
    clearBirthLocation() {
      this.changeBirthLocation(null);
    },
    changeBirthLocation(location) {
      this.birthLocation = location;
      this.$emit('form-change', this.hasData);
    },
    getData() {
      return {
        birth_date: this.birthDate,
        birth_location: this.birthLocation,
      };
    },
  },
  components: {approximateDateField, FamilyTreeLocationMultiselectContainer, PlusIcon},
  name: 'OnboardingMe',
};
</script>

<style lang="scss" scoped>
.onboarding-me {
  .main-heading {
    color: $neutral-600;
  }
  .date-field {
    margin-top: 24px;
  }
  .place-field {
    margin-top: 24px;
  }
  .helper-text {
    margin-top: 8px;
    color: $neutral-500;
  }
}
</style>

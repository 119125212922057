<template>
  <div class="extract-text-interstitial">
    <div class="close-btn round" @click="closeModal"></div>
    <div class="content">
      <div class="heading-4 heading-3-mobile">Text Extraction</div>
      <div class="paragraph text-md">
        Use our Optical Character Recognition technology (OCR) to extract text with AI.
      </div>
      <div class="paragraph text-md">
        Nifty for translating a tombstone in Chinese, or just transcribing documents in English.
      </div>
      <mcr-button @click="goToLibrary"
        ><span>Upload Photos to Extract</span><arrow-right-icon :size="18"></arrow-right-icon
      ></mcr-button>
    </div>
    <div class="image-container">
      <img :src="$getAsset('/assets/services/ocr-modal.png')" width="258" height="308" />
    </div>
  </div>
</template>

<script>
import McrButton from '@common/elements/buttons/mcrButton.vue';
import {getFamilyTreeLibraryRoute} from '@common/utils/utils.routes';
import ArrowRightIcon from 'vue-material-design-icons/ArrowRight';

export default {
  computed: {},
  methods: {
    closeModal() {
      this.$emit('close');
    },
    goToLibrary() {
      if (this.$route.name !== 'familytree-library') {
        this.$router.push(getFamilyTreeLibraryRoute(this.$store));
      }
      this.$emit('close');
    },
  },
  components: {McrButton, ArrowRightIcon},
  name: 'ExtractTextInterstitial',
};
</script>

<style scoped lang="scss">
.extract-text-interstitial {
  display: flex;
  max-width: 660px;

  .content {
    padding: 24px 32px 24px 24px;
    display: flex;
    flex-direction: column;

    .heading-4 {
      margin-bottom: 12px;
    }
    .paragraph {
      margin-bottom: 24px;
      width: 304px;
    }
  }

  .image-container {
    width: 258px;
    display: flex;
    overflow: hidden;
    border-radius: 0 4px 4px 0;
    flex-shrink: 0;
    flex-grow: 0;

    img {
      object-fit: cover;
      object-position: top;
      width: 100%;
    }
  }

  .arrow-right-icon {
    margin-left: 8px;
  }

  @media only screen and (max-width: $breakpoint-phablet) {
    flex-direction: column-reverse;
    .content {
      .paragraph {
        width: auto;
      }
    }

    .image-container {
      width: 100%;
      height: 200px;
    }
  }
}
</style>

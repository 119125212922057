<template>
  <div class="tab-library tab-content">
    <library-block v-if="displayLibrary" ref="libraryBlock" @initialized="uploadQueuedFiles"></library-block>
  </div>
</template>

<script>
import LibraryBlock from './components/LibraryBlock';

export default {
  data() {
    return {
      displayLibrary: false,
      needQueueFiles: true,
      queuedUploadFiles: [],
    };
  },
  methods: {
    activate() {
      this.displayLibrary = true;
    },
    handleFilesDrop(files) {
      if (this.needQueueFiles) {
        this.queuedUploadFiles = files;
      } else {
        this.$refs.libraryBlock.filesSelected(files);
      }
    },
    uploadQueuedFiles() {
      this.needQueueFiles = false;
      if (this.queuedUploadFiles.length) {
        this.$refs.libraryBlock.filesSelected(this.queuedUploadFiles);
        this.queuedUploadFiles = [];
      }
    },
  },
  components: {LibraryBlock},
  name: 'TabLibrary',
};
</script>

<style scoped></style>

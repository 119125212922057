<template>
  <div class="person-edit-form-block item-block">
    <div class="header">
      <div class="heading-6">{{ title }}</div>
      <mcr-button class="white small" @click="addDetailsClick"
        ><plus-circle-icon :size="20" /><span>Add Other Details</span></mcr-button
      >
    </div>

    <person-full-edit-form ref="form" :form-initial-data="familyTreePersonState"> </person-full-edit-form>

    <div class="action-buttons">
      <mcr-button class="white" :disabled="formLoading" @click="cancelClick">Cancel</mcr-button>
      <mcr-button class="save" :loading="formLoading" @click="saveClick">Save Changes</mcr-button>
    </div>

    <modal
      name="edit-cancel-confirm"
      classes="clear_modal white_modal"
      :scrollable="true"
      height="auto"
      @before-open="beforeEditCancelConfirmOpen"
    >
      <edit-cancel-modal-content
        :unsaved-field-keys="editCancelModalData.unsavedFields"
        :unsaved-fact-ids="editCancelModalData.unsavedFactsIds"
        :person-form-data="editCancelModalData.personFormData"
        :person-form-data-facts="editCancelModalData.personFormDataFacts"
        :person-initial-data="familyTreePersonState"
        :name="name"
        :options="familyTreePersonOptionsState"
        :save-loading="formLoading"
        modal-name="edit-cancel-confirm"
        @save="saveClick"
        @discard="$emit('end-edit-mode')"
      ></edit-cancel-modal-content>
    </modal>
    <modal name="add-details-modal" classes="clear_modal white_modal" :scrollable="true" height="auto">
      <div>
        <div class="heading">
          <div class="text-lg bold">Add New Details</div>
          <div class="close-btn" @click="$modal.hide('add-details-modal')"></div>
        </div>
        <fact-types-modal-content
          class="content"
          :types="factTypes"
          @select="addNewDetailsSelected"
        ></fact-types-modal-content>
      </div>
    </modal>
    <modal name="new-details-form-modal" classes="clear_modal white_modal" :scrollable="true" height="auto">
      <fact-type-add-modal-content
        :fact-type="factTypeForAdd"
        :options="factTypes"
        :tree-id="familyTreeId"
        modal-name="new-details-form-modal"
        @go-back="$modal.show('add-details-modal')"
        @save="addFact"
      ></fact-type-add-modal-content>
    </modal>
  </div>
</template>

<script>
import mcrButton from '@common/elements/buttons/mcrButton';
import PlusCircleIcon from 'vue-material-design-icons/PlusCircleOutline';
import {mapGetters} from 'vuex';

import PersonFullEditForm from '@/components/common/tree/PersonFullEditForm';

import {UNKNOWN_NAME} from '@/components/modules/familyTree/constants';
import EditCancelModalContent from '@/components/modules/familyTreeProfile/components/EditCancelModalContent';
import FactTypeAddModalContent from '@/components/modules/familyTreeProfile/components/FactTypeAddModalContent';
import FactTypesModalContent from '@/components/modules/familyTreeProfile/components/FactTypesModalContent';

export default {
  data() {
    return {
      factTypeForAdd: {},
      editCancelModalData: {},
    };
  },
  computed: {
    ...mapGetters([
      'familyTreePersonState',
      'familyTreePersonOptionsState',
      'familyTreePersonUpdateLoadingState',
      'familyTreePersonFactsUpdateLoadingState',
    ]),
    name() {
      const names = this.familyTreePersonState.first_names;
      return names && names[0] && names[0].value ? names[0].value : UNKNOWN_NAME;
    },
    title() {
      return `${this.name}'s Details`;
    },
    formLoading() {
      return this.familyTreePersonUpdateLoadingState || this.familyTreePersonFactsUpdateLoadingState;
    },
    familyTreeId() {
      return this.familyTreePersonState.family_tree_id;
    },
    factTypes() {
      return this.familyTreePersonOptionsState.fact_types.filter(type => type.is_for_person);
    },
  },
  methods: {
    addFact(fact) {
      this.$refs.form.addFact(fact);
    },
    saveClick() {
      if (this.formLoading) {
        return;
      }
      const personId = this.familyTreePersonState.object_id;
      const person = this.$refs.form.getModifiedPersonForSave();
      const facts = this.$refs.form.getModifiedPersonFactsForSave();
      const personPromise = this.$refs.form.unsavedFields.some(key => person.hasOwnProperty(key))
        ? this.$store.dispatch('updateFamilyTreePersonAction', person)
        : Promise.resolve();
      personPromise.then(() => {
        const factsPromise = facts.length
          ? this.$store.dispatch('updateFamilyTreePersonFactsAction', {personId, facts})
          : Promise.resolve();
        factsPromise.then(() => {
          this.$modal.hide('edit-cancel-confirm');
          this.$emit('end-edit-mode');
        });
      });
    },
    cancelClick() {
      if (this.$refs.form.unsavedFields.length) {
        return this.$modal.show('edit-cancel-confirm');
      }
      return this.$emit('end-edit-mode');
    },
    addDetailsClick() {
      this.$modal.show('add-details-modal');
    },
    addNewDetailsSelected(factType) {
      this.$modal.hide('add-details-modal');
      this.factTypeForAdd = factType;
      this.$modal.show('new-details-form-modal');
    },
    beforeEditCancelConfirmOpen() {
      this.editCancelModalData = {
        unsavedFields: this.$refs.form.unsavedFields,
        unsavedFactsIds: this.$refs.form.unsavedFactsIds,
        personFormData: this.$refs.form.personFormData,
        personFormDataFacts: this.$refs.form.personFormDataFacts,
      };
    },
  },
  components: {
    PersonFullEditForm,
    EditCancelModalContent,
    FactTypeAddModalContent,
    FactTypesModalContent,
    mcrButton,
    PlusCircleIcon,
  },
  name: 'PersonEditFormBlock',
};
</script>

<style lang="scss" scoped>
.person-edit-form-block {
  .header {
    position: sticky;
    z-index: 2;
    left: 0;
    right: 0;
    top: $main-menu-height;
    background: $background-light;
    box-shadow: $slight-shadow-bottom;
  }
  .action-buttons {
    padding: 20px;
    display: flex;
    justify-content: flex-end;
    column-gap: 16px;
    border-top: 1px solid $neutral-200;
    position: sticky;
    z-index: 2;
    box-shadow: $slight-shadow;
    background: $background-light;
    left: 0;
    right: 0;
    bottom: 0;

    .save {
      min-width: 130px;
    }
  }

  .edit-cancel-confirm {
    .unsaved-fields {
      color: $primary-400;
    }
  }

  @media only screen and (max-width: $main-menu-breakpoint) {
    .action-buttons {
      padding: 16px;
    }
    .header {
      top: $main-menu-height-mobile;
    }
  }
}
</style>

<template>
  <div class="stepper-item" :class="{active}">
    <div class="done-checkmark" v-if="done"><checkmark-icon :size="20"></checkmark-icon></div>
    <div class="number-container text-xs" v-else>
      <div class="number">{{ number }}</div>
    </div>
    <div class="name-text text-sm">{{ name }}</div>
  </div>
</template>

<script>
import CheckmarkIcon from 'vue-material-design-icons/CheckCircle';

export default {
  props: {
    name: String,
    number: String,
    done: Boolean,
    active: Boolean,
  },
  components: {CheckmarkIcon},
  name: 'StepperItem',
};
</script>

<style lang="scss" scoped>
.stepper-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  row-gap: 5px;

  .number-container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 20px;
    width: 20px;
  }
  .number {
    height: 16px;
    width: 16px;
    border-radius: 50%;
    border: 1px solid $neutral-400;
    color: $neutral-400;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .name-text {
    color: $neutral-400;
    white-space: nowrap;
  }

  .done-checkmark {
    display: flex;
    color: $primary-400;
  }

  &.active {
    .number {
      color: white;
      background-color: $primary-400;
      border-color: $primary-400;
    }
    .name-text {
      color: $primary-400;
      font-weight: 600;
    }
  }
}

@media only screen and (max-width: $breakpoint-mobile) {
  .stepper-item.active {
    .number-container {
      display: none;
    }
  }
  .stepper-item:not(.active) {
    .name-text {
      display: none;
    }
  }
}
</style>

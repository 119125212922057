<template>
  <svg v-if="viewBox" class="map-svg" :viewBox="viewBox" :style="svgStyle">
    <template v-for="connector in simpleConnectors">
      <template v-for="line in connector.lines">
        <circle v-if="line.radius" :cx="line.x1" :cy="line.y1" :r="line.radius" :fill="lineColor"></circle>
        <line
          :key="line.id"
          :x1="line.x1"
          :y1="line.y1"
          :x2="line.x2"
          :y2="line.y2"
          :stroke="lineColor"
          stroke-width="8"
          style="z-index: 9"
        />
      </template>
    </template>
    <template v-for="person of persons">
      <g v-for="draw in person.draws">
        <rect
          style="z-index: 10"
          :width="cardWidth"
          :height="cardHeight"
          :x="draw.position.x"
          :y="draw.position.y"
          :fill="getCardColor(person)"
          stroke-width="10"
          :stroke="lineColor"
        />
        <image
          v-if="person.profile_picture"
          :href="getPicture(person)"
          :width="pictureWidth"
          :x="draw.position.x + 4"
          :y="draw.position.y + 4"
        ></image>
        <circle
          v-if="personHasHints(person)"
          :cx="draw.position.x + cardWidth"
          :cy="draw.position.y"
          r="32"
          fill="#B80C15"
        />
      </g>
    </template>
  </svg>
</template>

<script>
import {WIDTH, HEIGHT} from '@/components/modules/familyTree/constants';

export default {
  props: {
    persons: Array,
    connectors: Array,
    viewBox: String,
    fullWidth: Number,
    fullHeight: Number,
    wrapperStyle: Object,
    colorCodeGender: {type: Boolean, default: true},
  },
  data() {
    return {
      cardWidth: WIDTH,
      cardHeight: HEIGHT,
      maleColor: '#E3F4F6',
      femaleColor: '#FFE9ED',
      neutralColor: '#F0F1F3',
      ghostColor: '#FFFFFF',
      startPersonColor: '#353535',
      lineColor: '#565656',
    };
  },
  computed: {
    svgStyle() {
      return {
        'z-index': '11',
        width: this.fullWidth + 'px',
        height: this.fullHeight + 'px',
        ...this.wrapperStyle,
      };
    },
    pictureWidth() {
      return this.cardWidth - 8;
    },
    simpleConnectors() {
      return this.connectors.filter(connector => connector.type !== 'controller');
    },
  },
  methods: {
    getCardColor(person) {
      if (person.is_ghost) {
        return this.ghostColor;
      }
      if (person.is_start_person) {
        return this.startPersonColor;
      }
      if (!this.colorCodeGender) {
        return this.neutralColor;
      }
      if (person.is_male) {
        return this.maleColor;
      }
      if (person.is_female) {
        return this.femaleColor;
      }
      return this.neutralColor;
    },
    personHasHints(person) {
      return Boolean(this.$store.getters.familyTreeHintsCountsByPersonIdsState[person.id]);
    },
    getPicture(person) {
      return person.profile_picture.attachment;
    },
  },
  components: {},
  name: 'family-tree-simple-svg',
};
</script>

<style scoped lang="scss">
.map-svg {
  transform-style: preserve-3d;
  -webkit-transform-style: preserve-3d;
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
  user-drag: none;
  pointer-events: none;
  position: absolute;
}
</style>

<template>
  <div class="asset-edit-form-container">
    <div class="loading text-md">
      <transition name="fade" mode="out-in">
        <animated-checkmark ref="loading-checkmark" v-if="saveLoadingText" key="checkmark"></animated-checkmark>
      </transition>
      <transition name="fade" mode="out-in">
        <div v-if="saveLoadingText" :key="saveLoadingText">{{ saveLoadingText }}</div>
      </transition>
    </div>
    <people-select-container
      :value="peopleValue"
      :family-tree-id="asset.family_tree_id"
      @select="peopleSelect"
      @remove="peopleRemove"
      @clear="clearPeople"
    ></people-select-container>

    <family-tree-location-multiselect-container
      placeholder="Search for a place"
      :location="asset.location_tag"
      :family-tree-id="asset.family_tree_id"
      @clear="clearPlace"
      @select="placeSelect"
    ></family-tree-location-multiselect-container>

    <date-multiselect :value="dateValue" @select="dateSelect" @clear="clearDate"></date-multiselect>

    <textarea
      :ref="descriptionRef"
      :value="asset.description"
      @blur="descriptionBlur"
      placeholder="Description"
      @keyup.prevent
    />
  </div>
</template>

<script>
import AnimatedCheckmark from '@common/elements/icons/AnimatedCheckmark';
import {mapGetters} from 'vuex';

import PeopleSelectContainer from '@/components/common/inputs/FamilyTreePeopleMultiselectContainer';

import FamilyTreeLocationMultiselectContainer from '../FamilyTreeLocationMultiselectContainer';
import DateMultiselect from './DateMultiselect';

export default {
  props: {
    asset: Object,
  },
  data() {
    return {
      descriptionRef: 'description-ref',
      saveLoadingText: '',
    };
  },
  watch: {
    asset(newValue, oldValue) {
      // on click next or previous in gallery, the component is not re-mounted, so need to cleanup
      if (newValue.object_id !== oldValue.object_id) {
        this.dateOptions = [];
      }
    },
  },
  computed: {
    ...mapGetters([
      'familyTreeAssetUpdateLoadingState',
      'familyTreePersonsSearchListState',
      'familyTreePersonsSearchListLoadingState',
    ]),
    dateValue() {
      return this.asset.event_date ? {label: this.asset.event_date, value: this.asset.event_date} : null;
    },
    peopleValue() {
      return this.asset.persons.map(person => {
        return {...person, full_name: person.full_name || 'Unknown Person'};
      });
    },
  },
  methods: {
    containsChanges(data) {
      for (let [key, value] of Object.entries(data)) {
        if (value !== this.asset[key]) {
          return true;
        }
      }
    },
    changeAsset(data) {
      if (this.containsChanges(data)) {
        this.saveLoadingText = 'Saving changes...';
        return this.$store
          .dispatch('patchAssetDetailsAction', {id: this.asset.object_id, ...data})
          .then(() => {
            this.saveLoadingText = 'Changes saved!';
            this.$refs['loading-checkmark'].toDone();
            setTimeout(() => {
              this.saveLoadingText = '';
            }, 3000);
          })
          .catch(() => {
            this.$toasted.error('Error saving changes.');
            this.saveLoadingText = '';
          });
      }
      return Promise.resolve();
    },
    clearPlace() {
      this.changeAsset({location_tag: null});
    },
    placeSelect(item) {
      this.changeAsset({location_tag: item});
    },
    clearDate() {
      this.changeAsset({event_date: null});
    },
    dateSelect(item) {
      this.changeAsset({event_date: item.value});
    },
    descriptionBlur() {
      this.saveDescription();
    },
    saveDescription() {
      this.changeAsset({description: this.$refs[this.descriptionRef].value});
    },
    peopleSelect(item) {
      const newPersons = [...this.peopleValue, item];
      this.setPersonsToAssetDetails(newPersons);
    },
    peopleRemove(item) {
      const newPersons = this.peopleValue.filter(person => person.object_id !== item.object_id);
      this.setPersonsToAssetDetails(newPersons);
    },
    setPersonsToAssetDetails(persons) {
      this.$store.commit('mutateFamilyTreeAssetDetailsByIdState', {object_id: this.asset.object_id, persons});
      this.saveLoadingText = 'Saving changes...';
      this.$store
        .dispatch('setPersonsToAssetDetailsAction', {
          id: this.asset.object_id,
          persons: persons.map(person => {
            return {pk: person.object_id};
          }),
        })
        .then(() => {
          this.saveLoadingText = 'Changes saved!';
          this.$refs['loading-checkmark'].toDone();
          setTimeout(() => {
            this.saveLoadingText = '';
          }, 3000);
        });
    },
    clearPeople() {
      this.setPersonsToAssetDetails([]);
    },
  },
  components: {
    AnimatedCheckmark,
    DateMultiselect,
    FamilyTreeLocationMultiselectContainer,
    PeopleSelectContainer,
  },
  name: 'AssetEditFormContainer',
};
</script>

<style lang="scss" scoped>
input,
textarea {
  width: 100%;
  box-sizing: border-box;
  padding-right: 40px;
  margin-bottom: 12px;
}
textarea {
  resize: none;
  height: 8em;
}

.loading {
  margin-bottom: 8px;
  min-height: 24px;
  display: flex;
  column-gap: 8px;
  color: $dark-color-contrast-1;

  .checkmark-done::v-deep svg {
    height: 18px;
    width: 18px;
  }
}

.multiselect,
.family-tree-location-multiselect-container {
  margin-bottom: 8px;
}

.family-tree-location-multiselect-container::v-deep .multiselect .multiselect__tags,
.family-tree-location-multiselect-container::v-deep .multiselect .multiselect__single,
.multiselect::v-deep .multiselect__tags,
.multiselect::v-deep .multiselect__single,
input,
textarea {
  background: #111;
  color: $supplemental-text-color;
  box-shadow: none;
  border: 1px solid #222;
}
.family-tree-location-multiselect-container::v-deep .multiselect.multiselect--active .multiselect__tags,
.family-tree-location-multiselect-container::v-deep .multiselect.multiselect--active input,
.multiselect.multiselect--active::v-deep .multiselect__tags,
.multiselect.multiselect--active::v-deep input,
input:focus,
textarea:focus {
  color: $off-white;
  background: black;
  border: 1px solid #333;
  -webkit-appearance: none;
  outline: none;
}

.family-tree-location-multiselect-container::v-deep .multiselect.multiselect--active input::placeholder,
.family-tree-location-multiselect-container::v-deep .multiselect .multiselect__placeholder,
.multiselect.multiselect--active::v-deep input::placeholder,
.multiselect::v-deep .multiselect__placeholder,
input::placeholder,
textarea::placeholder {
  color: #555;
}

.family-tree-location-multiselect-container::v-deep .multiselect.multiselect--active input,
.family-tree-location-multiselect-container::v-deep .multiselect .multiselect__single,
.family-tree-location-multiselect-container::v-deep .multiselect__input,
.multiselect.multiselect--active::v-deep input,
.multiselect::v-deep .multiselect__single,
.multiselect__input {
  border: 0;
}

.family-tree-location-multiselect-container::v-deep .multiselect,
.multiselect::v-deep {
  .loading-after-list {
    color: $supplemental-text-color;
  }
  .multiselect__tags {
    min-height: 48px;
    padding: 11px 12px;
  }
  .caret-icon,
  .caret-icon.close-icon {
    top: 12px;
  }

  .helper-before-list {
    color: $supplemental-text-color;
  }
}

.people-multiselect::v-deep,
.family-tree-location-multiselect-container::v-deep {
  .multiselect__spinner {
    background: black;
  }
}

.people-multiselect::v-deep {
  .multiselect__tags {
    .multiselect__tags-wrap {
      display: flex;
      flex-wrap: wrap;
      margin-right: 15px;
    }
    .multiselect__tag {
      border-radius: 4px;
      background-color: #222;
      padding: 8px 26px;
      padding-left: 10px;
      margin-bottom: 9px;
      color: $supplemental-text-color;

      span {
        position: relative;
        color: $supplemental-text-color;
      }
      .multiselect__tag-icon {
        display: flex;
        justify-content: center;
        align-items: center;
        &::after {
          color: #555;
        }
        &:hover {
          background-color: rgba(white, 0.06);
          &::after {
            color: #666;
          }
        }
      }
    }

    input {
      margin-bottom: 0;
    }
  }
}

.family-tree-location-multiselect-container::v-deep .multiselect .multiselect__content-wrapper,
.multiselect::v-deep .multiselect__content-wrapper {
  background-color: #0a0a0a;
  border: 1px solid #333;
  border-top: none;
  box-shadow: $box-shadow-light;
  .multiselect__options {
    border: 1px solid #333;
  }
  .multiselect__option {
    background: #0a0a0a;
    color: $supplemental-text-color;
    &.multiselect__option--highlight {
      background: #111;
      color: $pale-gold;
    }
    &.multiselect__option--selected {
      &::after {
        background: none;
        opacity: 0.3;
        font-size: 0.8em;
        text-transform: uppercase;
        font-size: 12px;
        top: 0px;
      }
    }
  }
}
.family-tree-location-multiselect-container::v-deep .multiselect__option.multiselect__option--highlight {
  .material-design-icon__svg,
  .level-name {
    color: $pale-gold;
  }
}

.description-buttons {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-bottom: 24px;
  .mcr-button {
    margin-left: 12px;
    border: 0;
    box-shadow: $box-shadow-extra-light;
    &:first-child {
      margin-left: 0;
    }
    &.cancel {
      background-color: rgba(white, 0.05);
      color: #888;
      &:hover {
        border-color: rgba(white, 0.2);
      }
    }
  }
}
</style>

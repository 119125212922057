<template>
  <div class="fact-display">
    <div v-if="fact.value">{{ fact.value }}</div>
    <date-location-display
      v-if="fact.start_date || fact.end_date || location"
      :date="fact.start_date"
      :date2="fact.end_date"
      :location="location"
    ></date-location-display>
    <router-link v-if="fact.cemetery && fact.cemetery.object_id" :to="cemeteryRoute">{{
      fact.cemetery.name
    }}</router-link>
    <div v-for="infoValue in fact.info" :key="infoValue.key">
      <span>{{ infoValue.label }}: {{ infoValue.value }}</span>
    </div>
  </div>
</template>

<script>
import {getCemeteryDetailRoute} from '@common/utils/utils.routes';

import DateLocationDisplay from '@/components/common/tree/dateLocationDisplay';

export default {
  props: {
    fact: Object,
  },
  computed: {
    location() {
      return this.fact.place_display_text
        ? {display_text: this.fact.place_display_text, place_id: this.fact.place_id}
        : null;
    },
    cemeteryRoute() {
      if (this.fact.cemetery) {
        return getCemeteryDetailRoute(this.fact.cemetery.object_id, this.fact.cemetery.name);
      }
    },
  },
  components: {DateLocationDisplay},
  name: 'FactDisplay',
};
</script>

<style lang="scss" scoped></style>

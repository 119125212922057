<template>
  <div class="person-profile-picture-crop with-sticky-buttons">
    <close-button @click="cancelClick"></close-button>
    <div class="content">
      <div class="heading-5">Drag and zoom to crop this picture.</div>
      <actions-helper></actions-helper>
      <div class="loading-container" v-if="loading || imgLoading">
        <mcr-loading-indicator :loading="true"></mcr-loading-indicator>
      </div>
      <div class="cropper-wrapper" :class="{loaded: !loading && !imgLoading}">
        <cropper
          class="cropper"
          ref="cropper"
          :src="initialImageSrc"
          :min-width="120"
          :stencil-props="{
            aspectRatio: 1,
          }"
          :canvas="{
            height: 512,
            width: 512,
          }"
          @change="onChange"
          @ready="onImageLoaded"
        />
      </div>
    </div>

    <div class="action-buttons">
      <mcr-button :loading="saveLoading" @click="okClick" class="full-width">Save</mcr-button>
    </div>
  </div>
</template>

<script>
import CloseButton from '@common/elements/buttons/closeButton';
import McrButton from '@common/elements/buttons/mcrButton';
import {Cropper} from 'vue-advanced-cropper';
import 'vue-advanced-cropper/dist/style.css';

import ActionsHelper from '@/components/modules/familyTree/actionsHelper';

export default {
  props: {
    initialImageSrc: String,
    loading: Boolean,
    saveLoading: Boolean,
    onCrop: {type: Function, required: false},
  },
  data() {
    return {
      croppa: {},
      imgLoading: true,
      result: {
        coordinates: null,
        image: null,
      },
    };
  },
  methods: {
    onChange({coordinates, image}) {
      this.result = {
        coordinates,
        image,
      };
    },
    onImageLoaded() {
      this.imgLoading = false;
    },
    okClick() {
      const {canvas} = this.$refs.cropper.getResult();
      if (canvas) {
        canvas.toBlob(blob => {
          this.$emit('cropped', blob);
          if (this.onCrop) {
            this.onCrop(blob);
          }
        });
      }
    },
    cancelClick() {
      this.$emit('close');
    },
  },
  components: {McrButton, ActionsHelper, CloseButton, Cropper},
  name: 'personProfilePictureCrop',
};
</script>

<style lang="scss" scoped>
.person-profile-picture-crop {
  position: relative;
  box-sizing: border-box;
  max-width: 800px;

  .cropper-wrapper,
  .loading-container {
    height: 400px;
    overflow: hidden;
    margin: 20px 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .cropper-wrapper.loaded {
    background: black;
  }

  .family-tree-actions-helper {
    position: relative;
    left: 0;
    top: 0;
    opacity: 0.8;
    margin-top: 15px;
    &::v-deep .quick-actions {
      display: none;
    }
  }

  .loading-container {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    left: 0;
    right: 0;

    .mcr-loading-indicator {
      margin: 0;
    }
  }

  .close-btn {
    position: absolute;
    top: 10px;
    right: 10px;
  }

  @media only screen and (min-width: $breakpoint-phablet) {
    .cropper-wrapper {
      width: 500px;
    }
  }
}
</style>

<template>
  <multiselect
    placeholder="Select clan to save to"
    class="tree-clan-select bordered"
    ref="select"
    :value="selected"
    :options="options"
    :loading="loading"
    :internal-search="false"
    open-direction="bottom"
    track-by="_id"
    label="full_name"
    select-label=""
    deselect-label=""
    @select="onSelect"
    @open="onSearchChange"
    @search-change="onSearchChange"
  >
    <template v-slot:option="params">
      <span class="option-label">
        <span class="clan-name">{{ params.option.clan_name }} family</span
        ><span class="family-tree-name">({{ params.option.family_tree_name }})</span>
      </span>
    </template>
    <template v-slot:singleLabel="params">
      <span class="option-label">
        <span class="clan-name">{{ params.option.clan_name }}</span
        ><span class="family-tree-name">({{ params.option.family_tree_name }})</span>
      </span>
    </template>
    <template v-slot:noResult>No Clans found.</template>
    <template v-slot:beforeList>
      <div class="helper-before-list">Type in English or Chinese to search</div>
    </template>
  </multiselect>
</template>

<script>
import {getRandomString} from '@common/utils/utils';
import Multiselect from 'vue-multiselect';

export default {
  data() {
    return {
      loading: false,
      selected: null,
      options: [],
    };
  },
  methods: {
    autofocus() {
      this.$refs.select.$el.focus();
    },
    onSelect(value) {
      this.selected = value;
      this.$emit('select', value);
    },
    onSearchChange(query) {
      this.loading = true;
      this.network.familyTree
        .searchClansInAllTrees({query: query})
        .then(response => {
          this.options = response.objects.map(obj => {
            const fullName = `${obj.clan_name} family (${obj.family_tree_name})`;
            return {...obj, _id: getRandomString(), full_name: fullName};
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  components: {Multiselect},
  name: 'TreeClanSelect',
};
</script>

<style scoped lang="scss">
.tree-clan-select::v-deep {
  .option-label {
    .family-tree-name {
      margin-left: 5px;
      color: $supplemental-text-color;
    }
  }
  .multiselect__option--highlight {
    .family-tree-name {
      color: $pale-gold;
    }
  }
}
</style>

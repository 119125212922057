<template>
  <span class="link-surname-page-family" v-if="clans">
    <link-surname-page
      v-for="clan in clans"
      :key="clan.name_ch"
      :clan="clan"
      class="name"
      :for-spelling-variant="displaySpellingVariant"
    ></link-surname-page>
    <span v-if="showFamily"> {{ familyWord }}</span>
  </span>
</template>

<script>
import linkSurnamePage from '@common/elements/links/linkSurnamePage';

export default {
  props: {
    clans: Array,
    showFamily: {type: Boolean, default: true},
    forSpellingVariant: String,
  },
  computed: {
    isMultipleClans() {
      return this.clans.length > 1;
    },
    familyWord() {
      return this.isMultipleClans ? 'families' : 'family';
    },
    displaySpellingVariant() {
      return this.isMultipleClans ? '' : this.forSpellingVariant;
    },
  },
  components: {linkSurnamePage},
};
</script>

<style scoped lang="scss">
.link-surname-page-family {
  .name:not(:last-of-type)::after {
    content: ', ';
  }
}
</style>

<template>
  <div class="asset-more-options-modal" v-touch:swipe.bottom="closeModal">
    <div class="quick-action" @click="download">
      <download-icon :size="24"></download-icon>
      <div v-if="downloadLoading"><bounce-loading></bounce-loading></div>
      <div v-else>Download</div>
    </div>
    <div v-if="canDelete" class="quick-action" @click="onDeleteClick">
      <delete-icon :size="24"></delete-icon>
      <div>Delete</div>
    </div>
  </div>
</template>

<script>
import BounceLoading from '@common/elements/loading-indicators/bounceLoading.vue';
import DeleteIcon from 'vue-material-design-icons/Delete';
import DownloadIcon from 'vue-material-design-icons/Download';

export default {
  props: {
    href: String,
    filename: String,
    deleteHandler: Function,
    canDelete: Boolean,
  },
  data() {
    return {
      downloadLoading: false,
    };
  },
  methods: {
    closeModal() {
      this.$emit('close');
    },
    download() {
      this.downloadLoading = true;
      let xhr = new XMLHttpRequest();
      xhr.open('GET', this.href, true);
      xhr.responseType = 'blob';
      xhr.onload = () => {
        let blob = xhr.response;
        saveAs(blob, this.filename);
        this.downloadLoading = false;
        this.closeModal();
      };
      xhr.onerror = () => {
        this.downloadLoading = false;
      };
      xhr.send();
    },
    onDeleteClick() {
      this.closeModal();
      this.deleteHandler();
    },
  },
  components: {BounceLoading, DownloadIcon, DeleteIcon},
  name: 'AssetMoreOptionsModal',
};
</script>

<style scoped lang="scss"></style>

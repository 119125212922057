<template>
  <div class="breadcrumbs" v-if="needShowBreadCrumbsState && treeBreadcrumbsState.treeId == familyTreId">
    <tree-breadcrumbs-item :person="treeBreadcrumbsState.homePerson" @click="onBreadcrumbClick"></tree-breadcrumbs-item>
    <template v-for="person in displayedPersons">
      <div class="slash-divider text-md" :key="person.id + 'divider'">/</div>
      <tree-breadcrumbs-item :key="person.id" :person="person" @click="onBreadcrumbClick"></tree-breadcrumbs-item>
    </template>
  </div>
</template>

<script>
import {mapGetters} from 'vuex';

import treeBreadcrumbsItem from './treeBreadcrumbsItem';

export default {
  computed: {
    ...mapGetters(['treeBreadcrumbsState', 'familyTreeDetailsIdState', 'needShowBreadCrumbsState', 'windowWidthState']),
    familyTreId() {
      return this.$route.params.id;
    },
    displayedPersons() {
      const availableWidth = this.windowWidthState - 60;
      return this.estimatedLength > availableWidth
        ? this.treeBreadcrumbsState.persons.slice(-1)
        : this.treeBreadcrumbsState.persons;
    },
    estimatedLength() {
      const iconWidth = 24;
      const letterWidth = 8;
      const dividerWidth = 24;
      const padding = 32;
      const homePersonWidth =
        (this.treeBreadcrumbsState.homePerson.full_name || 'Unknown').length * letterWidth + iconWidth;
      const personWidths = this.treeBreadcrumbsState.persons.map(p => {
        return (p.full_name || 'Unknown').length * letterWidth + iconWidth + dividerWidth;
      });
      const sum = personWidths.reduce((accumulator, currentValue) => accumulator + currentValue, 0);
      return homePersonWidth + sum + padding;
    },
  },
  methods: {
    onBreadcrumbClick(person) {
      const query = {start_person_id: person.object_id};
      this.$router.push({name: 'familytree-details', params: {id: this.familyTreeDetailsIdState}, query});
    },
  },
  components: {treeBreadcrumbsItem},
  name: 'treeBreadcrumbs',
};
</script>

<style scoped lang="scss">
.breadcrumbs {
  position: fixed;
  z-index: calc(#{$modal-z-index} - 1);
  left: 20px;
  bottom: 16px;
  display: flex;
  background: white;
  border: 1px solid $neutral-300;
  border-radius: 4px;
  padding: 6px 8px;

  .slash-divider {
    color: $neutral-400;
    margin: 0 8px;
  }
}
@media only screen and (max-width: $main-menu-breakpoint) {
  .breadcrumbs {
    left: 12px;
    bottom: 12px;
  }
}
</style>

<template>
  <transition name="info-bar-slide-bottom-fade">
    <div class="extract-text-prompt-container" v-if="shown">
      <div class="extract-text-prompt">
        <div class="flex-row">
          <crop-icon :size="30"></crop-icon>
          <div class="label text-md">Select the area to extract text from</div>
        </div>
        <div class="flex-row">
          <mcr-button class="action small transparent" @click="$emit('dismiss')">Cancel</mcr-button>
          <mcr-button class="action small" :disabled="disabled" @click="$emit('confirm')"
            >Extract Selected Area</mcr-button
          >
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import mcrButton from '@common/elements/buttons/mcrButton.vue';
import CropIcon from 'vue-material-design-icons/Crop';

export default {
  props: {
    shown: Boolean,
    disabled: Boolean,
  },
  components: {CropIcon, mcrButton},
  name: 'ExtractTextPrompt',
};
</script>

<style scoped lang="scss">
.extract-text-prompt-container {
  position: absolute;
  bottom: 24px;
  left: 0;
  right: 0;
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;

  .extract-text-prompt {
    border-radius: 4px;
    color: $background-light;
    background: $dark-background-1;
    padding: 18px 16px;
    display: flex;
    column-gap: 32px;
    align-items: center;

    .flex-row {
      align-items: center;
    }

    .label {
      color: $neutral-200;
      margin-left: 8px;
    }

    .mcr-button.action.transparent {
      margin-right: 16px;
    }
  }
}
</style>

<template>
  <div class="save-zupu-modal-content">
    <div class="heading">
      <div class="text-lg bold">Save Zupu</div>
      <close-icon class="close-icon" @click="closeModal"></close-icon>
    </div>
    <div class="content">
      <template v-if="!ownedZupu">
        <div class="label text-md bold">Save to My Zupus</div>
        <div class="text-sm sublabel">Save this record to "My Zupus" for later reference.</div>

        <mcr-button :loading="loadingToZupus" :disabled="loadingToClan" @click="saveToZupusClick"
          >Save to My Zupus</mcr-button
        >
        <hr />
      </template>

      <div class="label text-md bold">Save to Clan</div>
      <tree-clan-select ref="clan-select" @select="onClanSelect"></tree-clan-select>
      <mcr-button :loading="loadingToClan" :disabled="disableSaveToClan" @click="saveToClanClick"
        >Save to Clan</mcr-button
      >
    </div>
  </div>
</template>

<script>
import mcrButton from '@common/elements/buttons/mcrButton.vue';
import CloseIcon from 'vue-material-design-icons/Close';

import TreeClanSelect from '@/components/common/tree/TreeClanSelect';

export default {
  props: {
    zupuId: String,
    ownedZupu: Object,
    refetchList: Function,
  },
  data() {
    return {
      selectedClan: null,
      loadingToZupus: false,
      loadingToClan: false,
    };
  },
  mounted() {
    this.$refs['clan-select'].autofocus();
  },
  computed: {
    disableSaveToClan() {
      return this.loadingToZupus || !this.selectedClan;
    },
  },
  methods: {
    onClanSelect(value) {
      this.selectedClan = value;
    },
    closeModal() {
      this.$emit('close');
    },
    saveToZupusClick() {
      this.loadingToZupus = true;
      const payload = {clanPedigreeId: this.zupuId, family_tree_id: null, clan_name: null};
      return this.saveZupu(payload).catch(() => {
        this.loadingToZupus = false;
        this.$toasted.error('Save to My Zupus failed. Please try again later.');
      });
    },
    saveToClanClick() {
      this.loadingToClan = true;
      const payload = {
        clanPedigreeId: this.zupuId,
        family_tree_id: this.selectedClan.family_tree_id,
        clan_name: this.selectedClan.clan_name,
      };
      if (this.ownedZupu) {
        return this.network.savedPedigree
          .update({id: this.ownedZupu.object_id, ...payload})
          .then(() => {
            this.$toasted.success('Updated!');
            if (this.refetchList) {
              this.refetchList();
            }
            this.closeModal();
          })
          .catch(() => {
            this.loadingToClan = false;
            this.$toasted.error('Save to Clan failed. Please try again later.');
          });
      }
      return this.saveZupu(payload).catch(() => {
        this.loadingToClan = false;
        this.$toasted.error('Save to Clan failed. Please try again later.');
      });
    },
    saveZupu(payload) {
      return this.$store.dispatch('createSavedClanPedigreeAction', payload).then(() => {
        const action = {text: 'Open My Zupus', push: {name: 'my-zupus'}};
        this.$toasted.success('Record added!', {action});
        this.closeModal();
      });
    },
  },
  components: {mcrButton, CloseIcon, TreeClanSelect},
  name: 'SaveZupuModalContent',
};
</script>

<style scoped lang="scss">
.save-zupu-modal-content {
  width: 500px;

  .sublabel {
    margin-bottom: 16px;
    color: $neutral-500;
  }
  hr {
    border-color: $neutral-200;
    margin: 20px 0;
  }

  .tree-clan-select {
    margin-top: 8px;
    margin-bottom: 16px;
  }
  .tree-clan-select + .mcr-button {
    margin-top: 80px;
  }
  @media only screen and (max-width: $reading-max-width) {
    width: 100vw;
  }
}
</style>

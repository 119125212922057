<template>
  <div class="asset-details-block">
    <div v-if="isEditAllowed" class="edit-form">
      <asset-edit-form-container :asset="assetDetails"></asset-edit-form-container>
      <asset-upload-meta :asset="assetDetails"></asset-upload-meta>
    </div>

    <div v-else class="readonly">
      <hr />
      <p v-html="taggedPersonsReadonlyHtml"></p>
      <p v-html="placeDateReadonlyHtml"></p>
      <p class="file-description">{{ assetDetails.description }}</p>
      <asset-upload-meta :asset="assetDetails"></asset-upload-meta>
    </div>
  </div>
</template>

<script>
import {UNKNOWN_NAME} from '@/components/modules/familyTree/constants';

import AssetEditFormContainer from './AssetEditFormContainer';
import AssetUploadMeta from './AssetUploadMeta';

export default {
  props: {
    isEditAllowed: Boolean,
    assetDetails: Object,
  },
  computed: {
    taggedPersonsReadonlyHtml() {
      const persons = this.assetDetails && this.assetDetails.persons ? this.assetDetails.persons : [];
      let str = '';
      for (let i = 0; i < persons.length; i++) {
        const separator = i === persons.length - 1 ? ' and ' : ', ';
        const personHtml = `<strong>${persons[i].full_name || UNKNOWN_NAME}</strong>`;
        str = str ? `${str}${separator}${personHtml}` : personHtml;
      }
      return str;
    },
    placeDateReadonlyHtml() {
      const asset = this.assetDetails;
      const location = asset.location || (asset.location_tag ? asset.location_tag.display_text : '');
      const dateLocation = [asset.event_date, location];
      return dateLocation
        .filter(str => str)
        .map(str => `<strong>${str}</strong>`)
        .join(' in ');
    },
  },
  name: 'AssetDetailsBlock',
  components: {AssetUploadMeta, AssetEditFormContainer},
};
</script>

<style scoped lang="scss">
.asset-details-block {
  width: 100%;
  .readonly::v-deep {
    hr {
      border-color: $title-color;
    }

    p {
      color: $supplemental-text-color;

      strong {
        font-weight: normal;
        color: $pale-gold;
      }

      &.file-description {
      }
    }
    @media only screen and (max-width: $breakpoint-desktop) {
      hr {
        display: none;
      }
    }
  }
}
</style>

<template>
  <div class="checkmark-done">
    <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect
        v-if="!isCompleted || animate || animateReverse"
        class="glow-circle"
        :class="{'is-active': isActive}"
        x="1"
        y="1"
        width="26"
        height="26"
        rx="13"
      />
      <circle
        v-if="!isCompleted || animate || animateReverse"
        class="dash-circle"
        :class="{
          'circle-fade-out': animate,
          'circle-fade-in': animateReverse,
          pulse: animatePulse,
          'is-active': isActive,
        }"
        cx="14"
        cy="14"
        r="10"
      />
      <svg v-if="isCompleted || animate || animateReverse" viewBox="0 0 24 24" height="28" width="28">
        <path
          opacity="0"
          :class="{
            'check-fade-in': animate,
            persist: isCompleted,
            'check-fade-out': animateReverse,
            'is-active': isActive,
            'is-done': isCompleted,
          }"
          d="M12 2C6.5 2 2 6.5 2 12S6.5 22 12 22 22 17.5 22 12 17.5 2 12 2M10 17L5 12L6.41 10.59L10 14.17L17.59 6.58L19 8L10 17Z"
        />
      </svg>
    </svg>
  </div>
</template>
<script>
export default {
  name: 'AnimatedCheckmark',
  data() {
    return {
      animate: false,
      animateReverse: false,
      animatePulse: false,
      isCompleted: this.isCompletedState,
    };
  },
  props: {
    isCompletedState: {
      type: Boolean,
      default: false,
    },
    isActive: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    toDone() {
      this.animatePulse = false;
      this.isCompleted = false;
      this.animate = true;
      setTimeout(() => {
        this.isCompleted = true;
        this.animate = false;
        this.$emit('complete');
      }, 500);
    },
    toNotDone() {
      this.animatePulse = false;
      this.isCompleted = true;
      this.animateReverse = true;
      setTimeout(() => {
        this.isCompleted = false;
        this.animateReverse = false;
        this.$emit('uncomplete');
      }, 500);
    },
    doPulse() {},
    startPulsing() {
      this.isCompleted = false;
      this.animatePulse = true;
    },
  },
};
</script>
<style scoped lang="scss">
.checkmark-done {
  display: flex;
  align-items: center;
  .glow-circle {
    opacity: 1;
    transform-origin: center;
    stroke: $neutral-300;
    stroke-width: 2;
    fill: none;

    &.is-active {
      stroke: none;
      fill: $green-50;
    }
  }

  .dash-circle {
    transform-origin: center;

    &.is-active {
      stroke-width: 2;
      stroke-dasharray: 4 4;
      stroke: $green-600;
    }
  }

  path {
    fill: $neutral-400;

    &.is-active {
      fill: $green-600;
    }

    &.persist {
      opacity: 1;
    }
  }
}

.circle-fade-out {
  animation: circle-fade 500ms ease-in forwards;
  transform-origin: center;
}

.check-fade-in {
  animation: check-fade 500ms ease-in forwards;
  transform-origin: center;
}

.circle-fade-in {
  animation: circle-fade 500ms ease-in forwards reverse;
  transform-origin: center;
}

.check-fade-out {
  animation: check-fade 500ms ease-in forwards reverse;
  transform-origin: center;
}

.pulse {
  animation: pulse 2500ms ease-in-out infinite;
  transform-origin: center;
}

@keyframes pulse {
  0% {
    r: 10;
    transform: rotate(180deg);
  }
  100% {
    r: 10;
  }
}

@keyframes circle-fade {
  0% {
    r: 10;
  }
  50% {
    r: 3;
    stroke-width: 2;
    stroke-dasharray: 2;
    transform: rotate(180deg);
    fill-opacity: 0;
  }

  100% {
    r: 10;
    stroke-width: 0;
  }
}

@keyframes check-fade {
  0% {
    opacity: 0;
    transform: scale(0.5);
  }
  50% {
    opacity: 0;
    transform: scale(0.5);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}
</style>

<template>
  <div class="family-tree-actions-helper">
    <div class="action-helper">
      <img :src="$getAsset('/assets/icons/mouse_interactions/left_click_drag.png')" />
      <span>Pan</span>
    </div>
    <div class="action-helper">
      <img :src="$getAsset('/assets/icons/mouse_interactions/middle_mouse.png')" />
      <span>Zoom</span>
    </div>
    <div class="action-helper quick-actions">
      <img :src="$getAsset('/assets/icons/mouse_interactions/right_click.png')" />
      <span>Actions</span>
    </div>
    <div class="action-helper quick-actions">
      <img :src="$getAsset('/assets/icons/mouse_interactions/double_click_7.png')" />
      <span>Edit</span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'actionsHelper',
};
</script>

<style lang="scss" scoped>
.family-tree-actions-helper {
  display: flex;
  flex-direction: row;
  .action-helper {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-right: 18px;
    img {
      height: 24px;
      width: auto;
      margin-right: 6px;
      opacity: 0.5;
    }
    span {
      color: #999;
      font-size: 0.7em;
      text-transform: uppercase;
    }
  }
  @media only screen and (max-width: $main-menu-breakpoint) {
    display: none;
  }
}
</style>

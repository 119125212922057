<template>
  <div class="family-tree-lineage-switch">
    <router-link
      :to="familyTreeRoute"
      class="item"
      :class="familyTreeRoute.name === $route.name ? 'is-active' : ''"
      v-tooltip="treeTooltip"
      ><tree-icon :size="24" title=""
    /></router-link>

    <router-link
      :to="lineageRoute"
      class="item"
      :class="lineageRoute.name === $route.name ? 'is-active' : ''"
      v-tooltip="lineageTooltip"
      ><lineage-icon :size="24" title=""
    /></router-link>
  </div>
</template>

<script>
import LineageIcon from 'vue-material-design-icons/FormatListBulletedSquare';
import TreeIcon from 'vue-material-design-icons/Sitemap';
import {mapGetters} from 'vuex';

export default {
  computed: {
    ...mapGetters(['familyTreeStartPersonIdState', 'familyTreeDetailsIdState']),
    familyTreeRoute() {
      const query = this.familyTreeStartPersonIdState ? {start_person_id: this.familyTreeStartPersonIdState} : {};
      return {name: 'familytree-details', params: {id: this.familyTreeDetailsIdState}, query};
    },
    lineageRoute() {
      const query = this.familyTreeStartPersonIdState ? {start_person_id: this.familyTreeStartPersonIdState} : {};
      return {name: 'familytree-lineage', params: {id: this.familyTreeDetailsIdState}, query};
    },
    treeTooltip() {
      return {content: 'Tree View', placement: 'left'};
    },
    lineageTooltip() {
      return {content: 'Lineage View', placement: 'left'};
    },
  },
  components: {LineageIcon, TreeIcon},
  name: 'familyTreeLineageSwitch',
};
</script>

<style lang="scss" scoped></style>

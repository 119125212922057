<template>
  <div class="view-relationship-modal-content">
    <div class="heading">
      <div class="text-lg bold">View relationships of {{ personFirstName }}</div>
      <close-icon class="close-icon" @click="closeModal"></close-icon>
    </div>
    <div class="person-block">
      <div class="main-person">
        <div class="picture-container">
          <img :src="profilePictureAttachment" v-if="profilePictureAttachment" class="profile-picture" />
          <person-icon-colored v-else :gender="person.gender"></person-icon-colored>
        </div>
        <div class="person-data">
          <div class="text-lg bold">{{ personFullName }}</div>
          <div class="text-md dates">{{ livingStatus }}</div>
        </div>
      </div>
      <div class="icon-container">
        <arrow-left-right-icon class="desktop-only" :size="24"></arrow-left-right-icon>
        <arrow-up-down-icon class="mobile-only" :size="24"></arrow-up-down-icon>
      </div>
      <div class="other-person">
        <vue-content-loading :width="400" :height="50" v-if="treeLoading">
          <rect x="0" y="0" rx="4" ry="0" width="400" height="50" />
        </vue-content-loading>
        <multiselect
          v-else
          ref="personselect"
          placeholder="Search persons in family tree"
          class="people-multiselect bordered"
          :value="otherPerson"
          :loading="selectorLoading"
          :options="otherPersonOptions"
          selectLabel=""
          deselectLabel=""
          label="full_name"
          trackBy="object_id"
          @select="personSelect"
          @search-change="peopleSearch"
        >
          <template v-slot:caret="props">
            <div class="caret-icon" @mousedown.prevent.stop="props.toggle()" v-if="!selectorLoading">
              <account-icon></account-icon>
            </div>
          </template>
          <template v-slot:noOptions>
            <span>Start typing to search...</span>
          </template>
          <template v-slot:noResult>
            <span>No person found.</span>
          </template>
          <template v-slot:afterList>
            <div class="loading-after-list" v-if="selectorLoading">
              <span>Searching...</span>
            </div>
          </template>
        </multiselect>
      </div>
    </div>
    <div class="relationship-display">
      <div class="text-ld">{{ relationshipDisplay }}</div>
    </div>
  </div>
</template>

<script>
import {getLifetimeYears} from '@common/utils/utils';
import debounce from 'lodash/debounce';
import {VueContentLoading} from 'vue-content-loading';
import AccountIcon from 'vue-material-design-icons/Account';
import ArrowLeftRightIcon from 'vue-material-design-icons/ArrowLeftRight';
import ArrowUpDownIcon from 'vue-material-design-icons/ArrowUpDown';
import CloseIcon from 'vue-material-design-icons/Close';
import Multiselect from 'vue-multiselect';

import PersonIconColored from '@/base/icons/person/PersonIconColored.vue';

import {UNKNOWN_NAME} from '@/components/modules/familyTree/constants';

export default {
  props: {
    person: Object,
  },
  data() {
    return {
      treeLoading: true,
      treeData: {},
      otherPerson: null,
      otherPersonOptions: [],
      selectorLoading: false,
    };
  },
  created() {
    setTimeout(() => {
      this.init();
    }, 1000);
  },
  computed: {
    personFirstName() {
      return this.person.first_names && this.person.first_names[0] && this.person.first_names[0].value
        ? this.person.first_names[0].value
        : UNKNOWN_NAME;
    },
    personFullName() {
      return this.person.full_name || UNKNOWN_NAME;
    },
    profilePicture() {
      return this.person && this.person.profile_picture;
    },
    profilePictureAttachment() {
      return this.profilePicture && this.profilePicture.attachment;
    },
    livingStatus() {
      return getLifetimeYears(this.person.birth_date, this.person.death_date, this.person.is_deceased);
    },
    relationshipDisplay() {
      if (!this.relationshipToOtherPerson) {
        return;
      }
      const otherPersonName = this.otherPerson.full_name;
      if (this.relationshipToOtherPerson.spouse_of) {
        return `${otherPersonName} is s spouse of ${this.personFullName}'s ${this.relationshipToOtherPerson.spouse_of}`;
      }
      return `${otherPersonName} is ${this.personFullName}'s ${this.relationshipToOtherPerson.relation}`;
    },
    relationshipToOtherPerson() {
      return this.otherPerson && this.otherPerson.object_id ? this.treeData[this.otherPerson.object_id] : null;
    },
  },
  methods: {
    init() {
      this.network.familyTree
        .searchRelationNames({id: this.person.family_tree_id, start_person_id: this.person.object_id})
        .then(response => {
          this.treeData = response;
          setTimeout(() => {
            this.$refs.personselect.activate();
          }, 50);
        })
        .finally(() => {
          this.treeLoading = false;
        });
    },
    personSelect(person) {
      this.otherPerson = person;
    },
    peopleSearch(query) {
      this.otherPersonOptions = [];
      this.selectorLoading = true;
      this.fetchPeopleSearch(query);
    },
    fetchPeopleSearch: debounce(function (query) {
      if (query) {
        this.$store
          .dispatch('searchPersonsInFamilyTreeAction', {familyTreeId: this.person.family_tree_id, q: query})
          .then(response => {
            this.otherPersonOptions = response.objects.filter(
              obj => !!this.treeData[obj.object_id] && obj.object_id !== this.person.object_id
            );
          })
          .finally(() => {
            this.selectorLoading = false;
          });
      } else {
        this.otherPersonOptions = [];
        this.selectorLoading = false;
      }
    }, 500),
    closeModal() {
      this.$emit('close');
    },
  },
  components: {
    PersonIconColored,
    CloseIcon,
    ArrowLeftRightIcon,
    ArrowUpDownIcon,
    VueContentLoading,
    Multiselect,
    AccountIcon,
  },
  name: 'ViewRelationshipModalContent',
};
</script>

<style scoped lang="scss">
.view-relationship-modal-content {
  .person-block {
    padding: 16px 24px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    .main-person {
      display: flex;
      flex-direction: row;
      align-items: center;
      column-gap: 12px;

      .picture-container {
        width: 56px;
        height: 56px;
        flex-shrink: 0;
        cursor: pointer;
        border-radius: 4px;
        overflow: hidden;
        &::v-deep svg {
          width: 40px;
          height: 40px;
        }
      }
      .person-data {
        display: flex;
        flex-direction: column;
        .dates {
          color: $neutral-500;
        }
      }
    }
    .icon-container {
      display: flex;
      color: $neutral-500;
    }
    .other-person {
      min-height: 44px;
      width: 40%;
      display: flex;
      align-items: center;
      justify-content: stretch;
    }
  }

  .relationship-display {
    border-top: 1px solid $neutral-200;
    padding: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: $neutral-600;
    font-style: italic;
    text-align: center;
    min-height: 24px;
  }
}
@media only screen and (min-width: $breakpoint-mobile) {
  .view-relationship-modal-content {
    min-width: 720px;
    .mobile-only {
      display: none;
    }
  }
}
@media only screen and (max-width: $breakpoint-mobile) {
  .view-relationship-modal-content {
    .mobile-only {
      display: flex;
    }
    .desktop-only {
      display: none;
    }

    .person-block {
      flex-direction: column;
      align-items: flex-start;
      justify-content: stretch;
      row-gap: 16px;
      .icon-container {
        margin: auto;
      }
      .other-person {
        width: 100%;
      }
    }
  }
}
</style>

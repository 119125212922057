<template>
  <div class="upload-meta">{{ uploadMeta }}</div>
</template>

<script>
import moment from 'moment';

export default {
  props: {
    asset: Object,
  },
  computed: {
    uploadMeta() {
      return this.asset
        ? `Uploaded by ${this.asset.creator} on ${moment(this.asset.created_at).format('MMMM DD, YYYY')}`
        : '';
    },
  },
  name: 'AssetUploadMeta',
};
</script>

<style lang="scss" scoped>
.upload-meta {
  margin-top: auto;
  color: $dark-color-contrast-2;
  font-size: 0.8em;
}
</style>

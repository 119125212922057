var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"checkmark-done"},[_c('svg',{attrs:{"width":"28","height":"28","viewBox":"0 0 28 28","fill":"none","xmlns":"http://www.w3.org/2000/svg"}},[(!_vm.isCompleted || _vm.animate || _vm.animateReverse)?_c('rect',{staticClass:"glow-circle",class:{'is-active': _vm.isActive},attrs:{"x":"1","y":"1","width":"26","height":"26","rx":"13"}}):_vm._e(),(!_vm.isCompleted || _vm.animate || _vm.animateReverse)?_c('circle',{staticClass:"dash-circle",class:{
        'circle-fade-out': _vm.animate,
        'circle-fade-in': _vm.animateReverse,
        pulse: _vm.animatePulse,
        'is-active': _vm.isActive,
      },attrs:{"cx":"14","cy":"14","r":"10"}}):_vm._e(),(_vm.isCompleted || _vm.animate || _vm.animateReverse)?_c('svg',{attrs:{"viewBox":"0 0 24 24","height":"28","width":"28"}},[_c('path',{class:{
          'check-fade-in': _vm.animate,
          persist: _vm.isCompleted,
          'check-fade-out': _vm.animateReverse,
          'is-active': _vm.isActive,
          'is-done': _vm.isCompleted,
        },attrs:{"opacity":"0","d":"M12 2C6.5 2 2 6.5 2 12S6.5 22 12 22 22 17.5 22 12 17.5 2 12 2M10 17L5 12L6.41 10.59L10 14.17L17.59 6.58L19 8L10 17Z"}})]):_vm._e()])])}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <div>
    <div class="readable_content">
      <h1>Something went wrong...</h1>
      <p>
        Many apologies! Something went wrong on the page you are trying to reach. Our team has been informed and we will
        strive to fix it as soon as possible!
      </p>
      <p>
        Please feel free to contact us at <a href="mailto: support@mychinaroots.com">support@mychinaroots.com</a> if you
        have more details on the problem for us, or you need further assistance.
      </p>
      <hr />
      <p>
        <b><router-link to="/">&larr; Back to My China Roots</router-link></b>
      </p>
    </div>
  </div>
</template>

<script>
export default {
  metaInfo: {
    title: 'Error',
    meta: [
      {
        vmid: 'robots',
        name: 'robots',
        content: 'noindex,nofollow',
      },
    ],
  },
};
</script>

<style lang="scss" scoped></style>

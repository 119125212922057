<template>
  <div class="multiple-choice-select">
    <div v-for="option in options" :key="option.id" class="multiple-choice-select-option">
      <input type="checkbox" v-model="model" :id="getId(option)" :value="option.id" />
      <label :for="getId(option)">{{ option.text }}</label>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    checked: {
      type: Array,
      default: () => {
        return [];
      },
    },
    name: {type: String, default: 'multiple-choice-select'},
    options: Array,
  },
  watch: {
    model(newValue) {
      this.$emit('change', newValue);
    },
  },
  data() {
    return {
      model: [...this.checked],
    };
  },
  methods: {
    getId(option) {
      return `${this.name}-${option.id}`;
    },
  },
};
</script>

<style scoped lang="scss">
.multiple-choice-select-option {
  display: flex;
  align-items: center;
  &:not(:last-child) {
    margin-bottom: 6px;
  }
}
</style>

<template>
  <delete-confirm-modal-content :loading="loading" @confirm="deleteFunction" @close="close">
    <h5>Are you sure you want to remove <span>this record</span>?</h5>
    <p>You may re-add the record to your collection again at a later date.</p>
  </delete-confirm-modal-content>
</template>

<script>
import {mapGetters} from 'vuex';

import DeleteConfirmModalContent from '@/base/elements/modals/deleteConfirmModalContent';

export default {
  props: {
    itemId: [String, Number],
    zupuId: String,
    treeId: [String, Number],
    clanName: String,
    refetchList: Function,
  },
  computed: {
    ...mapGetters(['clanPedigreeDetailsState']),
    loading() {
      return this.$store.getters.savedClanPedigreeDeleteLoadingState;
    },
  },
  methods: {
    deleteFunction() {
      this.$store.dispatch('deleteSavedClanPedigreeAction', this.itemId).then(() => {
        if (this.clanPedigreeDetailsState && this.clanPedigreeDetailsState.object_id === this.zupuId) {
          this.$store.commit('setClanPedigreeDetailsState', null);
        }
        if (this.refetchList) {
          this.refetchList();
        }
        this.close();
      });
    },
    close() {
      this.$emit('close');
    },
  },
  components: {DeleteConfirmModalContent},
};
</script>

<style lang="scss" scoped>
h3 span {
  color: $active-color;
}
</style>

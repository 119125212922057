<template>
  <transition name="fade" v-if="treeUsageTutorialState" mode="out-in">
    <div class="overlay-tutorial-animated no-select" v-if="treeUsageTutorialState.showDrag" key="drag">
      <mouse-drag-animation></mouse-drag-animation>
      <div class="label text-md">Drag to pan</div>
    </div>
    <div
      class="overlay-tutorial-animated no-select"
      v-else-if="treeUsageTutorialState.showZoom && !isTouchDevice"
      key="zoom"
    >
      <mouse-scroll-animation></mouse-scroll-animation>
      <div class="label text-md">Scroll to zoom</div>
    </div>
    <div
      class="overlay-tutorial-animated no-select"
      v-else-if="treeUsageTutorialState.showZoom && isTouchDevice"
      key="zoom-pinch"
    >
      <pinch-zoom-animation></pinch-zoom-animation>
      <div class="label text-md">Pinch to zoom</div>
    </div>
  </transition>
</template>

<script>
import MouseDragAnimation from '@common/elements/animations/MouseDragAnimation';
import MouseScrollAnimation from '@common/elements/animations/MouseScrollAnimation';
import PinchZoomAnimation from '@common/elements/animations/PinchZoomAnimation';
import {mapGetters} from 'vuex';

export default {
  mounted() {
    this.isTouchDevice = 'ontouchstart' in window || navigator.msMaxTouchPoints > 0 || navigator.maxTouchPoints > 0;
  },
  data() {
    return {
      isTouchDevice: false,
    };
  },
  computed: {
    ...mapGetters(['treeUsageTutorialState']),
  },
  components: {MouseDragAnimation, MouseScrollAnimation, PinchZoomAnimation},
  name: 'OverlayTutorialAnimated',
};
</script>

<style lang="scss" scoped>
.overlay-tutorial-animated {
  position: absolute;
  z-index: 20;
  width: 168px;
  height: 168px;
  background: rgba(0, 0, 0, 0.58);
  border-radius: 99px;
  top: 0;
  bottom: $main-menu-height;
  right: 0;
  left: 0;
  margin: auto;

  .animation {
    position: absolute;
    right: 0;
    left: 0;
    margin: auto;
  }

  .mouse-drag-animation {
    top: 36px;
    width: 80px;
  }

  .mouse-scroll-animation {
    top: 36px;
    width: 50px;
  }

  .pinch-zoom-animation {
    top: 26px;
    width: 90px;
  }

  .label {
    position: absolute;
    right: 0;
    left: 0;
    text-align: center;
    color: white;
    font-weight: 500;
    bottom: 31px;
  }
}
</style>

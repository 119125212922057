<template>
  <div class="drag-prompt" v-show="showDropzone" @dragover.prevent @drop.prevent="handleDrop">
    <upload-icon :size="56" class="upload-icon"></upload-icon>
    <div class="label">Drop files here to upload</div>
  </div>
</template>

<script>
import UploadIcon from 'vue-material-design-icons/Upload';

export default {
  created() {
    window.addEventListener('dragenter', this.handleWindowDragEnter);
    window.addEventListener('dragover', this.handleWindowDragOver);
    window.addEventListener('dragleave', this.handleWindowDragLeave);
    window.addEventListener('drop', this.handleWindowDrop);
  },
  beforeDestroy() {
    window.removeEventListener('dragenter', this.handleWindowDragEnter);
    window.removeEventListener('dragover', this.handleWindowDragOver);
    window.removeEventListener('dragleave', this.handleWindowDragLeave);
    window.removeEventListener('drop', this.handleWindowDrop);
  },
  data() {
    return {
      showDropzone: false,
      dragCounter: 0,
    };
  },
  methods: {
    handleWindowDragEnter(event) {
      event.preventDefault();
      this.showDropzone = true;
      this.dragCounter++;
    },
    handleWindowDragOver(event) {
      event.preventDefault();
    },
    handleWindowDragLeave(event) {
      this.dragCounter--;
      if (this.dragCounter === 0) {
        this.showDropzone = false;
      }
    },
    handleWindowDrop(event) {
      event.preventDefault();
      this.showDropzone = false;
      this.dragCounter = 0;
    },
    handleDrop(event) {
      const files = event.dataTransfer.files;
      this.$emit('drop', files);
      this.showDropzone = false;
      this.dragCounter = 0;
    },
  },
  components: {UploadIcon},
  name: 'DragDropPrompt',
};
</script>

<style lang="scss" scoped>
.drag-prompt {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(white, 0.9);
  border: 1px dashed $neutral-300;
  display: flex;
  flex-direction: column;
  row-gap: 8px;
  align-items: center;
  justify-content: center;
  z-index: 2;

  .label {
    color: $neutral-600;
  }

  .upload-icon {
    color: $neutral-400;
  }
}
</style>

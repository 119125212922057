<template>
  <div class="name-select">
    <multiselect
      class="bordered"
      :value="valueObj"
      placeholder=""
      :searchable="false"
      :internal-search="false"
      :preserveSearch="false"
      :clearOnSelect="false"
      :show-no-options="true"
      :hide-selected="false"
      :options="options"
      track-by="key"
      label="value"
      :showLabels="false"
      @select="onSelect"
    >
    </multiselect>
  </div>
</template>

<script>
import Multiselect from 'vue-multiselect';

export default {
  props: {
    value: String,
    options: Array,
  },
  computed: {
    valueObj() {
      return this.options.find(option => option.key === this.value);
    },
  },
  methods: {
    onSelect(value) {
      this.$emit('select', value.key);
    },
  },
  components: {Multiselect},
  name: 'NameTypeSelect',
};
</script>

<style lang="scss" scoped>
.name-select {
  &::v-deep .multiselect__tags {
    cursor: pointer;
  }
}
</style>

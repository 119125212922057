<template>
  <div class="family-tree-location-multiselect-container">
    <place-multiselect
      v-bind="$attrs"
      :ref="ref"
      :value="location"
      :options="options"
      :loading="loading"
      :search-query="searchQuery"
      :always-show-value="true"
      :placeholder="placeholder"
      :class="[multiselectClasses, classes]"
      label="display_text"
      track-by="display_text"
      @open="open"
      @clear="clear"
      @select="select"
      @search-change="onSearchChange"
      @close="close"
      class="location-multiselect"
    >
    </place-multiselect>
    <div class="input-warning" v-if="hasIdWarning">
      <alert-icon :size="16" /><span>Not a linked place.</span
      ><mcr-wiki-glossary-link naturalId="linked-places" text="Learn more" />
    </div>
  </div>
</template>

<script>
import McrWikiGlossaryLink from '@common/elements/glossary/mcrWikiGlossaryLink';
import PlaceMultiselect from '@common/elements/inputs/PlaceMultiselect.vue';
import debounce from 'lodash/debounce';
import uniqBy from 'lodash/uniqBy';
import AlertIcon from 'vue-material-design-icons/AlertOutline';
import {mapGetters} from 'vuex';

export default {
  inheritAttrs: false,
  props: {
    location: Object, // MultiPlace object {display_text, place_id, place_historical_id}
    familyTreeId: [String, Number],
    multiselectClasses: {type: String, default: ''},
    showIdWarning: {type: Boolean, default: false},
    placeholder: {type: String, default: ''},
  },
  data() {
    return {
      ref: 'multiselect',
      options: [],
      defaultOptions: [],
      loading: false,
      searchQuery: '',
    };
  },
  computed: {
    ...mapGetters([
      'placeAlternativesLoadingState',
      'familyTreeMostTaggedLocationsState',
      'familyTreeMostTaggedLocationsLoadingState',
      'windowWidthState',
    ]),
    hasIdWarning() {
      return this.showIdWarning && this.location && this.location.display_text && !this.location.place_id;
    },
    classes() {
      return this.hasIdWarning ? 'has-warning' : '';
    },
  },
  methods: {
    autofocus() {
      this.$refs[this.ref].autofocus();
    },
    open() {
      if (this.location && this.location.display_text) {
        this.options = [];
      } else {
        this.getMostTaggedLocations();
      }
    },
    select(item) {
      const {display_text, place_historical_id, place_id, level} = item;
      this.$emit('select', {display_text, place_historical_id, place_id, level});
      this.defaultOptions = [];
    },
    onSearchChange(searchQuery) {
      this.options = [];
      this.searchQuery = searchQuery;
      if (this.location && searchQuery === this.location.display_text) {
        return;
      }
      if (!this.$refs[this.ref].isMultiselectOpened()) {
        return;
      }
      if (!searchQuery) {
        return this.getMostTaggedLocations();
      }
      this.loading = true;
      this.search(searchQuery);
    },
    search: debounce(function (searchQuery) {
      const searchOption =
        this.location && this.location.display_text === searchQuery
          ? this.location
          : {
              display_text: searchQuery,
              isAddCustom: true,
              place_historical_id: null,
              place_id: null,
              customOptionLabel: `+ Add "${searchQuery}"`,
            };

      const placeAlternatives = this.$store.dispatch('searchPlaceAlternativesAction', {
        query: searchQuery,
        includeHistoricalPlaces: true,
      });
      const previouslyUsed = this.familyTreeId
        ? this.$store.dispatch('searchMostTaggedLocationsInFamilyTreeAction', {
            familyTreeId: this.familyTreeId,
            q: searchQuery,
          })
        : Promise.resolve();

      Promise.all([placeAlternatives, previouslyUsed])
        .then(() => {
          if (!this.searchQuery) {
            return;
          }
          let options = [
            ...this.$store.getters.familyTreeMostTaggedLocationsState,
            ...this.$store.getters.placeAlternativesState,
          ];
          options = uniqBy(options, 'display_text');
          const searchQueryInOptions = options.find(
            option => option.display_text.toLowerCase() === searchQuery.toLowerCase()
          );
          this.options = searchQueryInOptions ? options : [...options, searchOption];
        })
        .finally(() => {
          this.loading = false;
        });
    }, 500),
    clear() {
      this.$emit('clear');
      this.defaultOptions = [];
      this.loading = false;
    },
    close() {
      this.options = [];
      this.isMultiselectOpened = false;
    },
    getMostTaggedLocations() {
      if (!this.familyTreeId) {
        return;
      }
      if (this.defaultOptions.length) {
        this.options = this.defaultOptions;
        if (!this.searchQuery) {
          this.loading = false;
        }
        return;
      }
      this.$store
        .dispatch('searchMostTaggedLocationsInFamilyTreeAction', {familyTreeId: this.familyTreeId})
        .then(() => {
          this.defaultOptions = this.$store.getters.familyTreeMostTaggedLocationsState;
          this.options = this.$store.getters.familyTreeMostTaggedLocationsState;
          if (!this.searchQuery) {
            this.loading = false;
          }
        });
    },
  },
  components: {PlaceMultiselect, AlertIcon, McrWikiGlossaryLink},
};
</script>

<style lang="scss" scoped></style>

<template>
  <div class="cards-language-switch" @click="toggleLanguage" v-tooltip="tooltip">
    <translate-icon class="item" :class="{'is-active': familyTreeIsLangChineseState}" :size="24" title="" />
  </div>
</template>

<script>
import TranslateIcon from 'vue-material-design-icons/Translate';
import {mapGetters} from 'vuex';

export default {
  computed: {
    ...mapGetters(['familyTreeIsLangChineseState']),
    tooltip() {
      return {content: 'Show CN or EN names', placement: 'left'};
    },
  },
  methods: {
    toggleLanguage() {
      const value = !this.familyTreeIsLangChineseState;
      const toCn = 'Showing Chinese names by default';
      const toEn = 'Showing English names by default';
      const toastText = value ? toCn : toEn;

      this.$store.commit('setFamilyTreeIsLangChineseState', value);
      this.$toasted.success(toastText);
    },
  },
  components: {TranslateIcon},
  name: 'cardsLanguageSwitch',
};
</script>

<style lang="scss" scoped></style>

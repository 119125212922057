<template>
  <div class="quick-view-menu-sub-action text-md">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'QuickViewMenuSubAction',
};
</script>

<style scoped lang="scss">
.quick-view-menu-sub-action {
  height: 48px;
  width: 208px;
  padding: 0 12px;
  display: flex;
  align-items: center;
  cursor: pointer;
  color: $neutral-800;

  &::v-deep .material-design-icon {
    margin-right: 12px;
  }

  &::v-deep .hotkey {
    margin-left: auto;
    color: $neutral-600;
  }

  &:hover {
    color: $primary-600;
    background: $primary-50;
  }
}
</style>

import ExtractTextInterstitial from '@/components/common/tree/ExtractTextInterstitial.vue';

import personProfilePictureCropModalContentContainer from '@/components/modules/familyTree/tree/gallery/personProfilePictureCropModalContentContainer.vue';

export default {
  data() {
    return {
      addPhotosInitiator: 'personQuickView',
      profilePictureProcessing: false,
      cropperInitialImage: {},
    };
  },
  computed: {
    showProfilePictureLoading() {
      return this.$store.getters.familyTreePersonProfilePictureLoadingState || this.profilePictureProcessing;
    },
  },
  methods: {
    personIconClick() {
      this.$refs.photos.addPhotos(this.addPhotosInitiator);
    },
    async profilePictureClick() {
      let photo = this.$refs.photos.galleryOverlayItems.find(item => item.object_id === this.profilePicture.asset_id);
      if (photo) {
        return this.$refs.photos.showGallery(photo);
      }
      /* if profile picture is not in photos section, get and cache the full attachment url */
      let fullAttachment = this.profilePicture.fullAttachment;
      if (!fullAttachment) {
        const asset = await this.$store.dispatch('getAssetDetailsAction', {assetId: this.profilePicture.asset_id});
        fullAttachment = asset.attachment;
        const profilePictureData = {...this.profilePicture, fullAttachment: asset.attachment};
        this.$store.commit('mutateFamilyTreePersonsByIdState', {
          id: this.person.id,
          profile_picture: profilePictureData,
        });
      }
      const profilePicture = {
        object_id: this.profilePicture.asset_id,
        src: this.profilePicture.fullAttachment,
        _id: this.profilePicture.asset_id,
        ...this.$refs.photos.photoMetadata,
      };
      this.$store.commit('setGalleryItemsState', [profilePicture]);
      this.$store.dispatch('displayGalleryAction', profilePicture._id);
    },
    filesSelected({initiator}) {
      if (
        initiator === this.addPhotosInitiator ||
        (!this.$refs.photos.photos.length && !this.profilePictureAttachment)
      ) {
        this.profilePictureProcessing = true;
      }
    },
    fileUploaded(asset) {
      if (!this.cropperInitialImage.attachment && this.profilePictureProcessing) {
        this.cropperInitialImage = asset;
        const props = {
          src: this.cropperInitialImage.attachment,
          personId: this.person.object_id,
          assetId: this.cropperInitialImage.object_id,
        };
        const modalParams = {
          classes: 'clear_modal white_modal',
          height: 'auto',
          scrollable: true,
          name: personProfilePictureCropModalContentContainer.modalName,
        };
        const events = {
          closed: () => this.openTextExtractTutorialModal(asset.show_text_extract_tutorial),
        };
        this.$modal.show(personProfilePictureCropModalContentContainer, props, modalParams, events);
        this.profilePictureProcessing = false;
      } else {
        this.openTextExtractTutorialModal(asset.show_text_extract_tutorial);
      }
    },
    openTextExtractTutorialModal(show) {
      if (show) {
        this.$modal.show(
          ExtractTextInterstitial,
          {},
          {
            clickToClose: true,
            scrollable: true,
            height: 'auto',
            classes: 'clear_modal white_modal',
            class: 'mobile_bottom',
          }
        );
      }
    },
  },
};

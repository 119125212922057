import {getNetworkAPIZupuImageThumbnailURL} from '@common/network/network.utils';
import {getAsset} from '@common/utils/utils';

const CLAN_PEDIGREE_PREVIEW_WIDTH = 200;

export function getClanPedigreePreviewSrc(coverId, height = null, width = null) {
  if (coverId) {
    return getNetworkAPIZupuImageThumbnailURL(coverId, height, width || CLAN_PEDIGREE_PREVIEW_WIDTH);
  }
  return getAsset('/assets/mcr_logo_white_transparent.png');
}

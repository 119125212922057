<template>
  <div class="about-zupus text-md text-lg-mobile">
    <div class="bold whatszupu">What's a Zupu?</div>
    <div class="text">The zupu, jiapu, or family tree book, is the Holy Grail of Chinese genealogy.</div>
    <div class="text">
      They often contain family trees, migration stories, biographies of famous ancestors, even guidelines for naming
      each generation of children.
    </div>
    <div class="action-links">
      <div>
        <router-link :to="wikiZupuRoute" class="bold">Learn more about Zupus (Family Tree Books)</router-link>
      </div>
      <div>
        <slot name="search-link"></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    wikiZupuRoute() {
      return {name: 'wiki-article', params: {slug: 'zupu'}};
    },
  },
  name: 'AboutZupus',
};
</script>

<style scoped lang="scss">
.about-zupus {
  background: $background-light;
  border-radius: 4px;
  padding: 16px 24px 20px 24px;
  .text {
    margin-top: 8px;
  }
  .action-links {
    display: flex;
    flex-direction: column;
    margin-top: 16px;
    row-gap: 12px;
    > div {
      display: flex;
    }

    &::v-deep .db-logo {
      vertical-align: sub;
      height: 20px;
      margin-left: 5px;
    }
  }
}
@media only screen and (max-width: $breakpoint-phablet) {
  .about-zupus {
    padding: 16px;
  }
}
</style>

<template>
  <div class="tab-details tab-content">
    <person-edit-form-block v-if="editMode" @end-edit-mode="setEditMode(false)" ref="editForm"></person-edit-form-block>
    <person-details-block v-else @start-edit-mode="startEditModeWithScroll"></person-details-block>
  </div>
</template>

<script>
import {mapGetters} from 'vuex';

import PersonDetailsBlock from './components/PersonDetailsBlock';
import PersonEditFormBlock from './components/PersonEditFormBlock';

export default {
  data() {
    return {
      editMode: Boolean(this.$route.query.edit) && this.$store.getters.familyTreePersonState.is_write_allowed,
    };
  },
  mounted() {
    if (this.editMode) {
      this.$nextTick(() => {
        if (this.$route.query.focus && this.$route.query.focus === 'birth') {
          const scrollId = this.$refs.editForm.$refs.form.getCommonSectionId('birth');
          this.$refs.editForm.$refs.form.scrollToSection(scrollId);
          const birthplace = this.$refs.editForm.$refs.form.$refs.birthplace;
          birthplace.autofocus();
        } else {
          this.$scrollTo('.person-edit-form-block', {offset: -120});
        }
      });
    }
  },
  computed: {
    ...mapGetters([
      'familyTreePersonState',
      'familyTreePersonPrimaryFullnameEnState',
      'familyTreePersonPrimaryFullnameCnState',
      'reviewedItemsState',
    ]),
  },
  methods: {
    startEditModeWithScroll(data) {
      this.setEditMode(true);
      if (data.scrollId) {
        this.$nextTick(() => {
          this.$refs.editForm.$refs.form.scrollToSection(data.scrollId);
          if (data.focus && data.focus === 'birth') {
            this.$refs.editForm.$refs.form.$refs.birthplace.autofocus();
          }
        });
      }
    },
    setEditMode(bool) {
      this.editMode = bool;
    },
    activate() {},
    deactivate() {},
  },
  components: {PersonEditFormBlock, PersonDetailsBlock},
  name: 'TabDetails',
};
</script>

<style lang="scss" scoped></style>

<template>
  <div class="create-tree-page">
    <div class="readable_content">
      <router-link :to="backRoute" class="back-link">{{ backRouteLabel }}</router-link>
      <div class="main-heading">
        <h4>Create New Tree</h4>
      </div>
      <hr />
      <div class="option option-me">
        <div>
          <input id="me" type="radio" name="start_person" :value="valueMe" v-model="startPersonRadio" />
          <label for="me">Start with me</label>
        </div>
        <div class="disclaimer">Create a new family tree with your profile as the starting person</div>
      </div>
      <hr />
      <div class="option option-start-person">
        <div>
          <input id="person" type="radio" name="start_person" :value="valuePerson" v-model="startPersonRadio" />
          <label for="person">Start with another person</label>
        </div>
        <div class="form" :class="{'no-select': startPersonRadio !== valuePerson}">
          <person-quick-form
            ref="form"
            :autofocus-on-mount="false"
            :form-initial-data="{gender: null}"
            :gender-options="genders"
            @keyup.enter.native="createTree"
          ></person-quick-form>
        </div>
      </div>
      <hr />
      <div class="action-buttons">
        <mcr-button class="save-button" :loading="createLoading" @click="createTree">Create Tree</mcr-button>
      </div>
    </div>
  </div>
</template>

<script>
import McrButton from '@common/elements/buttons/mcrButton';

import PersonQuickForm from '@/components/modules/familyTree/tree/modals/quick/PersonQuickForm';

export default {
  metaInfo() {
    return {
      title: 'Create New Tree',
    };
  },
  data() {
    return {
      valueMe: 'me',
      valuePerson: 'person',
      startPersonRadio: 'me',
      createLoading: false,
    };
  },
  computed: {
    backRoute() {
      return {name: 'familytree-manage-all'};
    },
    backRouteLabel() {
      return 'Back to Manage Trees';
    },
    genders() {
      return {f: 'Female', m: 'Male', null: 'Unknown'};
    },
  },
  methods: {
    createTree() {
      this.createLoading = true;
      const personData = this.startPersonRadio === this.valuePerson ? this.$refs.form.getPersonForSave() : null;
      this.$store
        .dispatch('createFamilyTreeAction', {start_person: personData})
        .then(response => {
          this.$router.push({name: 'familytree-details', params: {id: response.id}});
        })
        .catch(error => {
          this.createLoading = false;
          this.$toasted.error('Something went wrong during tree creation. Please try again later.');
        });
    },
  },

  components: {McrButton, PersonQuickForm},
  name: 'CreateTreePage',
};
</script>

<style lang="scss" scoped>
.create-tree-page {
  .readable_content {
    max-width: $form-max-width;
  }
  .back-link:before {
    content: '\2039';
    margin-right: 5px;
  }
  .main-heading {
    margin: 20px 0;
  }

  hr {
    margin: 20px 0;
  }

  .option {
    label {
      margin-left: 5px;
      font-weight: bold;
      cursor: pointer;
    }
    input[type='radio'] {
      cursor: pointer;
    }
    .disclaimer {
      color: $supplemental-text-color;
      margin: 5px 0 0 20px;
    }
    .form {
      &.no-select {
        opacity: 0.5;
      }
    }
  }
}
</style>

<template>
  <div class="edit-picture">
    <div class="label">Picture</div>
    <div class="field">
      <div class="image-container" @click="imageClick" v-if="croppedImageSrc">
        <div class="loading-container" v-if="loading">
          <bounce-loading></bounce-loading>
        </div>
        <div class="icon-container" v-else>
          <div>Crop</div>
          <crop-icon :size="50" class="crop-icon"></crop-icon>
        </div>
        <img :src="croppedImageSrc" class="profile-picture" />
      </div>
      <a @click="uploadPictureClick" class="upload-new">Upload New</a>
    </div>
    <input type="file" class="file-input" ref="fileinput" @change="filesSelectedHandler" />
  </div>
</template>

<script>
import BounceLoading from '@common/elements/loading-indicators/bounceLoading';
import network from '@common/network/network';
import consts from '@common/utils/consts';
import CropIcon from 'vue-material-design-icons/CropFree';

import PersonProfilePictureCrop from '@/components/common/tree/personProfilePictureCrop';

export default {
  props: {
    profilePicture: {type: Object},
  },
  data() {
    return {
      selectedImageFile: null,
      croppedImageBlob: null,
      loading: false,
      maxFileSizeMB: consts.maxFileSizeMB,
      maxFileSize: consts.maxFileSizeMB * 1024 * 1024,
      allowedFileExtensions: consts.imageExtensions,
    };
  },
  computed: {
    croppedImageSrc() {
      return this.croppedImageBlob
        ? URL.createObjectURL(this.croppedImageBlob)
        : this.profilePicture
        ? this.profilePicture.attachment
        : null;
    },
  },
  methods: {
    uploadPictureClick() {
      this.$refs.fileinput.click();
    },
    filesSelectedHandler(event) {
      this.filesSelected(event.target.files);
    },
    filesSelected(files) {
      const file = files[0];
      if (file.size > this.maxFileSize) {
        return this.handleFilesSelectError(`File max size: ${this.maxFileSizeMB}MB`);
      }
      const fileExtension = file.name.split('.').pop();
      if (!this.allowedFileExtensions.includes(fileExtension.toLowerCase())) {
        return this.handleFilesSelectError(`Allowed file extensions: ${this.allowedFileExtensions}`);
      }

      this.selectedImageFile = file;
      this.reCrop(URL.createObjectURL(this.selectedImageFile));
    },
    clearInputFiles() {
      this.$refs.fileinput.value = '';
    },
    handleFilesSelectError(errorText) {
      this.clearInputFiles();
      this.$toasted.error(errorText);
    },
    async imageClick() {
      if (this.loading) {
        return;
      }
      this.loading = true;
      let imageUrl = this.selectedImageFile ? URL.createObjectURL(this.selectedImageFile) : null;
      if (!this.selectedImageFile && this.profilePicture && this.profilePicture.asset_id) {
        const response = await network.familyTreeLibrary.getAssetUrl({id: this.profilePicture.asset_id});
        imageUrl = response.url;
      }
      this.reCrop(imageUrl);
      this.loading = false;
    },
    reCrop(imageUrl) {
      const props = {
        initialImageSrc: imageUrl,
        onCrop: blob => {
          this.croppedImageBlob = blob;
          this.$modal.hide('crop');
        },
      };
      const modalParams = {classes: 'clear_modal white_modal', name: 'crop', height: 'auto', scrollable: true};
      this.$modal.show(PersonProfilePictureCrop, props, modalParams);
    },
    getDataForSave() {
      if (!this.croppedImageBlob) {
        return {};
      }
      if (this.selectedImageFile) {
        return {file: this.selectedImageFile, blob: this.croppedImageBlob};
      }
      return {asset_id: this.profilePicture.asset_id, blob: this.croppedImageBlob};
    },
  },
  components: {BounceLoading, CropIcon},
  name: 'EditPicture',
};
</script>

<style lang="scss" scoped>
.edit-picture {
  display: flex;
  .label {
    color: $neutral-600;
    font-weight: 500;
    margin-right: 20px;
    flex-shrink: 0;
  }
  .field {
    flex-direction: row;
  }
  .image-container {
    position: relative;
    width: 100px;
    height: 100px;
    cursor: pointer;
    margin-bottom: 10px;

    .icon-container {
      display: none;
    }
    .loading-container {
      display: flex;
    }
    .icon-container,
    .loading-container {
      background: rgba(black, 0.5);
      color: $off-white;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 1;

      align-items: center;
      justify-content: center;
      flex-direction: column;
    }

    .profile-picture {
      width: 100px;
      height: 100px;
    }

    &:hover {
      .icon-container {
        display: flex;
      }
    }
  }
  .file-input {
    display: none;
  }
}
</style>

<template>
  <div class="lineage-card">
    <div class="generation-number bold">{{ generationNumber }}</div>

    <div class="data-wrapper" @click="dataWrapperClick">
      <img class="image" :src="imageSrc" v-if="imageSrc" />
      <person-icon-colored class="image" :gender="gender" v-else></person-icon-colored>

      <div class="title">
        <div class="name text-md bold">{{ fullNameDisplay }}</div>
        <div class="dates text-sm">{{ datesDisplay }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import {getFullNameByLangMixed} from '@common/utils/utils.names';

import PersonIconColored from '@/base/icons/person/PersonIconColored';

import {UNKNOWN_NAME} from '@/components/modules/familyTree/constants';

export default {
  props: {
    imageSrc: String,
    gender: String,
    fullName: String,
    firstNames: Array,
    surnames: Array,
    birthDate: Object,
    deathDate: Object,
    isDeceased: Boolean,
    generationNumber: Number,
  },
  computed: {
    datesDisplay() {
      let birth = this.getDateDisplay(this.birthDate) || 'Unknown';
      let death = this.getDateDisplay(this.deathDate) || (this.isDeceased ? 'Deceased' : 'Living');
      return `${birth} - ${death}`;
    },
    fullNameDisplay() {
      if (this.firstNames || this.surnames) {
        return (
          getFullNameByLangMixed(this.firstNames, this.surnames, this.$store.getters.familyTreeIsLangChineseState) ||
          UNKNOWN_NAME
        );
      }
      return this.fullName || UNKNOWN_NAME;
    },
  },
  methods: {
    getDateDisplay(date) {
      let year = date ? date.year : '';
      return year && date.approximation_years ? `ca. ${year}` : year;
    },
    dataWrapperClick() {
      this.$emit('click-data');
    },
  },
  components: {PersonIconColored},
};
</script>

<style lang="scss" scoped>
$border-radius: 5px;

.lineage-card {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80px;
  background-color: white;
  box-shadow: $lg-shadow;
  border-radius: 4px;
  border: 2px solid transparent;
  box-sizing: border-box;
  position: relative;
  width: 100%;
  cursor: pointer;

  &:hover {
    border-color: $power-red;
  }

  .generation-number {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 55px;
    height: 100%;
    flex-shrink: 0;
  }

  .data-wrapper {
    display: flex;
    flex-grow: 1;
    overflow: hidden;
    padding-right: 8px;

    .image {
      height: 64px;
      width: 64px;
      border-radius: 4px;
      &::v-deep svg {
        height: 45px;
      }
    }

    .title {
      margin-left: 12px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      flex-grow: 1;
    }
    .name {
      margin-bottom: 4px;
      max-width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .dates {
      color: $neutral-600;
    }
  }
}
</style>

<template>
  <div class="person-details-block item-block">
    <div class="header">
      <div class="heading-6">{{ title }}</div>
      <mcr-button v-if="familyTreePersonState.is_write_allowed" class="white small" @click="startEditMode"
        ><edit-icon :size="20" /><span>Edit</span></mcr-button
      >
    </div>
    <div class="text-md text-lg-mobile sections">
      <div v-for="section in visibleSections" :key="section.key" class="section" :class="section.class">
        <div v-if="section.sectionCategory" class="section-category text-sm text-md-mobile">
          {{ section.sectionCategory }}
        </div>
        <div class="content-item" v-for="sectionItem in section.items">
          <div class="label">{{ sectionItem.label }}</div>
          <div class="values">
            <div class="value" v-for="value in sectionItem.values">
              <div v-if="sectionItem.isHtml" v-html="value"></div>
              <div v-else-if="value.isAddButton" class="add-button-wrapper">
                <tooltip-interactive
                  placement="top-start"
                  :shown="value.isTooltipShown"
                  :heading="value.tooltip && value.tooltip.heading"
                  :text="value.tooltip && value.tooltip.text"
                  :distance="value.tooltip && value.tooltip.distance"
                  :container="'.person-details-block'"
                  @close="saveViewedBirthTooltip"
                >
                  <a @click="startEditMode(value.scrollId)">+ Add</a>
                </tooltip-interactive>
              </div>
              <residence-display
                v-else-if="sectionItem.isResidence"
                :date="value.date"
                :location="value.location"
                :note="value.note"
              >
              </residence-display>
              <date-location-display
                v-else-if="sectionItem.isDateLocation"
                :date="value.date"
                :location="value.location"
              ></date-location-display>
              <fact-display v-else-if="sectionItem.isFact" :fact="value"></fact-display>
              <div v-else>{{ value }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import McrButton from '@common/elements/buttons/mcrButton';
import TooltipInteractive from '@common/elements/layouts/TooltipInteractive.vue';
import {isChineseText} from '@common/utils/utils';
import sortBy from 'lodash/sortBy';
import EditIcon from 'vue-material-design-icons/Pencil';
import {mapGetters} from 'vuex';

import FactDisplay from '@/components/common/tree/FactDisplay';
import dateLocationDisplay from '@/components/common/tree/dateLocationDisplay';
import ResidenceDisplay from '@/components/common/tree/residenceDisplay.vue';

import {
  CARD_FULL_PROFILE_HELPER_KEY,
  PROFILE_ADD_BIRTH_HELPER_KEY,
  QUICK_VIEW_HELPER_KEY,
  UNKNOWN_NAME,
} from '@/components/modules/familyTree/constants';
import {getEditSectionScrollId} from '@/components/modules/familyTreeProfile/utils';

export default {
  computed: {
    ...mapGetters(['familyTreePersonState', 'familyTreePersonOptionsState', 'reviewedItemsState']),
    sections() {
      const [enNames, cnNames] = this.splitNamesByLanguageAndType(this.familyTreePersonState.first_names, 'First Name');
      const [enSurnames, cnSurnames] = this.splitNamesByLanguageAndType(this.familyTreePersonState.surnames, 'Surname');

      let englishNamesItems = [
        ...Object.keys(enNames).map(key => {
          return {label: `English ${key}`, values: enNames[key]};
        }),
        ...Object.keys(enSurnames).map(key => {
          return {label: `English ${key}`, values: enSurnames[key]};
        }),
      ];
      let chineseNamesItems = [
        ...Object.keys(cnNames).map(key => {
          return {label: `Chinese ${key}`, values: cnNames[key]};
        }),
        ...Object.keys(cnSurnames).map(key => {
          return {label: `Chinese ${key}`, values: cnSurnames[key]};
        }),
      ];

      if (!englishNamesItems.length && !chineseNamesItems.length) {
        englishNamesItems = [
          {label: 'English First Name', values: [this.getEmptyValue('first_names')]},
          {label: 'English Surname', values: [this.getEmptyValue('surnames')]},
        ];
      }

      const commonInfoItems = [
        {label: 'Gender', values: [this.familyTreePersonState.gender_display]},
        {label: 'Status', values: [this.livingStatus]},
        {
          label: 'Biography',
          values: [this.familyTreePersonState.notes_display || this.getEmptyValue('notes')],
          isHtml: !!this.familyTreePersonState.notes_display,
        },
        {
          label: 'Generation Number',
          values: [this.familyTreePersonState.generation_number || this.getEmptyValue('generation_number')],
        },
      ];
      const emptyBirthValue = this.getEmptyValue('birth');
      if (emptyBirthValue.isAddButton) {
        emptyBirthValue.isTooltipShown = this.addBirthTooltipShown;
        emptyBirthValue.tooltip = this.addBirthTooltip;
      }
      const birthDate = this.familyTreePersonState.birth_date;
      const hasBirthDate = birthDate && Object.values(birthDate).some(v => !!v);
      const birthItems =
        hasBirthDate || this.familyTreePersonState.birth_location
          ? [
              {
                label: 'Birth',
                values: [{date: birthDate, location: this.familyTreePersonState.birth_location}],
                isDateLocation: true,
              },
            ]
          : [
              {
                label: 'Birth',
                values: [emptyBirthValue],
              },
            ];
      const deathDate = this.familyTreePersonState.death_date;
      const hasDeathDate = deathDate && Object.values(deathDate).some(v => !!v);
      const deathItems =
        hasDeathDate || this.familyTreePersonState.death_location
          ? [
              {
                label: 'Death',
                values: [{date: deathDate, location: this.familyTreePersonState.death_location}],
                isDateLocation: true,
              },
            ]
          : this.familyTreePersonState.is_deceased
          ? [{label: 'Death', values: [this.getEmptyValue('death')]}]
          : [];
      const residenceItems =
        this.familyTreePersonState.residence_location && this.familyTreePersonState.residence_location.length
          ? [
              {
                label: 'Residence',
                values: this.familyTreePersonState.residence_location.map(residence => {
                  return {
                    location: {place_id: residence.place_id, display_text: this.getFormattedResidenceText(residence)},
                    note: residence.note,
                  };
                }),
                isResidence: true,
              },
            ]
          : [{label: 'Residence', values: [this.getEmptyValue('residence_location')]}];

      const factsGrouped = {};
      const priorityByCategory = {};
      this.familyTreePersonState.facts.forEach(fact => {
        const category = fact.fact_type.category;
        const type = fact.fact_type.id;
        factsGrouped[category] = factsGrouped[category] || {};
        factsGrouped[category][type] = factsGrouped[category][type] || [];
        factsGrouped[category][type].push(fact);
        priorityByCategory[category] =
          priorityByCategory[category] && priorityByCategory[category] < fact.fact_type.priority
            ? priorityByCategory[category]
            : fact.fact_type.priority;
      });
      if (this.familyTreePersonState.is_write_allowed) {
        this.familyTreePersonOptionsState.fact_types
          .filter(type => type.is_profile_highlighted)
          .forEach(type => {
            factsGrouped[type.category] = factsGrouped[type.category] || {};
            if (!factsGrouped[type.category][type.id]) {
              factsGrouped[type.category][type.id] = [{fact_type: type, ...this.getEmptyValue(type.id, true)}];
              priorityByCategory[type.category] = type.priority;
            }
          });
      }
      const factSections = sortBy(Object.keys(factsGrouped), category => priorityByCategory[category]).map(category => {
        let factTypes = Object.keys(factsGrouped[category]);
        factTypes = sortBy(factTypes, factType => factsGrouped[category][factType][0].fact_type.priority);

        const items = factTypes.map(type => {
          const facts = sortBy(factsGrouped[category][type], fact => {
            return fact.start_date && fact.start_date.year ? fact.start_date.year : null;
          });
          return {label: facts[0].fact_type.label, values: facts, isFact: true};
        });
        return {
          key: `fact_${category}`,
          sectionCategory: category,
          items: items,
          class: 'fact-section',
        };
      });
      const nameItems = [
        {key: 'english_names', items: englishNamesItems},
        {key: 'chinese_names', items: chineseNamesItems},
      ];
      return [
        ...nameItems,
        {key: 'birth', items: birthItems},
        {key: 'death', items: deathItems},
        {key: 'residence', items: residenceItems},
        {key: 'common_info', items: commonInfoItems},
        ...factSections,
      ].filter(item => item.items.length);
    },
    visibleSections() {
      return this.sections;
    },
    isActive() {
      return this.$parent.$parent.isActive;
    },
    addBirthTooltip() {
      return {
        heading: `Do you know ${this.primaryFirstName}’s birth place?`,
        text: 'Adding birth details helps to automatically match records.',
        buttonLabel: 'Add Now',
        distance: this.isDesktop ? 10 : 35,
      };
    },
    addBirthTooltipShown() {
      return (
        this.isActive &&
        !this.reviewedItemsState.includes(PROFILE_ADD_BIRTH_HELPER_KEY) &&
        this.reviewedItemsState.includes(QUICK_VIEW_HELPER_KEY) &&
        this.reviewedItemsState.includes(CARD_FULL_PROFILE_HELPER_KEY) &&
        this.familyTreePersonState.hierarchy_level === 98 &&
        this.familyTreePersonState.is_write_allowed
      );
    },
    primaryFirstName() {
      const names = this.familyTreePersonState.first_names;
      return names && names[0] && names[0].value ? names[0].value : UNKNOWN_NAME;
    },
    title() {
      return `${this.primaryFirstName}'s Details`;
    },
    livingStatus() {
      const mapping = {
        [true]: 'Deceased',
        [false]: 'Living',
      };
      return mapping[this.familyTreePersonState.is_deceased] || this.unknownLabel;
    },
    unknownLabel() {
      return 'Unknown';
    },
    isDesktop() {
      return this.$store.getters.windowWidthState >= this.$breakpoints.phablet;
    },
  },
  methods: {
    splitNamesByLanguageAndType(names, defaultType) {
      let englishByType = {};
      let chineseByType = {};
      for (let name of names) {
        const typeDisplay = name.type_display || defaultType;
        if (isChineseText(name.value)) {
          chineseByType[typeDisplay] = chineseByType[typeDisplay] || [];
          chineseByType[typeDisplay].push(name.value);
        } else {
          englishByType[typeDisplay] = englishByType[typeDisplay] || [];
          englishByType[typeDisplay].push(name.value);
        }
      }
      return [englishByType, chineseByType];
    },
    getFormattedResidenceText(residence) {
      const years =
        residence.from_year || residence.to_year ? ` (${residence.from_year || '?'}-${residence.to_year || '?'})` : '';
      return `${residence.display_text || ''}${years}`;
    },
    startEditMode(scrollId) {
      this.$emit('start-edit-mode', {scrollId});
    },
    getEmptyValue(id, isFact) {
      if (!this.familyTreePersonState.is_write_allowed) {
        return this.unknownLabel;
      }
      const scrollId = getEditSectionScrollId(id, isFact);
      return {isAddButton: true, scrollId};
    },
    saveViewedBirthTooltip() {
      this.$store.commit('addReviewedItemState', PROFILE_ADD_BIRTH_HELPER_KEY);
    },
  },
  components: {TooltipInteractive, ResidenceDisplay, McrButton, EditIcon, dateLocationDisplay, FactDisplay},
  name: 'PersonDetailsBlock',
};
</script>

<style lang="scss" scoped>
.person-details-block {
  .content-item::v-deep {
    .date-location-display {
      display: flex;
      align-items: flex-start;
      flex-wrap: wrap;

      .place-wrapper {
        display: flex;
        align-items: flex-start;
      }
      .material-design-icon {
        display: flex;
        width: 20px;
      }
    }
    .add-button-wrapper {
      display: flex;
    }
  }

  @media only screen and (max-width: $breakpoint-phablet) {
    .content-item {
      flex-direction: column;
      .label {
        width: max-content;
        margin-bottom: 4px;
      }
      .values {
        row-gap: 0;
      }
    }
  }
}
</style>

<template>
  <div class="asset-details-block-modal">
    <div class="heading">
      <div class="text-md bold">{{ mainTitle }}</div>
      <close-icon class="close-icon" @click="cancelClick"></close-icon>
    </div>
    <div class="content">
      <asset-details-block :is-edit-allowed="isEditAllowed" :asset-details="assetDetails"></asset-details-block>
    </div>
  </div>
</template>

<script>
import CloseIcon from 'vue-material-design-icons/Close';

import AssetDetailsBlock from './AssetDetailsBlock';

export default {
  props: {
    isEditAllowed: Boolean,
    assetId: String,
  },
  computed: {
    mainTitle() {
      return this.isEditAllowed ? 'Edit Details' : 'View Details';
    },
    assetDetails() {
      return this.$store.getters.familyTreeAssetDetailsByIdState[this.assetId];
    },
  },
  methods: {
    cancelClick() {
      this.$emit('close');
    },
  },
  components: {AssetDetailsBlock, CloseIcon},
  name: 'AssetDetailsBlockModal',
};
</script>

<style scoped lang="scss"></style>

<template>
  <div
    class="family-tree-card"
    :style="style"
    @click="cardClick"
    @touchstart="onTouchStart"
    @touchmove.prevent="onTouchMove"
    @touchend="onTouchEnd"
    @mouseup.right="contextmenu"
    @mouseenter="mouseenter"
    @mouseleave="mouseleave"
    @contextmenu.prevent
  >
    <div class="family-tree-card-content" :class="classes">
      <div class="picture-container">
        <img :src="profilePicture" v-if="profilePicture" class="profile-picture no-select" />
        <div v-else-if="person.is_ghost" class="profile-picture no-select ghost-add-icon">
          <plus-circle-icon :size="68"></plus-circle-icon>
        </div>
        <person-icon-colored v-else :gender="person.gender" :colored="colorCodeGender"></person-icon-colored>
      </div>
      <div class="name-container">
        <div class="name clip no-select bold" v-if="person.is_ghost">{{ person.full_name }}</div>
        <div class="name clip no-select bold" v-else-if="showUnknownName">{{ unknownName }}</div>
        <div class="name clip no-select bold" v-else-if="showChineseName">{{ fullNameCh }}</div>
        <template v-else>
          <div class="name clip no-select bold" v-if="primaryFirstNameEnTrimmed">
            {{ primaryFirstNameEnTrimmed }}
          </div>
          <div class="name clip no-select bold" v-if="primarySurnameEn">{{ primarySurnameEn }}</div>
        </template>
      </div>
      <div class="years text-sm">{{ years }}</div>
    </div>
    <div class="has-hints-icon" v-if="hasHints"><img :src="hintsIconSrc" /></div>
  </div>
</template>

<script>
import {getLifetimeYears, isChineseText} from '@common/utils/utils';
import {getFullNameCn, trimLongName} from '@common/utils/utils.names';
import PlusCircleIcon from 'vue-material-design-icons/Plus';

import PersonIconColored from '@/base/icons/person/PersonIconColored';

import {HEIGHT, UNKNOWN_NAME, WIDTH} from '../constants';

export default {
  props: {
    person: Object,
    isHovered: Boolean,
    hasHints: {type: Boolean, default: false},
    doubleClick: {type: Boolean, default: true},
    colorCodeGender: {type: Boolean, default: true},
  },
  data() {
    return {
      clicks: 0,
      doubleClickDelay: 200,
      touchContextMenuTimeoutId: null,
      touchContextMenuStart: null,
      firstNameTrimLength: 16,
    };
  },
  computed: {
    classes() {
      let classes = [];
      if (this.person.is_start_person) {
        classes.push('is-start-person');
      }
      if (this.person.is_ghost) {
        classes.push('is-ghost');
      }
      if (this.person.is_current_user) {
        classes.push('is-current-user');
      }
      if (this.isHovered) {
        classes.push('hover');
      }
      return classes;
    },
    years() {
      return getLifetimeYears(this.person.birth_date, this.person.death_date, this.person.is_deceased);
    },
    showUnknownName() {
      return !this.primaryFirstNameEn && !this.primarySurnameEn && !this.fullNameCh;
    },
    showChineseName() {
      if (!this.fullNameCh) {
        return false;
      }
      return this.$store.getters.familyTreeIsLangChineseState || (!this.primaryFirstNameEn && !this.primarySurnameEn);
    },
    fullNameCh() {
      return getFullNameCn(this.person.first_names, this.person.surnames);
    },
    primaryFirstNameEn() {
      for (let name of this.person.first_names || []) {
        if (name && name.value && !isChineseText(name.value)) {
          return name.value;
        }
      }
    },
    primaryFirstNameEnTrimmed() {
      return this.primaryFirstNameEn && this.primaryFirstNameEn.length > this.firstNameTrimLength
        ? trimLongName(this.primaryFirstNameEn)
        : this.primaryFirstNameEn;
    },
    primarySurnameEn() {
      for (let name of this.person.surnames || []) {
        if (name && name.value && !isChineseText(name.value)) {
          return name.value;
        }
      }
    },
    unknownName() {
      return this.person.is_current_user ? 'You' : UNKNOWN_NAME;
    },
    style() {
      return {
        width: `${WIDTH}px`,
        'max-width': `${WIDTH}px`,
        height: `${HEIGHT}px`,
        'max-height': `${HEIGHT}px`,
      };
    },
    profilePicture() {
      return this.person.profile_picture && this.person.profile_picture.attachment;
    },
    hintsIconSrc() {
      return this.$getAsset('/assets/mcr_logo_dude_transparent.png');
    },
  },
  methods: {
    cardClick(event) {
      if (event.altKey) {
        return this.$emit('alt-click-card', this.person);
      }
      if (event.ctrlKey || event.metaKey) {
        return this.$emit('ctrl-click-card', this.person);
      }
      this.clicks++;
      if (this.doubleClick) {
        return this.handleDoubleClick();
      }
      return this.handleSingleClick();
    },
    handleDoubleClick() {
      if (this.clicks === 1) {
        setTimeout(() => {
          if (this.clicks > 1) {
            this.$emit('dblclick-card', this.person);
          } else {
            this.handleSingleClick();
          }
          this.clicks = 0;
        }, this.doubleClickDelay);
      }
    },
    handleSingleClick() {
      return this.$emit('click-card', this.person);
    },
    mouseenter() {
      this.$emit('mouseenter');
    },
    mouseleave() {
      this.$emit('mouseleave');
    },
    contextmenu(event) {
      this.$emit('contextmenu', event);
    },
    onTouchStart() {
      this.touchContextMenuStart = new Date().getTime();
      this.touchContextMenuTimeoutId = setTimeout(() => {
        this.contextmenu();
      }, 500);
    },
    onTouchMove(event) {
      const now = new Date().getTime();
      if (this.touchContextMenuStart && now - this.touchContextMenuStart < 400) {
        clearTimeout(this.touchContextMenuTimeoutId);
      }
    },
    onTouchEnd(event) {
      clearTimeout(this.touchContextMenuTimeoutId);
    },
  },
  components: {PersonIconColored, PlusCircleIcon},
};
</script>

<style scoped lang="scss">
@import '@common/style/mixins';

.family-tree-card {
  cursor: pointer;
  display: flex;
  border-radius: 4px;

  .family-tree-card-content {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    width: 100%;
    align-items: center;
    justify-content: flex-start;
    background-color: white;
    box-shadow: $lg-shadow;
    transition: all 0.1s ease;
    border-radius: 4px;
    border: 2px solid transparent;
    box-sizing: border-box;
    padding: 0 8px;

    .name-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      min-height: 37px;
      max-width: 100%;
    }

    .name {
      max-width: 100%;
      transition: max-width 0.3s ease;
      font-size: 18px;
      line-height: 19px;

      &.clip {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    .years {
      position: absolute;
      bottom: 8px;
      color: $neutral-600;
    }

    &:hover,
    &.hover {
      &:not(.is-ghost) {
        border-color: $power-red;

        .name {
          z-index: 10;
          position: relative;
          max-width: 40vw;
          background-color: white;
        }
      }
    }

    .picture-container {
      height: 120px;
      width: 120px;
      display: flex;
      margin: 8px 0 10px;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      border-radius: 4px;
      overflow: hidden;

      .profile-picture {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    &.is-start-person {
      background-color: $dark-background-3;
      color: white;

      .years {
        color: $neutral-300;
      }

      &:hover,
      &.hover {
        &:not(.is-ghost) {
          .name {
            background-color: $dark-background-3;
          }
        }
      }
    }

    &.is-ghost {
      border: 1px dotted $neutral-300;
      background-color: white;
      justify-content: center;
      .years {
        display: none;
      }

      .picture-container {
        height: 68px;
        width: 68px;
        margin-bottom: 32px;
        background: rgba(156, 163, 175, 0.12);
        border-radius: 100%;

        .ghost-add-icon {
          display: flex;
          justify-content: center;
          align-items: center;
          color: $neutral-500;
        }
      }

      &:hover,
      &.hover {
        border-color: $primary-600;
        .picture-container {
          background: $primary-50;
          .ghost-add-icon {
            color: $primary-600;
          }
        }

        .name {
          opacity: 1;
          color: $primary-600;
          background-color: transparent;
        }
      }
    }

    &.is-current-user {
      &:hover,
      &.hover {
        &::v-deep .person-icon {
          .symbol {
            fill: $link-color;
          }
        }
      }
    }
  }
}

.has-hints-icon {
  background: $power-red;
  position: absolute;
  top: -16px;
  right: -16px;
  border-radius: 50%;
  height: 32px;
  width: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  img {
    margin-left: 1px;
    height: 28px;
  }
}
</style>

<template>
  <delete-confirm-modal-content
    :loading="loading"
    class="confirm-delete-mention"
    @confirm="okClick"
    @close="cancelClick"
  >
    <h5>
      Are you sure you want to remove <span>{{ recordName }}</span> from <span>{{ personName }}</span
      >?
    </h5>
    <p>This record can be re-added later via the search section.</p>
  </delete-confirm-modal-content>
</template>

<script>
import DeleteConfirmModalContent from '@/base/elements/modals/deleteConfirmModalContent';

export default {
  props: {
    recordId: String,
    recordName: String,
    personName: String,
    reloadRecords: Function,
  },
  data() {
    return {
      loading: false,
    };
  },
  methods: {
    cancelClick() {
      this.$emit('close');
    },
    okClick() {
      this.loading = true;
      this.$store
        .dispatch('deleteSavedMentionFromFamilyTreeAction', {id: this.recordId})
        .then(() => {
          this.reloadRecords();
          this.$emit('close');
        })
        .catch(() => {
          this.loading = false;
          this.$toasted.error('Something went wrong. Please try again later.');
        });
    },
  },
  name: 'ConfirmDeleteMention',
  components: {DeleteConfirmModalContent},
};
</script>

<style lang="scss" scoped>
h5 span {
  color: $active-color;
  word-break: break-word;
}
</style>

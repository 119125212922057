<template>
  <div class="familytree-card-container">
    <family-tree-card
      ref="card"
      :person="person"
      :double-click="false"
      @click-card="openQuickCreateSidebar"
      @dblclick-card="openQuickCreateSidebar"
    ></family-tree-card>
  </div>
</template>

<script>
import AnalyticsMainHandler from '@common/utils/analytics/analytics.main';
import {mapGetters} from 'vuex';

import {PARENT_RELATION_TYPE, UNKNOWN_NAME} from '@/components/modules/familyTree/constants';
import FamilyTreeCard from '@/components/modules/familyTree/tree/familyTreeCard';
import PersonQuickCreate from '@/components/modules/familyTree/tree/modals/quick/PersonQuickCreate';
import {
  createGhostParentHandler,
  getFirstEnCnSurnames,
  getPossibleChildrenList,
} from '@/components/modules/familyTree/tree/services';
import {openCreateGhostParentModal} from '@/components/modules/familyTree/tree/services.modals';

export default {
  props: {
    person: Object,
    handleClickAllowed: Boolean,
  },
  computed: {
    ...mapGetters(['familyTreeDetailsIdState', 'familyTreeStartPersonIdState', 'familyTreePersonsByIdState']),
    initialPersonData() {
      const child = this.familyTreePersonsByIdState[this.person.child.object_id];
      return {
        gender: this.person.gender,
        hierarchy_level: this.person.hierarchy_level,
        generation_number: this.person.child.generation_number ? this.person.child.generation_number - 1 : null,
        family_tree_id: this.familyTreeDetailsIdState,
        surnames: this.person.gender === 'm' && child && child.surnames ? getFirstEnCnSurnames(child.surnames) : null,
      };
    },
    createModalName() {
      return `create-person-${this.person.id}`;
    },
  },
  methods: {
    async openQuickCreateSidebar(person) {
      if (!this.handleClickAllowed) {
        return;
      }
      let possibleChildrenList = person.spouse ? getPossibleChildrenList(PARENT_RELATION_TYPE, person.child, this) : [];
      let childrenIds = possibleChildrenList.filter(option => option.selected).map(option => option.id);
      const props = {
        relationType: PARENT_RELATION_TYPE,
        relationTypeName: this.person.gender === 'm' ? 'Father' : this.person.gender === 'f' ? 'Mother' : 'Parent',
        fullName: this.familyTreePersonsByIdState[person.child.object_id].full_name || UNKNOWN_NAME,
        fromPersonId: person.id,
        initialData: this.initialPersonData,
        possibleChildrenList: possibleChildrenList,
        possibleParentsList: [],
        saveClickHandler: async formData => {
          await createGhostParentHandler(formData, person.child, childrenIds, false, this);
          this.$modal.hide(this.createModalName);
        },
        fullFormOpener: formData => {
          openCreateGhostParentModal(formData, person.child, possibleChildrenList, this);
        },
        childrenIdsChange: value => {
          childrenIds = value;
        },
        parentsIdsChange: value => {},
      };

      let events = {
        opened: () => {
          this.$store.commit('setCreatePrefillSurnamesState', []);
        },
      };

      const isMobileView = this.$store.getters.windowWidthState <= this.$breakpoints.mobile;
      const modalParams = {
        name: this.createModalName,
        clickToClose: true,
        scrollable: true,
        height: 'auto',
        classes: 'clear_modal white_modal',
        class: isMobileView ? 'mobile_bottom' : '',
        transition: isMobileView ? 'info-bar-slide-bottom' : '',
      };
      this.$modal.show(PersonQuickCreate, props, modalParams, events);
      AnalyticsMainHandler.trackTreeClickGhostCardEvent();
    },
  },
  components: {FamilyTreeCard},
};
</script>

<style lang="scss" scoped>
.familytree-card-container {
  position: absolute;
  z-index: 3;
}
</style>

<template>
  <delete-confirm-modal-content
    :loading="loading"
    :deleteFunction="deleteFunction"
    class="person-delete-confirm"
    @confirm="okClick"
    @close="cancelClick"
  >
    <h5>
      Are you sure you want to delete <span>{{ personName }}</span
      >?
    </h5>
    <p>Deleting will permanently erase their information.</p>

    <stand-out-block class="warning text-sm" v-if="showSplitTreeWarning">
      <div class="icon-container">
        <alert-icon :size="25"></alert-icon>
      </div>
      <div>
        <b>WARNING:</b> If this person connects a branch to the root tree, deleting this person may isolate the branch,
        and persons inside will only be findable through the search function.
      </div>
    </stand-out-block>
  </delete-confirm-modal-content>
</template>

<script>
import StandOutBlock from '@common/elements/layouts/StandOutBlock.vue';
import AlertIcon from 'vue-material-design-icons/AlertOutline';
import {mapGetters} from 'vuex';

import DeleteConfirmModalContent from '@/base/elements/modals/deleteConfirmModalContent';

export default {
  components: {StandOutBlock, DeleteConfirmModalContent, AlertIcon},
  props: {
    personName: String,
    personId: Number,
    deleteFunction: Function,
  },
  computed: {
    ...mapGetters(['familyTreePersonDeleteLoadingState', 'familyTreeDrawLoadingState']),
    loading() {
      return this.familyTreePersonDeleteLoadingState || this.familyTreeDrawLoadingState;
    },
    showSplitTreeWarning() {
      return true;
    },
  },
  methods: {
    cancelClick() {
      this.$emit('close');
    },
    okClick() {
      this.deleteFunction();
    },
  },
  name: 'personDeleteConfirmModalContent',
};
</script>

<style lang="scss" scoped>
h5 span {
  color: $active-color;
}
.warning {
  box-shadow: $slight-shadow-bottom;
  background: white;
  color: $neutral-500;
  max-width: 472px;

  .name {
    font-weight: 600;
  }
  .icon-container {
    color: $primary-400;
    background: $primary-50;
    border-radius: 50%;
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 8px;
  }
}
</style>

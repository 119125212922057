import { render, staticRenderFns } from "./personSavedMentions.vue?vue&type=template&id=e89ea436&scoped=true&"
import script from "./personSavedMentions.vue?vue&type=script&lang=js&"
export * from "./personSavedMentions.vue?vue&type=script&lang=js&"
import style0 from "./personSavedMentions.vue?vue&type=style&index=0&id=e89ea436&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e89ea436",
  null
  
)

export default component.exports
<template>
  <div v-if="relatives.length" class="person-details-relatives flex-column">
    <div v-for="person in relatives" :key="person.object_id" class="flex-row relative">
      <div class="flex-row clickable" @click="click(person.object_id)">
        <person-icon :gender="person.gender"></person-icon>
        <span class="full-name">{{ getPersonName(person) }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import PersonIcon from '@/base/icons/person/PersonIcon';

import {UNKNOWN_NAME} from '@/components/modules/familyTree/constants';

export default {
  components: {PersonIcon},
  props: {
    relatives: Array,
  },
  methods: {
    getPersonName(person) {
      /* handle person was re-named in the tree without tree re-load */
      const drawnPerson = this.$store.getters.familyTreePersonsByIdState[person.object_id];
      const name = drawnPerson ? drawnPerson.full_name : null;
      return name || person.full_name || UNKNOWN_NAME;
    },
    click(id) {
      this.$emit('click', id);
    },
  },
};
</script>

<style lang="scss" scoped>
.flex-row {
  align-items: flex-start;
  &::v-deep .person-icon {
    width: 27px;
    position: relative;
    top: -2px;
    left: -2px;
    flex-shrink: 0;
    .shape {
      opacity: 0.3;
      stroke: black;
    }
    .symbol {
      fill: black;
      opacity: 0;
    }
  }

  .full-name {
    margin-left: 8px;
    word-break: break-all;
  }
}

.clickable {
  cursor: pointer;
}
.relative:not(:last-child) {
  margin-bottom: 20px;
}
</style>

<template>
  <div class="remove-relationship-confirm alert-modal-content">
    <h5>Are you sure you want to remove this relationship?</h5>
    <div class="warning">
      Removing <span class="name">{{ relationshipTypeDisplay }}</span> relationship between
      <span class="name">{{ personName }}</span> and <span class="name">{{ relativeName }}</span> could result in their
      branches to be isolated, because they might not be connected to the root person anymore. In that case, all persons
      inside this branch will only be findable through the search function.
    </div>
    <div class="buttons">
      <mcr-button class="white" :disabled="loading" @click="cancelClick">Cancel</mcr-button>
      <mcr-button class="proceed" :loading="loading" @click="okClick">Proceed</mcr-button>
    </div>
  </div>
</template>

<script>
import McrButton from '@common/elements/buttons/mcrButton.vue';

export default {
  props: {
    treeId: [String, Number],
    personName: String,
    personId: [String, Number],
    relativeName: String,
    relativeId: [String, Number],
    type: {type: String, validator: value => ['parents', 'spouses', 'children'].includes(value)},
  },
  data() {
    return {
      loading: false,
    };
  },
  computed: {
    relationshipTypeDisplay() {
      return {
        parents: 'Parent',
        spouses: 'Spouse',
        children: 'Child',
      }[this.type];
    },
  },
  methods: {
    cancelClick() {
      this.$emit('close');
    },
    okClick() {
      this.loading = true;
      const mapping = {
        parents: () => this.removeParent(this.relativeId, this.personId),
        spouses: () => this.removeSpouse(this.personId, this.relativeId),
        children: () => this.removeParent(this.personId, this.relativeId),
      };

      return mapping[this.type]()
        .then(() => {
          const action = {
            text: 'View their Profile',
            push: {
              name: 'familytree-profile-details',
              params: {id: this.treeId, personId: this.relativeId},
              query: {tab: 'details'},
            },
          };
          this.$toasted.success(`Relationship with ${this.relativeName} is removed`, {action, duration: 5000});
          this.$store.dispatch('fetchFamilyTreePersonRelativesAction', this.personId);
          this.$store.commit('setFamilyTreePersonsDrawnState', []);
          this.$store.commit('setFamilyTreeLinesDrawnState', []);
          this.$emit('close');
        })
        .catch(() => {
          this.$toasted.error('Error removing the relationship. Please try again later.');
          this.loading = false;
        });
    },
    removeParent(parentId, childId) {
      return this.network.familyTree.deleteParentRelationship({
        id: this.treeId,
        parent_id: parentId,
        child_id: childId,
      });
    },
    removeSpouse(personId, spouseId) {
      return this.network.familyTree.deleteSpouseRelationship({
        id: this.treeId,
        person_id: personId,
        spouse_id: spouseId,
      });
    },
  },
  name: 'RemoveRelationshipConfirm',
  components: {McrButton},
};
</script>

<style scoped lang="scss">
.remove-relationship-confirm {
  max-width: 600px;
  .warning {
    color: $neutral-500;
  }
  .name {
    font-weight: 600;
  }
}
</style>

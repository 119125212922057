<template>
  <div class="uploading-mask" v-if="isUploading">
    <div class="overlay">
      <p class="uploading-text" v-if="isUploading && !hasError">Uploading...</p>
      <p class="uploading-progress" v-if="!hasError && uploadProgress">{{ uploadProgress }}%</p>
      <transition name="fade">
        <p v-if="hasError" class="uploading-error">Upload Failed</p>
      </transition>
    </div>
    <div class="upload-progress-bar-container">
      <div class="upload-progress-bar" :style="progressBarStyle"></div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    isUploading: Boolean,
    hasError: Boolean,
    uploadProgress: String,
  },
  computed: {
    progressBarStyle() {
      return {width: `${this.uploadProgress}%`};
    },
  },
  name: 'fileUploadingMask',
};
</script>

<style lang="scss" scoped>
.uploading-mask {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 4px;
  .overlay {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(black, 0.76);
    p {
      color: rgba(white, 0.9);
      margin: 0;
      margin-bottom: 10px;
      font-size: 0.8em;
    }
    .uploading-error {
      background-color: $error-color;
      color: white;
      padding: 6px 8px;
      border-radius: 4px;
      text-align: center;
    }
  }
  .upload-progress-bar-container {
    position: absolute;
    bottom: 0px;
    left: 0px;
    width: 100%;
    .upload-progress-bar {
      background-color: rgba($error-color, 1);
      height: 10px;
    }
  }
}
</style>

<template>
  <div class="item-block person-saved-mentions-block" ref="block">
    <div class="header">
      <div class="heading-6">Associated Records</div>
    </div>
    <div class="content" v-if="loading">
      <mcr-loading-indicator :loading="true"></mcr-loading-indicator>
    </div>
    <div class="body text-md text-lg-mobile" v-else>
      <div class="items">
        <div v-for="record in mentions" :key="record.id" class="record-item">
          <div class="record-details">
            <div>
              <router-link class="link" :to="getDetailsRoute(record)">
                Source: {{ getRecordTitle(record) }}
              </router-link>
            </div>
            <record-type
              class="text-sm text-md-mobile"
              :size="18"
              :type="record.source.mention_type_display"
              :type-name="getSubtitle(record)"
            ></record-type>
          </div>
          <div class="actions" v-if="editable">
            <remove-field-icon class="action" @click.native="removeClick(record)"></remove-field-icon>
          </div>
        </div>
      </div>
      <base-pagination :meta="meta" :show-jump-to="false" @onSwitchPage="onSwitchPage"></base-pagination>
    </div>
  </div>
</template>

<script>
import BasePagination from '@common/elements/base-pagination';
import RecordType from '@common/elements/icons/RecordType';
import {getPersonRecordDetailRoute} from '@common/utils/utils.routes';

import ConfirmDeleteMention from '@/components/common/modals/ConfirmDeleteMention';

import RemoveFieldIcon from '@/components/modules/familyTree/tree/modals/fields/removeFieldIcon';

export default {
  props: {
    personName: String,
    editable: Boolean,
    loading: Boolean,
    mentions: Array,
    meta: Object,
  },
  methods: {
    onSwitchPage(page) {
      this.$emit('switch-page', page);
    },
    getRecordTitle(record) {
      return record.source.title_en || record.source.title_ch;
    },
    getSubtitle(record) {
      const country = record.source.country;
      const type = record.source.mention_type_display;
      return country && type ? `${country}, ${type}` : country || type;
    },
    getDetailsRoute(record) {
      return getPersonRecordDetailRoute(record.mention_person.code, record.mention_person.full_name);
    },
    removeClick(record) {
      const props = {
        recordId: record.id,
        recordName: this.getRecordTitle(record),
        personName: this.personName,
        reloadRecords: this.onSwitchPage,
      };
      const modalParams = {classes: 'clear_modal white_modal', class: 'mobile_bottom'};
      this.$modal.show(ConfirmDeleteMention, props, modalParams);
    },
  },
  components: {BasePagination, RecordType, RemoveFieldIcon},
  name: 'PersonSavedMentionsBlock',
};
</script>

<style lang="scss" scoped>
.person-saved-mentions-block {
  .items {
    display: flex;
    flex-direction: column;
    row-gap: 24px;
    padding: 20px 0;
  }
  .record-item {
    display: flex;
    justify-content: space-between;
    column-gap: 4px;
    word-break: break-word;
    padding: 0 24px;

    .record-type.supplemental {
      margin-top: 4px;
      color: $neutral-500;
    }

    @media only screen and (max-width: $breakpoint-mobile) {
      padding: 0 16px;

      .actions .action {
        margin-top: 3px;
      }
    }
  }
}
</style>

<template>
  <div
    class="family-tree-wrapper full_view card-menu-container"
    @click="onTreeWrapperClick"
    @wheel="zoom"
    @mousedown.left="mousedown"
    @mouseup.left="mouseup"
    @mousemove="mousemove"
    @mouseleave="mouseleave"
    @touchstart="touchstart"
    @touchend="touchend"
    @touchmove.prevent="touchmove"
  >
    <overlay-tutorial-animated></overlay-tutorial-animated>
    <div v-if="showMainLoadingOverlay" id="image-map-loading">
      <mcr-loading-indicator :loading="true" color="#9CA3AF"></mcr-loading-indicator>
    </div>

    <div class="family-tree" :style="style" ref="renderView">
      <transition-group name="fast-fade" tag="div">
        <family-tree-simple-s-v-g
          v-if="isImageRender"
          :persons="familyTreePersonsDrawnState"
          :connectors="familyTreeLinesDrawnState"
          :view-box="svgContainerViewBox"
          :wrapper-style="imageContainerStyle"
          :full-width="familyTreeDrawnWidthState"
          :full-height="familyTreeDrawnHeightState"
          :color-code-gender="familyTreePreferencesState.color_code_gender"
          key="map"
        />
        <div key="tree" v-else>
          <template v-for="connector in visibleConnectorsLabels">
            <div :style="getLabelConnectorStyle(connector)" class="connector-label">
              {{ getLabelConnectorText(connector) }}
            </div>
          </template>
          <svg
            v-if="svgContainerViewBox"
            class="connectors-container"
            :viewBox="svgContainerViewBox"
            :style="svgContainerStyle"
          >
            <template v-for="connector in visibleConnectorsSvg">
              <g v-html="connector.svg" :key="connector.id" :class="getConnectorClasses(connector)" />
              <g
                v-html="connector.svg"
                v-if="connector.type === 'connector'"
                :key="'zone-' + connector.id"
                class="connector-interaction-zone"
                @mouseover.stop="onMouseOverConnector(connector)"
                @mouseleave.stop="stopHighlightingRelatedConnectors(connector)"
                @click.stop="activateRelatedConnectors(connector)"
              ></g>
              <g
                v-else-if="connector.type === 'controller'"
                :key="'zone-' + connector.id"
                class="controller-interaction-zone"
                @mouseover.stop="onMouseOverController(connector)"
                @mouseleave.stop="stopHighlightController(connector)"
                @click.stop="onControllerClick(connector)"
                v-tooltip="getControllerTooltip(connector)"
              >
                <circle
                  :cx="connector.x"
                  :cy="connector.y"
                  :r="connector.radius + 8"
                  fill="white"
                  stroke="#D1D5D8"
                  stroke-width="2"
                  style="z-index: 10"
                />
              </g>
            </template>
          </svg>
          <template v-for="person of visiblePersons">
            <template v-for="draw in person.draws">
              <family-tree-card-ghost-container
                v-if="person.is_ghost"
                :class="getCardContainerClasses(person)"
                ref="cards-containers"
                :person="person"
                :draw="draw"
                :key="draw.position.x + '-' + draw.position.y + '-' + person.id"
                :style="getPositionStyle(draw.position)"
                :handle-click-allowed="wasDraggingInsignificant && isFamilyTreeWriteAllowedState"
              ></family-tree-card-ghost-container>
              <family-tree-card-container
                v-else
                :class="getCardContainerClasses(person)"
                ref="cards-containers"
                :person="person"
                :draw="draw"
                :key="draw.position.x + '-' + draw.position.y + '-' + person.id"
                :scale="scale"
                :style="getPositionStyle(draw.position)"
                :is-detailed="!(isDragging && wasDraggingInsignificant) && scale > 0.3"
                :handle-click-allowed="wasDraggingInsignificant"
              ></family-tree-card-container>
            </template>
          </template>
        </div>
      </transition-group>
    </div>

    <transition name="map-scroll">
      <family-tree-mini-map-switch v-if="showMapSwitch" @switchMap="switchMap"></family-tree-mini-map-switch>

      <family-tree-mini-map
        v-if="showMap"
        :map-scale="familyTreeMapImageScaleState"
        :scale="scale"
        :tree-width="familyTreeDrawnWidthState"
        :tree-height="familyTreeDrawnHeightState"
        :xMargin="familyTreeDrawnMarginXState"
        :view-port-boundaries="getViewPortBoundaries('for map')"
        @move="mapMove"
        @close="closeMap"
        ref="mini-map"
      ></family-tree-mini-map>
    </transition>
  </div>
</template>

<script>
import AnalyticsMainHandler from '@common/utils/analytics/analytics.main';
import consts from '@common/utils/consts';
import {trimLongName} from '@common/utils/utils.names';
import {sortBy} from 'lodash';
import debounce from 'lodash/debounce';
import isEqual from 'lodash/isEqual';
import throttle from 'lodash/throttle';
import {mapGetters} from 'vuex';

import {TREE_USAGE_TUTORIAL_KEY, UNKNOWN_NAME, WIDTH} from '@/components/modules/familyTree/constants';
import FamilyTreeMiniMapSwitch from '@/components/modules/familyTree/controls/familyTreeMiniMapSwitch.vue';
import FamilyTreeCardContainer from '@/components/modules/familyTree/tree/familyTreeCardContainer';
import FamilyTreeCardGhostContainer from '@/components/modules/familyTree/tree/familyTreeCardGhostContainer';
import FamilyTreeMiniMap from '@/components/modules/familyTree/tree/familyTreeMiniMap.vue';
import FamilyTreeSimpleSVG from '@/components/modules/familyTree/tree/familyTreeSimpleSVG.vue';
import OverlayTutorialAnimated from '@/components/modules/familyTree/tree/modals/OverlayTutorialAnimated';

const INSIGNIFICANT_DRAGGING_THRESHOLD = 5;
const DRAGGING_MOUSE = 'dragging_mouse';
const DRAGGING_TOUCH = 'dragging_touch';

export default {
  created() {
    window.addEventListener('wheel', this.stopBrowserZoom, {passive: false});
  },
  destroyed() {
    window.removeEventListener('wheel', this.stopBrowserZoom);
  },
  mounted() {
    this.drawTree();
    this.updateVisibleTree();
    this.shiftTreeIntoViewport();
    setTimeout(() => {
      this.updateMiniMapPosition();
    }, 250);
  },
  data() {
    const scale = 0.5;
    return {
      transform: `translate3d(0px, 0px, -10px) scale(${scale})`,
      translateX: 0,
      translateY: 0,
      scale: scale,
      scaleToMapSwitch: 0.25,
      width: '100%',
      height: '100%',
      svgContainerViewBox: '',
      simpleSvgContainerViewBox: '0 0',
      svgContainerStyle: {},
      imageContainerStyle: {},
      draggingWith: null,
      isDragging: false,
      isZooming: false,
      draggingStart: {x: 0, y: 0},
      draggingEnd: {x: 0, y: 0},
      ghostPersons: [],
      ghostParenthoods: null,
      ghostCouples: null,

      tree: null,
      activatedConnectorsIds: [],
      hoveredControllerId: null,
      hoveredConnectorId: null,
      highlightedConnectorsIds: [],
      highlightTimeout: null,
      highlightedRelatedPersonsIds: [],
      visibleBoundaries: {},
      visibleConnectors: [],
      visiblePersons: [],
      bucketIndexLength: 300,
    };
  },
  watch: {
    familyTreePersonsByIdState(newValue, oldValue) {
      setTimeout(() => {
        // setTimeout to put this into event queue
        this.setVisibleLinesAndPersonByArea();
      }, 0);
    },
    translateX(value) {
      if (this.isZooming) {
        return;
      }
      this.setTransform();
      this.updateVisibleTreeThrottled();
      this.updateVisibleCardMenuPosition();
      this.updateMiniMapPosition();
    },
    translateY(value) {
      if (this.isZooming) {
        return;
      }
      this.setTransform();
      this.updateVisibleTreeThrottled();
      this.updateMiniMapPosition();
    },
    scale(value) {
      this.setTransform();
      this.updateVisibleTreeDebounce();
    },
    familyTreeFocusPersonIdState: {
      handler: function (newValue, oldValue) {
        if (newValue) {
          this.shiftTreeToFocusPerson(newValue);
        }
      },
    },
    familyTreeRefreshRequestState(value) {
      if (value === false) {
        return;
      }
      this.$store.commit('setFamilyTreeRefreshRequestState', false);
      this.drawTree();
      this.updateVisibleTree();
      this.updateMiniMapPosition();
    },
    familyTreeStartPersonYState(newValue, oldValue) {
      if (newValue === null || oldValue === null) {
        return;
      }
      const diff = oldValue - newValue;
      this.translateY = this.translateY + diff * this.scale;
    },
  },
  computed: {
    ...mapGetters([
      'familyTreePersonsByIdState',
      'familyTreeStartPersonIdState',
      'familyTreeStartFocusPersonIdState',
      'familyTreeFocusPersonIdState',
      'isFamilyTreeWriteAllowedState',
      'userIsLoggedInState',

      'familyTreePersonsDrawnState',
      'familyTreeLinesDrawnState',
      'familyTreeDrawnHeightState',
      'familyTreeDrawnWidthState',
      'familyTreeDrawnMarginXState',
      'familyTreeMapImageState',
      'familyTreeMapImageLoadingState',
      'familyTreeRefreshRequestState',
      'familyTreeDrawLoadingState',
      'familyTreeIsMiniMapVisibleState',
      'familyTreeMapImageScaleState',
      'familyTreeMapImageExistsState',
      'featureSwitchesState',
      'familyTreeDetailsIdState',
      'familyTreePreferencesState',
      'reviewedItemsState',
      'treeUsageTutorialState',
      'familyTreeStartPersonYState',
    ]),
    style() {
      return {
        position: 'relative',
        transform: this.transform,
        width: this.width,
        height: this.height,
        'transform-style': 'preserve-3d',
        '-webkit-transform-style': 'preserve-3d',
        'backface-visibility': 'hidden',
        '-webkit-backface-visibility': 'hidden',
        'z-index': '10',
      };
    },
    isDesktop() {
      return this.$store.getters.windowWidthState >= this.$breakpoints.mobile;
    },
    showMapSwitch() {
      return (
        this.featureSwitchesState.FAMILY_TREE_MINIMAP &&
        !this.isImageRender &&
        !this.familyTreeIsMiniMapVisibleState &&
        this.isDesktop
      );
    },
    showMap() {
      return (
        this.featureSwitchesState.FAMILY_TREE_MINIMAP && !this.isImageRender && this.familyTreeIsMiniMapVisibleState
      );
    },
    mapSwitchIsActive() {
      return this.familyTreeIsMiniMapVisibleState;
    },
    isImageRender() {
      return this.scale < this.scaleToMapSwitch;
    },
    wasDraggingInsignificant() {
      let byX = this.draggingStart.x - this.draggingEnd.x;
      let byY = this.draggingStart.y - this.draggingEnd.y;
      return Math.abs(byX) < INSIGNIFICANT_DRAGGING_THRESHOLD && Math.abs(byY) < INSIGNIFICANT_DRAGGING_THRESHOLD;
    },
    drawnPersonBuckets() {
      let buckets = {};
      for (let person of Object.values(this.familyTreePersonsByIdState)) {
        for (let draw of person.draws) {
          const personIndex = this.getBucketIndex(draw.position.x, draw.position.y);
          buckets = this.addObjectToBucket(buckets, personIndex, person);
        }
      }
      return buckets;
    },
    allConnectorsBuckets() {
      let buckets = {};
      for (let line of Object.values(this.familyTreeLinesDrawnState)) {
        line.meta = {
          relatedConnectors: line.related_lines.map(id => ({id})),
          relatedPersons: line.related_people.map(object_id => ({object_id})),
          gender: line.gender,
          is_dashed: line.is_dashed,
        };

        let startIndex = this.getBucketIndex(line.bounding_box.x1, line.bounding_box.y1);
        let endIndex = this.getBucketIndex(line.bounding_box.x2, line.bounding_box.y2);

        if (endIndex.x === startIndex.x && endIndex.y === startIndex.y) {
          buckets = this.addObjectToBucket(buckets, startIndex, line);
          continue;
        }

        let Xlist = [];
        let Ylist = [];

        let lengthByX = line.x1 - line.x2;
        let bucketsByX = Math.ceil(Math.abs(lengthByX) / this.bucketIndexLength);

        let startPosition = line.x1;
        for (let buck = 0; buck <= bucketsByX; buck++) {
          let step = buck * this.bucketIndexLength;
          let currentPosition = lengthByX > 0 ? startPosition - step : startPosition + step;
          Xlist.push(currentPosition);
        }

        let lengthByY = line.y1 - line.y2;
        let bucketsByY = Math.ceil(Math.abs(lengthByY) / this.bucketIndexLength);

        startPosition = line.y1;
        for (let buck = 0; buck <= bucketsByY; buck++) {
          let step = buck * this.bucketIndexLength;
          let currentPosition = lengthByY > 0 ? startPosition - step : startPosition + step;
          Ylist.push(currentPosition);
        }

        for (let x of Xlist) {
          for (let y of Ylist) {
            let combIndex = this.getBucketIndex(x, y);
            buckets = this.addObjectToBucket(buckets, combIndex, line);
          }
        }
      }
      return buckets;
    },
    backgroundMapSize() {
      return {
        width: this.familyTreeDrawnWidthState,
        height: this.familyTreeDrawnHeightState,
      };
    },
    showMainLoadingOverlay() {
      return this.familyTreeDrawLoadingState;
    },
    visibleConnectorsSvg() {
      return this.visibleConnectors.filter(c => c.type !== 'label');
    },
    visibleConnectorsLabels() {
      return this.visibleConnectors.filter(c => c.type === 'label');
    },
    startPersonFirstName() {
      const startPerson = this.$store.getters.familyTreeStartPersonState;
      if (!startPerson) {
        return UNKNOWN_NAME;
      }
      const names =
        startPerson.first_names && startPerson.first_names.length ? startPerson.first_names : startPerson.surnames;
      const name = names && names[0] && names[0].value ? names[0].value : UNKNOWN_NAME;
      return name && name.length > 20 ? trimLongName(name) : name;
    },
  },
  methods: {
    getPositionStyle(position) {
      return `top: ${position.y}px; left: ${position.x}px;`;
    },
    round(value, factor = 1000) {
      return Math.round(value * factor) / factor;
    },
    mapMove({x, y}) {
      this.translateX = this.translateX + x;
      this.translateY = this.translateY + y;
    },
    closeMap() {
      this.$store.commit('setFamilyTreeIsMiniMapVisibleState', false);
    },
    updateMiniMapPosition() {
      if (!this.showMap) {
        return;
      }
      if (this.$refs['mini-map']) {
        this.$refs['mini-map'].setMiniMapStyle();
      }
    },
    addObjectToBucket(buckets, bucketIndex, object) {
      let bucket_x = buckets[bucketIndex.x] || {};
      let bucket = bucket_x[bucketIndex.y] || [];
      bucket.push(object);
      bucket_x[bucketIndex.y] = bucket;
      buckets[bucketIndex.x] = bucket_x;
      return buckets;
    },
    getBucketIndexForValue(value) {
      return Math.round(value / this.bucketIndexLength) * this.bucketIndexLength;
    },
    getBucketIndex(x, y) {
      let index_x = this.getBucketIndexForValue(x);
      let index_y = this.getBucketIndexForValue(y);
      return {x: index_x, y: index_y};
    },
    setVisibleLinesAndPersonByArea() {
      let x_start = this.getBucketIndexForValue(this.visibleBoundaries.left_x);
      let x_end = this.getBucketIndexForValue(this.visibleBoundaries.right_x);
      let y_start = this.getBucketIndexForValue(this.visibleBoundaries.top_y);
      let y_end = this.getBucketIndexForValue(this.visibleBoundaries.bottom_y);

      let bucketPerson = {};
      let bucketLines = {};

      for (let index_x = x_start; index_x <= x_end; index_x += this.bucketIndexLength) {
        for (let index_y = y_start; index_y <= y_end; index_y += this.bucketIndexLength) {
          let x_person_bucket = this.drawnPersonBuckets[index_x] || {};
          let y_person_bucket = x_person_bucket[index_y] || [];
          for (let p of y_person_bucket) {
            bucketPerson[p.id] = p;
          }

          let x_lines_bucket = this.allConnectorsBuckets[index_x] || {};
          let y_lines_bucket = x_lines_bucket[index_y] || [];
          for (let l of y_lines_bucket) {
            bucketLines[l.id] = l;
          }
        }
      }

      this.visiblePersons = bucketPerson;
      this.visibleConnectors = sortBy(bucketLines, 'type');
    },
    stopBrowserZoom(e) {
      if (e.ctrlKey) {
        e.preventDefault();
      }
    },
    personsListChanged(newList, oldList) {
      return this.idsChanged(newList, oldList);
    },
    idsChanged(newList, oldList) {
      let newIds = newList.map(person => person.object_id);
      let oldIds = oldList.map(person => person.object_id);
      return !isEqual(newIds.sort(), oldIds.sort());
    },
    drawTree() {
      this.width = `${this.familyTreeDrawnWidthState}px`;
      this.height = `${this.familyTreeDrawnHeightState}px`;
      this.svgContainerViewBox = `${this.familyTreeDrawnMarginXState} 0 ${this.familyTreeDrawnWidthState} ${this.familyTreeDrawnHeightState}`;
      this.simpleSvgContainerViewBox = `0 0 ${this.familyTreeDrawnWidthState} ${this.familyTreeDrawnHeightState}`;
      this.svgContainerStyle = {left: this.familyTreeDrawnMarginXState + 'px', 'shape-rendering': 'crispEdges'};
      this.imageContainerStyle = {
        left: this.familyTreeDrawnMarginXState + 'px',
      };
    },
    shiftTreeToFocusPerson: debounce(function (personId, acknowledgeSidebar = true, shiftY = 0) {
      if (personId === -1) {
        return;
      }
      const sidebars = document.getElementsByClassName('quick_sidebar');
      const sidebarWidth = sidebars.length && acknowledgeSidebar ? sidebars[0].getBoundingClientRect().width : 0;
      const sidebarOffsetX = sidebarWidth >= window.innerWidth ? 0 : sidebarWidth;
      const centerX = (window.innerWidth + sidebarOffsetX - WIDTH * this.scale) / 2;
      const centerY = window.innerHeight / 2 - consts.MAIN_MENU_HEIGHT; // removed "- HEIGHT" to fit the ghost cards
      const person = this.familyTreePersonsByIdState[personId];
      if (!person) {
        this.showFocusPersonIsHiddenToast(personId, null);

        this.$store.commit('setFamilyTreeFocusPersonIdState', -1);
        return;
      }
      const position = person.draws[0].position;
      // this.scale = 1;
      this.translateX = centerX - position.x * this.scale;
      this.translateY = centerY + shiftY - position.y * this.scale;
    }, 100),
    shiftTreeIntoViewport() {
      if (this.familyTreeStartFocusPersonIdState === -1) {
        return;
      }
      let focusPerson = this.familyTreePersonsByIdState[this.familyTreeStartFocusPersonIdState];
      const shiftY = 100;
      if (focusPerson) {
        this.shiftTreeToFocusPerson(this.familyTreeStartFocusPersonIdState, false, shiftY);
      } else {
        this.showFocusPersonIsHiddenToast(this.familyTreeStartFocusPersonIdState, null);
        this.shiftTreeToFocusPerson(this.familyTreeStartPersonIdState, false, shiftY);
      }
    },
    showFocusPersonIsHiddenToast(personId, fallbackPerson) {
      const query = {
        start_person_id: personId,
      };
      // const personName = fallbackPerson.full_name || UNKNOWN_NAME;
      const action = [
        {text: 'View their Tree', push: {name: 'familytree-details', params: {id: this.$route.params.id}, query}},
        {text: 'X', onClick: (e, toastObject) => toastObject.goAway(0)},
      ];
      const message = `Person is hidden.`;
      this.$toasted.success(message, {action: action, duration: 10000});
    },
    setTransform: throttle(
      function () {
        this.transform = `translate3d(${this.translateX}px, ${this.translateY}px, -10px) scale(${this.scale})`;
      },
      20,
      {leading: true}
    ),
    updateVisibleTree: function () {
      let updated = this.calculateVisibleBoundaries();
      if (!updated) return;
      this.setVisibleLinesAndPersonByArea();
    },
    updateVisibleTreeThrottled: throttle(
      function () {
        this.updateVisibleTree();
      },
      100,
      {leading: false, trailing: true}
    ),
    updateVisibleTreeDebounce: debounce(
      function () {
        this.updateVisibleTree();
        this.isZooming = false;
        this.setTransform();
        this.$nextTick(() => {
          this.updateMiniMapPosition();
        });
      },
      200,
      {leading: true, trailing: true}
    ),
    calculateVisibleBoundaries() {
      let previousBoundaries = this.visibleBoundaries;
      let viewPortBoundaries = this.getViewPortBoundaries('boundaries');
      this.updateMiniMapPosition();

      let right_x = viewPortBoundaries.right_x + 200;
      let top_y = viewPortBoundaries.top_y - 200;
      let left_x = viewPortBoundaries.left_x - 200;
      let bottom_y = viewPortBoundaries.bottom_y + 200;

      let right_diff = Math.abs(previousBoundaries.right_x - right_x);
      let left_diff = Math.abs(previousBoundaries.left_x - left_x);
      let top_diff = Math.abs(previousBoundaries.top_y - top_y);
      let bottom_diff = Math.abs(previousBoundaries.bottom_y - bottom_y);

      let diff_is_not_nan = !isNaN(right_diff) && !isNaN(left_diff) && !isNaN(top_diff) && !isNaN(bottom_diff);

      const minDiff = 200;
      let is_all_zero = right_diff === 0 && left_diff === 0 && top_diff === 0 && bottom_diff === 0;
      let has_significant_diff =
        left_diff > minDiff || top_diff > minDiff || right_diff > minDiff || bottom_diff > minDiff;
      if (diff_is_not_nan && !has_significant_diff && !is_all_zero) {
        return false;
      }

      this.visibleBoundaries = {
        left_x: left_x,
        right_x: right_x,
        top_y: top_y,
        bottom_y: bottom_y,
      };

      return true;
    },
    getViewPortBoundaries(f) {
      let scale = this.scale;
      if (!this.$refs || !this.$el) {
        return {};
      }
      let base = this.$el.getBoundingClientRect();

      let left_x = (this.translateX * -1) / scale;
      let right_x = base.width / scale + left_x;
      let top_y = (this.translateY * -1) / scale;
      let bottom_y = base.height / scale + top_y;

      return {
        left_x: left_x,
        right_x: right_x,
        top_y: top_y,
        bottom_y: bottom_y,
        width: base.width / scale,
        height: base.height / scale,
      };
    },
    zoom(event) {
      const {clientX, clientY} = event;
      const delta = this.getZoomDelta(event.deltaY, event.deltaY < 2 && event.deltaY > -2);
      this.doZooming(delta, clientX, clientY);
      this.updateVisibleCardMenuPosition();
    },
    doZooming(delta, toX, toY) {
      let nextScale = this.round(this.scale - delta + Number.EPSILON);
      this.doZoomingByNextScale(nextScale, toX, toY);
    },
    doZoomingByNextScale(nextScale, toX, toY) {
      if (this.treeUsageTutorialState && this.treeUsageTutorialState.showZoom) {
        setTimeout(() => {
          this.$store.commit('endTreeUsageTutorialState');
          this.$store.commit('addReviewedItemState', TREE_USAGE_TUTORIAL_KEY);
          AnalyticsMainHandler.trackTreeFirstZoomEvent();
        }, 1000);
      }
      const minScale = 0.04;
      const maxScale = 2;
      nextScale = nextScale < minScale ? minScale : nextScale > maxScale ? maxScale : nextScale;
      if (nextScale === this.scale) {
        return;
      }
      this.isZooming = true;
      const ratio = 1.0 - nextScale / this.scale;
      this.scale = nextScale;

      this.translateX = this.round(this.translateX + (toX - this.translateX) * ratio);
      this.translateY = this.round(this.translateY + (toY - this.translateY) * ratio);

      this.clearFocusPerson();
    },
    zoomInToCenter() {
      this.doZoomingByNextScale(this.scale * 1.5, window.innerWidth / 2, window.innerHeight / 2);
    },
    zoomOutToCenter() {
      this.doZoomingByNextScale(this.scale / 1.5, window.innerWidth / 2, window.innerHeight / 2);
    },
    mousedown(event) {
      this.isDragging = true;
      this.draggingWith = DRAGGING_MOUSE;
      this.draggingStart = {x: event.x, y: event.y};
    },
    mouseup(event) {
      this.isDragging = false;
      this.draggingEnd = {x: event.x, y: event.y};
      this.clearFocusPerson();
    },
    mouseleave(event) {
      this.isDragging = false;
    },
    mousemove(event) {
      if (this.isDragging && this.draggingWith === DRAGGING_MOUSE) {
        let {movementX, movementY} = event;
        if (!movementX && !movementY) {
          // disable for IE11
          return;
        }
        this.translateY = this.round(this.translateY + movementY);
        this.translateX = this.round(this.translateX + movementX);
        this.treeUsageTutorialGoToZoomStep();
      }
    },
    updateVisibleCardMenuPosition() {
      for (let cardContainer of this.$refs['cards-containers'] || []) {
        if (cardContainer && cardContainer.showMenu && cardContainer.$refs && cardContainer.$refs.dropdown) {
          cardContainer.$refs.dropdown.$refs.popper.$_computePosition();
        }
      }
    },
    treeUsageTutorialGoToZoomStep() {
      if (this.treeUsageTutorialState && this.treeUsageTutorialState.showDrag) {
        setTimeout(() => {
          this.$store.commit('showZoomTreeUsageTutorialState');
          AnalyticsMainHandler.trackTreeFirstDragToPanEvent();
        }, 1500);
      }
    },
    touchstart(event) {
      this.draggingWith = DRAGGING_TOUCH;
      if (event.touches.length === 1) {
        this.isDragging = true;
        this.draggingStart = {x: event.changedTouches[0].pageX, y: event.changedTouches[0].pageY};
      }
      if (event.touches.length === 2) {
        this.twoFingerInitDist = this.calculateTwoFingerDist(event);
      }
    },
    translateTouchMove: throttle(function (event) {
      const movementX = event.touches[0].pageX - this.draggingStart.x;
      const movementY = event.touches[0].pageY - this.draggingStart.y;
      this.translateY = this.translateY + movementY;
      this.translateX = this.translateX + movementX;
      this.draggingStart = {x: event.touches[0].pageX, y: event.touches[0].pageY};
    }, 10),
    touchmove(event) {
      if (this.draggingWith !== DRAGGING_TOUCH) {
        return;
      }
      if (event.touches.length === 1 && this.isDragging) {
        this.translateTouchMove(event);
        this.treeUsageTutorialGoToZoomStep();
      }
      if (event.touches.length === 2) {
        const newMousePosX = (event.touches[0].clientX + event.touches[1].clientX) / 2;
        const newMousePosY = (event.touches[0].clientY + event.touches[1].clientY) / 2;
        const newTwoFingerDist = this.calculateTwoFingerDist(event);
        const delta = this.getZoomDelta(this.twoFingerInitDist - newTwoFingerDist, true);
        this.doZooming(delta, newMousePosX, newMousePosY);
        this.twoFingerInitDist = newTwoFingerDist;
      }
    },
    touchend(event) {
      this.isDragging = false;
      const touch = event.changedTouches[0];
      this.draggingEnd = {x: touch.pageX, y: touch.pageY};
      this.clearFocusPerson();
    },
    clearFocusPerson() {
      /* clear focus person on drag/zoom to allow re-focusing the same person when moved/zoomed away from it */
      this.$store.commit('setFamilyTreeFocusPersonIdState', null);
    },
    calculateTwoFingerDist(event) {
      const distX = event.touches[0].clientX - event.touches[1].clientX;
      const distY = event.touches[0].clientY - event.touches[1].clientY;
      return Math.sqrt(distX * distX + distY * distY);
    },
    getZoomDelta(scrollDistance, isTouch) {
      const isMobile = isTouch || this.$store.getters.windowWidthState < this.$breakpoints.tablet;
      if (isMobile) {
        return scrollDistance * 0.005;
      }
      return scrollDistance * 0.001;
    },
    getCardContainerClasses(person) {
      const classesByCondition = [
        {
          condition: person =>
            this.highlightedRelatedPersonsIds.length && !this.highlightedRelatedPersonsIds.includes(person.object_id),
          getClass: person => 'is-not-active',
        },
      ];
      let classes = [];
      for (let item of classesByCondition) {
        if (item.condition(person)) {
          classes.push(item.getClass(person));
        }
      }

      return classes;
    },
    getConnectorClasses(connector) {
      const classesByMetaCondition = [
        {
          condition: connector => connector.meta && !connector.meta.isGhost,
          getClass: connector => (connector.meta.is_dashed ? null : connector.meta.gender),
        },
        {
          condition: connector => connector.meta && this.highlightedConnectorsIds.includes(connector.id),
          getClass: connector => 'is-highlighted',
        },
        {
          condition: connector => connector.id === this.hoveredControllerId,
          getClass: connector => 'is-controller-highlighted',
        },
        {
          condition: connector =>
            connector.meta &&
            this.highlightedConnectorsIds.length &&
            !this.highlightedConnectorsIds.includes(connector.id),
          getClass: connector => 'is-not-active',
        },
        {
          condition: connector => connector.meta.is_dashed,
          getClass: connector => 'is-dashed',
        },
      ];
      let classes = ['connector'];
      for (let item of classesByMetaCondition) {
        if (item.condition(connector)) {
          classes.push(item.getClass(connector));
        }
      }

      return classes;
    },
    onMouseOverController(connector) {
      this.hoveredControllerId = connector.id;
    },
    stopHighlightController(connector) {
      this.hoveredControllerId = null;
    },
    onMouseOverConnector(connector) {
      this.clearHighlightTimeout();
      this.hoveredConnectorId = connector.id;
      setTimeout(() => {
        if (this.hoveredConnectorId === connector.id) {
          this.highlightRelatedConnectors(connector);
        }
      }, 1000);
    },
    highlightRelatedConnectors(connector) {
      if (!this.activatedConnectorsIds.length) {
        let highlightedConnectorsIds = connector.meta.relatedConnectors.map(c => c.id);
        highlightedConnectorsIds.push(connector.id);
        this.highlightedConnectorsIds = highlightedConnectorsIds;
      }
    },
    stopHighlightingRelatedConnectors() {
      this.hoveredConnectorId = null;
      this.highlightTimeout = setTimeout(() => {
        if (!this.activatedConnectorsIds.length && !this.hoveredConnectorId) {
          this.stopHighlighting();
        }
      }, 2000);
    },
    activateRelatedConnectors(connector) {
      let connectorIds = connector.meta.relatedConnectors.map(c => c.id);
      connectorIds.push(connector.id);
      this.activatedConnectorsIds = connectorIds;
      this.highlightedConnectorsIds = connectorIds;
      this.highlightedRelatedPersonsIds = connector.meta.relatedPersons.map(c => c.object_id);
      this.closeOpenedPersonMenu();
    },
    onControllerClick(connector) {
      const dataList = [
        {
          controller_id: [connector.controller.id],
          controller_type: connector.controller.type,
          controller_state: connector.controller.state === 1 ? 0 : 1,
        },
      ];
      this.$store.dispatch('fetchFamilyTreeMapDiffAction', {
        id: this.familyTreeDetailsIdState,
        start_person_id: this.familyTreeStartPersonIdState,
        map_hash: this.$store.getters.familyTreeMapImageHashState,
        control_data: dataList,
        trigger: 'controller',
      });
    },
    getControllerTooltip(connector) {
      const mapping = {children: 'child', siblings: 'sibling', parents: 'parents'};
      const relType = connector.controller.type;
      const relTypeSingle = mapping[relType];
      if (connector.controller.state === 1) {
        return {content: `Hide ${relType}`};
      }
      const count = connector.controller.people_count;
      const relation = count > 1 ? relType : relTypeSingle;
      const content = count ? `Show ${count} ${relation}` : `Show ${relation}`;
      return {content: content};
    },
    onTreeWrapperClick(event) {
      const myDiv = document.getElementsByClassName('family-tree-wrapper')[0];
      const myDiv2 = document.getElementsByClassName('connectors-container')[0];
      if (event.target === myDiv || event.target === myDiv2) {
        this.closeOpenedPersonMenu();
      }
      if (this.wasDraggingInsignificant) {
        this.stopHighlighting();
      }
    },
    closeOpenedPersonMenu() {
      const cardId = this.$store.getters.treeCardMenuShownCardIdState;
      if (cardId) {
        for (let cardContainer of this.$refs['cards-containers'] || []) {
          if (cardContainer && cardContainer.cardId === cardId) {
            cardContainer.immediateHideMenu();
          }
        }
      }
    },
    clearHighlightTimeout() {
      if (this.highlightTimeout) {
        clearTimeout(this.highlightTimeout);
      }
    },
    stopHighlighting() {
      this.highlightedConnectorsIds = [];
      this.highlightedRelatedPersonsIds = [];
      this.activatedConnectorsIds = [];
    },
    switchMap() {
      this.$store.commit('setFamilyTreeIsMiniMapVisibleState', !this.familyTreeIsMiniMapVisibleState);
    },
    getLabelConnectorText(connector) {
      return `${this.startPersonFirstName}'s ${connector.text}`;
    },
    getLabelConnectorStyle(connector) {
      const inactive = this.activatedConnectorsIds.length ? {display: 'none'} : {};
      let maxWidth = connector.width ? connector.width - 20 : 120;
      if (maxWidth < 120) {
        maxWidth = 120;
      }
      const transform = connector.width ? {transform: 'translate(-50%, -50%)'} : {transform: 'translate(-50%, 0)'};
      return {
        top: connector.y + 'px',
        left: connector.x + 'px',
        'max-width': maxWidth + 'px',
        ...transform,
        ...inactive,
      };
    },
  },
  components: {
    FamilyTreeMiniMap,
    FamilyTreeMiniMapSwitch,
    FamilyTreeCardGhostContainer,
    FamilyTreeCardContainer,
    FamilyTreeSimpleSVG,
    OverlayTutorialAnimated,
  },
  name: 'family-tree',
};
</script>

<style scoped lang="scss">
.fast-fade-move,
.fast-fade-enter-active,
.fast-fade-leave-active {
  transition: opacity 0.5s;
}

.fast-fade-enter, .fast-fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

.fast-fade-leave-active {
  position: absolute;
}

#image-map-loading {
  position: fixed;
  z-index: 11;

  cursor: progress;
  width: 100vw;
  height: 100vh;

  .mcr-loading-indicator {
    position: fixed;

    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
  }
}

.loading-state {
}

.family-tree-wrapper {
  position: relative;
  z-index: 1;

  &:active {
    cursor: grab;
  }

  .family-tree {
    transition: transform 50ms;
    position: absolute;
    transform-origin: 0 0;
    -moz-user-select: none;
    -ms-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none;
    user-select: none;

    .family-tree-image {
    }

    .familytree-card-container {
      transition: all 50ms ease;

      &.is-not-active {
        opacity: 0.3;
      }
    }

    .connectors-container {
      position: relative;
      width: 100%;
      height: 100%;

      &::v-deep path.connector.secondary,
      &::v-deep line.connector.secondary {
        stroke-width: 1px;
      }

      .connector.is-highlighted {
        opacity: 1;
        stroke-width: 3px;
        stroke: rgba($mcr-grey, 0.9);
        z-index: 2;
      }
      .connector.is-controller-highlighted::v-deep {
        circle {
          fill: $neutral-50;
          stroke: $neutral-400;
        }
      }

      .connector.is-not-active {
        opacity: 0.3;
      }

      .connector.is-dashed {
        stroke-dasharray: 4;
      }
      .connector-interaction-zone {
        stroke: transparent;
        opacity: 0;
        cursor: pointer;
        stroke-width: 10px !important;

        ::v-deep {
          path {
            stroke-width: 10px !important;
          }
          line {
            stroke-width: 10px !important;
          }
        }
      }
      .controller-interaction-zone {
        opacity: 0;
        cursor: pointer;
      }
    }
  }

  .connector-label {
    position: absolute;
    font-style: italic;
    color: $neutral-600;
    background: $ft-background-color;
    padding: 2px 8px;
    z-index: 2;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: center;
  }

  &::v-deep .main-menu-dropdown {
    &.v-popper__popper.v-popper--theme-dropdown .v-popper__inner {
      border: none;
    }
    .v-popper__arrow-container {
      .v-popper__arrow-outer,
      .v-popper__arrow-inner {
        border-color: $power-red;
      }
    }
  }
}

@media only screen and (max-width: $breakpoint-tablet) {
  .library-link {
    position: absolute;
    z-index: 2;
    right: 80px;
    bottom: 16px;

    .mcr-button::v-deep {
      height: 52px;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    @media only screen and (max-width: $breakpoint-mobile) {
    }
  }
}

.map-scroll-enter-active {
  animation: map-scroll 0.25s;
}

.map-scroll-leave-active {
  animation: map-scroll 0.25s reverse;
}

@keyframes map-scroll {
  0% {
    transform: scale(0.1);
  }
  100% {
    transform: scale(1);
  }
}
</style>

<template>
  <div class="family-tree-clans-page">
    <div class="readable_content" v-if="familyTreeSurnamesLoadingState">
      <mcr-loading-indicator :loading="true"></mcr-loading-indicator>
    </div>
    <page-not-found v-else-if="pageNotFound"></page-not-found>
    <div class="readable_content" v-else>
      <div class="heading-6">Clans</div>
      <div class="tree-selector" v-if="showSelector">
        <family-tree-selector
          :list="familyTreeListState"
          :tree-id="familyTreeDetailsState.object_id"
          :tree-name="familyTreeDetailsState.name"
          :display-inline="true"
          @select="onTreeSelect"
        ></family-tree-selector>
      </div>
      <div class="clans-list" v-if="familyTreeSurnamesState.length">
        <stand-out-block class="clan-item light" v-for="item in familyTreeSurnamesState" :key="item.object_id">
          <div class="clan-item-content">
            <div class="clan-data">
              <router-link :to="getClanRoute(item)" class="name">{{ item.surname }} Clan</router-link>
            </div>
            <div class="clan-meta">
              <span class="persons-count"
                ><profile-icon :size="16" title="" />{{ item.count }} {{ item.count > 1 ? 'persons' : 'person' }}</span
              >
            </div>
          </div>
        </stand-out-block>
      </div>
      <div v-else class="clans-empty">
        <stand-out-block>
          <div class="empty-state">
            <div class="info-icon-wrapper">
              <info-icon :size="24"></info-icon>
            </div>
            <div class="title">Add Chinese surnames to your tree to unlock Clans</div>
            <div class="text-sm text-md-mobile">
              Don't know your Chinese surname? Try our surname search tool below.
            </div>
            <div class="search-surnames-form">
              <input
                class="input"
                v-model="searchSurname"
                :placeholder="searchInputPlaceholder"
                @keyup.enter="searchSurnamesClick"
              />
              <mcr-button @click="searchSurnamesClick">Search Surnames </mcr-button>
            </div>
          </div>
        </stand-out-block>
      </div>

      <div class="clans-helper">
        <h6>What is a "clan"?</h6>
        <div>
          A <span class="bold">clan</span> (家族, jiāzú) is an extended family group who share the same Chinese surname
          and common ancestry. In China, clans are a key source of documents and stories relating to family history.
        </div>
        <div class="learn-more">
          <router-link :to="{name: 'wiki-article', params: {slug: 'clans'}}">Learn more</router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import McrButton from '@common/elements/buttons/mcrButton.vue';
import StandOutBlock from '@common/elements/layouts/StandOutBlock.vue';
import {getTreeClanDetailRoute} from '@common/utils/utils.routes';
import isEmpty from 'lodash/isEmpty';
import ProfileIcon from 'vue-material-design-icons/Account.vue';
import InfoIcon from 'vue-material-design-icons/Information.vue';
import {mapGetters} from 'vuex';

import FamilyTreeSelector from '@/components/common/inputs/familyTreeSelector.vue';

import PageNotFound from '@/components/page.not.found';

export default {
  created() {
    this.init(this.$route.params.id);
  },
  watch: {
    $route: {
      handler: function (toRoute, fromRoute) {
        const sameRoute = toRoute.name === fromRoute.name;
        const changedFtId = parseInt(toRoute.params.id) !== parseInt(fromRoute.params.id);
        if (sameRoute && changedFtId) {
          this.init(toRoute.params.id);
        }
      },
    },
  },
  data() {
    return {
      searchSurname: '',
      pageNotFound: false,
    };
  },
  computed: {
    ...mapGetters([
      'familyTreeDetailsState',
      'familyTreeListState',
      'familyTreeSurnamesState',
      'familyTreeSurnamesLoadingState',
    ]),
    treeId() {
      return this.familyTreeDetailsState.object_id;
    },
    showSelector() {
      const inList = this.familyTreeListState.find(item => item.object_id === this.treeId);
      if (inList) {
        return this.familyTreeListState.length > 1;
      }
      return this.familyTreeDetailsState && this.familyTreeDetailsState.name;
    },
    searchInputPlaceholder() {
      return 'e.g. "Huang"';
    },
  },
  methods: {
    init(treeId) {
      this.$store.dispatch('fetchFamilyTreeDetailsAction', treeId);
      this.$store.dispatch('fetchFamilyTreeSurnamesListAction', treeId).catch(() => {
        this.pageNotFound = true;
      });
      if (isEmpty(this.familyTreeListState)) {
        this.$store.dispatch('fetchFamilyTreeListAction');
      }
    },
    onTreeSelect(value) {
      this.$router.push({name: 'familytree-clans', params: {id: value.object_id}});
    },
    getClanRoute(item) {
      return getTreeClanDetailRoute(this.$route.params.id, item.surname);
    },
    searchSurnamesClick() {
      this.$router.push({name: 'search-tool-surname', query: {q: this.searchSurname}});
    },
  },
  name: 'FamilyTreeClansPage',
  components: {McrButton, InfoIcon, StandOutBlock, ProfileIcon, FamilyTreeSelector, PageNotFound},
};
</script>

<style scoped lang="scss">
.family-tree-clans-page {
  .tree-selector::v-deep {
    margin-top: 10px;
    .family-tree-multiselect {
      max-width: fit-content;
      width: fit-content;

      &.multiselect--active {
        max-width: 380px;
        width: 380px;
      }

      @media only screen and (max-width: $breakpoint-mobile) {
        max-width: 100%;
        &.multiselect--active {
          max-width: 100%;
          width: 100%;
        }
      }
    }
  }
  .clans-list {
    border-top: 1px solid $neutral-300;
    border-bottom: 1px solid $neutral-300;
    display: flex;
    flex-direction: column;
    row-gap: 20px;
    padding: 20px 0;
    margin: 20px 0;
  }

  .clan-item {
    padding: 15px 24px;

    .clan-item-content {
      display: flex;
      justify-content: space-between;
      .clan-data {
        display: flex;
        align-items: center;
      }
      .clan-meta {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        justify-content: center;
        font-size: 14px;
      }
    }

    .name {
      font-weight: bold;
      margin-right: 7px;
    }
    .persons-count {
      display: flex;
      align-items: center;
      color: $neutral-500;
      .material-design-icon {
        margin-top: -1px;
        margin-right: 3px;
      }
    }
  }

  .learn-more {
    margin-top: 8px;
  }

  .clans-empty {
    padding: 24px 0 36px;
    .empty-state {
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
      padding: 16px 0;
    }
    .info-icon-wrapper {
      border-radius: 50%;
      background: $neutral-300;
      color: $neutral-500;
      width: 40px;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 16px;
    }
    .title {
      font-size: 18px;
      line-height: 28px;
      letter-spacing: -0.02em;
      font-weight: 800;
      margin-bottom: 4px;
    }
    .search-surnames-form {
      display: flex;
      margin-top: 20px;
      width: 100%;
      flex-direction: column;
      row-gap: 16px;
      max-width: 450px;
    }
  }

  @media only screen and (max-width: $breakpoint-mobile) {
    .clan-item {
      .clan-item-content {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        .clan-data {
          display: flex;
          flex-wrap: wrap;
        }
        .clan-meta {
          align-items: flex-start;
          flex-direction: row;
          margin-top: 5px;
          > * {
            margin-right: 10px;
            margin-bottom: 0;
          }
        }
      }
    }
  }
}
</style>

<template>
  <div class="date-location-display">
    <span v-if="displayDate">{{ displayDate }}</span>
    <template v-if="location">
      <span class="separator" v-if="displayDate"> in </span>
      <router-link class="place-wrapper" v-if="location.place_id" :to="placeRoute" target="_blank">
        <map-marker-icon></map-marker-icon>
        <span>{{ location.display_text }}</span>
      </router-link>
      <span v-else class="place-wrapper">
        <map-marker-icon></map-marker-icon>
        <span>{{ location.display_text }}</span>
      </span>
    </template>
  </div>
</template>

<script>
import {formatApproximateDate} from '@common/utils/utils';
import {getPlaceDetailRoute} from '@common/utils/utils.routes';
import MapMarkerIcon from 'vue-material-design-icons/MapMarker';

export default {
  props: {
    date: Object,
    date2: Object,
    location: Object,
  },
  computed: {
    displayDate() {
      if (this.date && this.date2) {
        return `${formatApproximateDate(this.date)}-${formatApproximateDate(this.date2)}`;
      }
      const date = this.date || this.date2;
      return date ? formatApproximateDate(date) : null;
    },
    placeRoute() {
      return getPlaceDetailRoute(this.location.place_id);
    },
  },
  methods: {
    formatApproximateDate: formatApproximateDate,
  },
  components: {MapMarkerIcon},
};
</script>

<style lang="scss" scoped>
.material-design-icon {
  display: inline-block;
}
.material-design-icon__svg {
  margin: 0;
  position: relative;
}

.separator {
  white-space: pre;
}
</style>
